<template>
  <header class="header">
    <HorizontalMenu />
    <img id="imgLogo" :src="`${url}/${user.company}/${logo()}`" :alt="user.company"/>
    <img id="imgLogoPrint" :src="`${url}/${user.company}/logo_print.png`" :alt="user.company"/>
    <h1>{{$store.state.print.pageTitle ? $store.state.print.pageTitle : $route.query.menuOpened}}</h1>
    <UserMenu :showLogin="showLogin"/>
  </header>
</template>

<script>
import { baseFilesUrl } from '@/global'
import HorizontalMenu from './HorizontalMenu'
import UserMenu from './UserMenu'
import { mapState } from 'vuex'
export default {
  name: "Header",
  props: { showLogin: Function },
  components: { HorizontalMenu, UserMenu },
  computed: { ...mapState(['user']) },
  data: () => ({
    url: baseFilesUrl
  }),
  methods: {
    logo() { return (this.$mq === 'xs' || this.$mq === 'sm') ? 'm_logo.png' : 'logo.png' }
  }
}
</script>

<style lang="scss">
  .header {
    grid-area: header;
    border-bottom: 1px solid rgb(100 121 143 / 12%);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-background-color); /* Company Style */
    color: var(--header-color); /* Company Style */
  }
  .header img#imgLogo, .header img#imgLogoPrint { max-height: 35px; }
  @media only screen and (max-width: 768px) { .header img#imgLogo { margin-left: -50px; } }
  @media screen {
    img#imgLogoPrint { display: none; }
    h1 { display: none; }
  }
  @media print {
    img#imgLogo { display: none; }
    h1 { font-size: 14pt; }
  }
</style>