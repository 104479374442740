<template>
  <v-expansion-panels class="Storage" v-if="canStorage('list')">
    <v-expansion-panel>
      <v-expansion-panel-header>{{field.label}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="line">
          <FieldMultilines :field="targets()" :vmodel="list" :logic="this" :FormLines="FormLines"/>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import * as storage from '@/lib/storage'
import { mapState } from 'vuex'
import FieldMultilines from './FieldMultilines'
export default {
  name: "FieldStorageList",
  components: { FieldMultilines },
  props: ['FormLines', 'logic', 'field'],
  computed: { ...mapState(['user', 'register']) },
  data: () => ({
    list: { files: []},
    file: null
  }),
  methods: {
    targets() { return {
      "name": "files",
      "type": 'multilines',
      "addButtom": this.canStorage('upload'),
      "removeButtom": this.canStorage('remove'),
      "clickRemove": 'removeLine',
      "fields": [
        {
          "name": "name",
          "label": 'Nome',
          "type": "storage",
          "columnwidth": "50%",
          "change": "listFiles"
        },
        {
          "name": "size",
          "label": 'Tamanho',
          "type": "text",
          "disabled": true,
          "columnwidth": "10%"
        },
        {
          "name": "timeCreated",
          "label": 'Criado',
          "type": "text",
          "disabled": true,
          "columnwidth": "20%"
        },
        {
          "name": "updated",
          "label": 'Modificado',
          "type": "text",
          "disabled": true,
          "columnwidth": "20%"
        }
      ]
    }},
    canStorage(action) { return this.$store.getters.canStorage(action) },
    removeLine(params) {
      const { value } = params
      !value.name || this.removeFile(value.name)
    },
    getStorageParams() {
      return {
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        _id: this.FormLines.register._id,
        early: true
      }
    },
    dateFormat(date) { return this.FormLines.Form.js_functions.dateToString(new Date(date), { addTime: true }) },
    async listFiles() {
      const FormLines = this.FormLines
      const params = this.getStorageParams()
      const _this = {}
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: _this, name: 'files' }, func: () => storage.listFiles(params) })) { return this.$store.commit("hideOverlay") }
      this.list.files = []
      _this.files
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.folder.localeCompare(b.folder))
        .map(file => this.list.files.push({
          name: file.relativePath,
          size: file.size,
          timeCreated: this.dateFormat(file.timeCreated),
          updated: this.dateFormat(file.updated)
        }))
    },
    async removeFile(fileName) {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      const params = { ...this.getStorageParams(), fileName }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => storage.removeFile(params) })) { return this.$store.commit("hideOverlay") }
      this.$store.commit("hideOverlay")
    }
  },
  mounted() { this.listFiles() }
}
</script>

<style scoped>
  .Storage {
    margin-top: 10px;
  }
  div.divLine {
    display: flex;
    margin-top: 10px;
    flex-direction: row-reverse;
  }
</style>