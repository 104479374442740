import * as js_functions from './js_functions.js'
import * as files from '@/lib/files'

async function readRegister(Buttons, _id) {
  const backend = await import('./backend.js')
  return await backend.post({
    module: Buttons.$route.name,
    dataId: Buttons.$route.query.menuId,
    action: 'read/one',
    body: { "query": { _id } }
  })
}

async function updateRegisterPatch(Buttons, fieldsToUpdate, fieldsToRemove) {
  Buttons.$store.commit("showOverlay")
  Buttons.$store.commit("hideDialog")
  const backend = await import('./backend.js')
  await backend.patch({
    module: Buttons.$route.name,
    dataId: Buttons.$route.query.menuId,
    body: { set: { ...fieldsToUpdate }, unset: { ...fieldsToRemove } },
    early: true
  }).then(async res => {
    if (res.status == 200) {
      const { fixedRegister } = files.fixRegister(await readRegister(Buttons, fieldsToUpdate._id), Buttons.Form.fields)
      Buttons.$store.commit('changeRegister', fixedRegister)
      Buttons.$store.commit("hideOverlay")
      Buttons.$store.commit("showAlert", { type: 'success', text: 'Gravado com sucesso!', dismissible: true, time: 2000 })
    }
    else { throw { data: "Ocorreu um problema ao gravar." } }
  }).catch(e => Buttons.$store.commit("showError", e))
}

function getFieldsToUpdate(Buttons) {
  let fieldsToUpdate = {}
  Object.keys(Buttons.register).map(key => {
    if (Buttons.register[key] != '' && Buttons.register[key] != []) {
      const _register = Array.isArray(Buttons.register[key]) ? js_functions.cleamObjectsArray(Buttons.register[key]) : Buttons.register[key]
      const _registerOriginal = Array.isArray(Buttons.registerOriginal[key]) ? js_functions.cleamObjectsArray(Buttons.registerOriginal[key]) : Buttons.registerOriginal[key]
      if (!js_functions.isEqual({ firtsThing: _register, secondThing: _registerOriginal })) {
        Object.assign(fieldsToUpdate, { [key]: Array.isArray(Buttons.register[key]) ? js_functions.cleamObjectsArray(Buttons.register[key]) : Buttons.register[key] })
      }
    }
  })
  fieldsToUpdate.lastUpdate = new Date(Date.now()).toISOString()
  fieldsToUpdate._id = Buttons.registerOriginal._id
  return fieldsToUpdate
}

function getFieldsToRemove(Buttons) {
  let fieldsToRemove = {}
  Object.keys(Buttons.register).map(key => {
    if ((Buttons.register[key] == '' && Buttons.registerOriginal[key] != '') || (Buttons.register[key] == [] && Buttons.registerOriginal[key] != [])) {
      Object.assign(fieldsToRemove, { [key]: '' })
    }
  })
  return fieldsToRemove
}

function getCorrectJSONObject(Buttons, key) {
  let jsonObject = js_functions.getJSONObject({ fields: Buttons.Form.fields, name: key })
  const notExistJsonObject = jsonObject.name == undefined
  jsonObject = key.startsWith('link_') && notExistJsonObject ? js_functions.getJSONObject({ fields: Buttons.Form.fields, name: key.substring(5) }) : jsonObject
  return jsonObject
}

function getConflictKeys(Buttons, newRegister, registerOriginal, currentRegister, jsonObjectDefault) {
  let allKeys = []
  if (newRegister != undefined && Array.isArray(Object.keys(newRegister))) { Object.keys(newRegister).map(key => allKeys.push(key) ) }
  if (currentRegister != undefined && Array.isArray(Object.keys(currentRegister))) { Object.keys(currentRegister).map(key => allKeys.includes(key) || allKeys.push(key) ) }
  if (registerOriginal != undefined && Array.isArray(Object.keys(registerOriginal))) { Object.keys(registerOriginal).map(key => allKeys.includes(key) || allKeys.push(key) ) }
  return allKeys.filter(key => {
    const newValue = newRegister != undefined ? newRegister[key] : undefined
    const originalValue = registerOriginal != undefined ? registerOriginal[key] : undefined
    const currentValue = currentRegister != undefined ? currentRegister[key] : undefined
    const jsonObject = jsonObjectDefault ? jsonObjectDefault : getCorrectJSONObject(Buttons, key)
    const noUpdate = jsonObject && jsonObject.update == 'noUpdate' ? true : false
    const orginalDiffCurrent = !js_functions.isEqual({ firtsThing: originalValue, secondThing: currentValue, sameAsUndefined: [''], emptyArrayIsUndefined: true })
    const currentDiffNew = !js_functions.isEqual({ firtsThing: currentValue, secondThing: newValue, sameAsUndefined: [''], emptyArrayIsUndefined: true })
    if (key == 'lastUpdate') { return false }
    return noUpdate ? orginalDiffCurrent : orginalDiffCurrent && currentDiffNew
  })
}

function getConflicts(Buttons, newRegister, currentRegister) {
  const conflictKeys = getConflictKeys(Buttons, newRegister, Buttons.registerOriginal, currentRegister)
  let conflicts = []
  conflictKeys.map(key => {
    const jsonObject = getCorrectJSONObject(Buttons, key)
    const keyLabel = jsonObject.label ? jsonObject.label : key
    const keyUpdate = jsonObject.update
    const keyType = jsonObject.type
    if (!Array.isArray(newRegister[key]) || jsonObject.type == 'map') { conflicts.push({ type: keyType, name: key, label: keyLabel, original: Buttons.registerOriginal[key], current: currentRegister[key], new: newRegister[key], update: keyUpdate }) }
    else {
      const addLineConflict = (params) => {
        const { lineIndex, newLine, originalLine, currentLine } = params
        const _conflictKeys = getConflictKeys(Buttons, newLine, originalLine, currentLine, jsonObject)
        _conflictKeys.map(_key => {
          const jsonObjectMultilines = js_functions.getJSONObject({ fields: Buttons.Form.fields, name: _key, multilines: key })
          const keyMultilinesLabel = jsonObjectMultilines.label ? jsonObjectMultilines.label : _key
          conflicts.push({ type: jsonObjectMultilines.type, name: _key, multilines: key, line: lineIndex, label: `${keyLabel}</br>(linha: ${lineIndex + 1})</br>${keyMultilinesLabel}`, original: Buttons.registerOriginal[key] && Buttons.registerOriginal[key][lineIndex] ? Buttons.registerOriginal[key][lineIndex][_key] : '', current: currentRegister[key] && currentRegister[key][lineIndex] ? currentRegister[key][lineIndex][_key] : '', new: newRegister[key] && newRegister[key][lineIndex] ? newRegister[key][lineIndex][_key] : '', update: keyUpdate })
        })
      }
      let maxNumberOfLines = 0
      if (newRegister != undefined && newRegister[key] != undefined && Array.isArray(newRegister[key])) { maxNumberOfLines = newRegister[key].length }
      if (currentRegister != undefined && currentRegister[key] != undefined && Array.isArray(currentRegister[key]) && currentRegister[key].length > maxNumberOfLines) { maxNumberOfLines = currentRegister[key].length }
      if (Buttons.registerOriginal != undefined && Buttons.registerOriginal[key] != undefined && Array.isArray(Buttons.registerOriginal[key]) && Buttons.registerOriginal[key].length > maxNumberOfLines) { maxNumberOfLines = Buttons.registerOriginal[key].length }
      for (let lineIndex = 0; lineIndex < maxNumberOfLines; lineIndex++) {
        const newLine = newRegister[key] ? newRegister[key][lineIndex] : undefined
        const originalLine = Buttons.registerOriginal[key] ? Buttons.registerOriginal[key][lineIndex] : undefined
        const currentLine = currentRegister[key] ? currentRegister[key][lineIndex] : undefined
        addLineConflict({ lineIndex, newLine, originalLine, currentLine })
      }
    }
  })
  return conflicts
}

function getConflictsTable(conflicts) {
  const changeIdsToValues = (conflict) => {
    let objectWithValues = conflicts.find(_conflict => _conflict.name.startsWith(`link_${conflict.name}`) && _conflict.multilines == conflict.multilines && _conflict.line == conflict.line)
    objectWithValues = objectWithValues != undefined ? objectWithValues : conflict
    conflict.original = objectWithValues.original ? objectWithValues.original : ''
    conflict.current = objectWithValues.current ? objectWithValues.current : ''
    conflict.new = objectWithValues.new ? objectWithValues.new : ''
    return conflict
  }
  let _conflicts = conflicts.filter(conflict => !conflict.name.startsWith('link_'))
  _conflicts = _conflicts.map(_conflict => conflicts.find(conflict => conflict.name == `link_${_conflict.name}` && _conflict.multilines == conflict.multilines) ? changeIdsToValues(_conflict) : _conflict)
  const tableStart = '<table><thead><tr><th>Campo</th><th>Quando você entrou estava</th><th>Enquanto isso alguem alterou para</th><th>Você pretende alterar para</th></tr></thead><tbody>'
  const checkTextValue = (value) => Array.isArray(value) ? 'Impossível converter para texto!' : value == undefined ? '-' : value
  const conflictsTable = _conflicts.reduce((str, conflict) => str += `<tr><td>${conflict.label}</td><td>${checkTextValue(conflict.original)}</td><td>${checkTextValue(conflict.current)}</td><td>${checkTextValue(conflict.new)}</td></tr>`, tableStart)
  return `${conflictsTable}</tbody></table>`
}

function updateFieldsNoConflicts(Buttons, noConflicts, currentRegister) {
  noConflicts.map(noConflict => {
    const { name, multilines, line } = noConflict
    multilines ? Buttons.register[multilines][line][name] = currentRegister[multilines][line][name] : Buttons.register[name] = currentRegister[name]
  })
}

function newConflictsAlert(previousConflicts) { return Array.isArray(previousConflicts) ? "<p class='emphasis'>Mais alguns campos foram alterados enquanto você analisava os campos que iria sobrescrever.</br>Por favor verifique novamente se está de acordo.</p>" : '' }

export async function updateRegisterSync(Buttons, previousConflicts) {
  const fieldsToUpdate = getFieldsToUpdate(Buttons)
  const fieldsToRemove = getFieldsToRemove(Buttons)
  const currentRegister = Buttons.unitTest ? Buttons.currentRegister : await readRegister(Buttons, fieldsToUpdate._id)
  const conflicts = getConflicts(Buttons, Buttons.register, currentRegister)
  const noConflicts = conflicts.filter(conflict => conflict.update == 'noConflicts')
  const noUpdates = conflicts.filter(conflict => conflict.update == 'noUpdate')
  let conflictsType = noConflicts.length > 0 ? 'noConflicts' : 'conflicts'
  conflictsType = noUpdates.length > 0 ? 'noUpdates' : conflictsType
  const _conflicts = conflictsType == 'conflicts' ? conflicts.filter(conflict => conflict.original != conflict.new) : conflicts
  const numberOfConflictsExceptLinks = _conflicts.filter(conflict => !conflict.name.startsWith('link_')).length
  if (numberOfConflictsExceptLinks > 0 && ((_conflicts.length > 0 && (!Array.isArray(previousConflicts) || _conflicts.length > previousConflicts.length)) || noUpdates.length > 0)) {
    Buttons.$store.commit("hideOverlay")
    const icon = {
      conflicts: { name: "mdi-pause-octagon" },
      noConflicts: { name: "mdi-alert-octagon", color: "warning" },
      noUpdates: { name: "mdi-close-octagon", color: "error" }
    }
    const title = {
      conflicts: 'Você vai sobrescrever alterações!',
      noConflicts: 'Alguns campos não podem ser atualizados!',
      noUpdates: 'Não é possível atualizar o registro!'
    }
    const text = {
      conflicts: `${newConflictsAlert(previousConflicts)}Alguns campos foram alterados enquando você trabalhava neste registro:</br></br>${getConflictsTable(_conflicts)}`,
      noConflicts: `${newConflictsAlert(previousConflicts)}Alguns campos foram alterados enquando você trabalhava neste registro e precisam permanecer iguais a essa última alteração:
        </br></br>${getConflictsTable(noConflicts)}</br>
        Deseja alterar o valor desses campos para os valores da última atualização (coluna: "Enquanto isso alguem alterou para")?`,
      noUpdates: `${newConflictsAlert(previousConflicts)}Alguns campos foram alterados enquando você trabalhava neste registro e portanto não vai ser possível atualizar:
        </br></br>${getConflictsTable(noUpdates)}</br>
        Você precisa ir para a lista e entrar no registro novamente para atualizar essas informações.`
    }
    const buttonYes = {
      conflicts: 'Ok, pode prosseguir!',
      noConflicts: 'Sim, pode alterar!',
      noUpdates: 'Ir para a lista!'
    }
    const buttonNo = {
      conflicts: 'Cancelar',
      noConflicts: 'Cancelar',
      noUpdates: 'Fechar'
    }
    const action = {
      conflicts: () => updateRegisterSync(Buttons, _conflicts),
      noConflicts: () => {
        updateFieldsNoConflicts(Buttons, noConflicts, currentRegister)
        updateRegisterSync(Buttons, [])
      },
      noUpdates: () => {
        Buttons.$router.push({ name: Buttons.$route.name, query: { ...Buttons.$route.query, tabNumber: 1 } })
          .then(() => { Buttons.$store.commit("hideOverlay") })
          .catch(() => { Buttons.$store.commit("hideOverlay") })
        Buttons.$store.commit("hideDialog")
      },
    }
    Buttons.$store.commit("showDialog", {
      title: title[conflictsType],
      text: text[conflictsType],
      buttonYes: buttonYes[conflictsType],
      buttonNo:  buttonNo[conflictsType],
      action: action[conflictsType],
      icon: icon[conflictsType],
      width: "800"
    })
    return { conflictsType, _conflicts, noConflicts, noUpdates }
  }
  else { return Buttons.unitTest ? { fieldsToUpdate, fieldsToRemove } : updateRegisterPatch(Buttons, fieldsToUpdate, fieldsToRemove) }
}