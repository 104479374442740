import * as backend from '@/lib/backend'
import * as files from '@/lib/files'

export async function getMenuObject(params) {
  const { parent, hmenu, dataId } = params
  const getMenu = async () => {
    if (hmenu == 'controls') { parent.$store.commit('changeMenus', { hmenu: hmenu, menus: await files.get('controls.json') }) }
    else {
      let params = { module: hmenu, dataId: 'menu/_' + hmenu, early: true }
      await backend.get(params)
        .then(res => { parent.$store.commit('changeMenus', { hmenu: hmenu, menus: res.data }) })
        .catch(e => {  parent.$store.commit("showError", e) })
    }
    return
  }
  const fieldName = { data: 'dataId', reports: 'report', controls: 'dataId' }
  const findMenuObject = () => parent.menus[hmenu].find(_menuObject => _menuObject[fieldName[hmenu]] == dataId)
  if (!Array.isArray(parent.menus[hmenu]) || parent.menus[hmenu].length == 0) { await getMenu() }
  if (!findMenuObject()) { await getMenu() }
  const menuObject = findMenuObject()
  return menuObject ? menuObject : {}
}