
export function downloadFile({ data, type, name }) {
  const blob = new Blob([data], { type: 'text/plain' })
  const e = document.createEvent('MouseEvents')
  let a = document.createElement('a')
  a.download = name
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl = [type, a.download, a.href].join(':')
  e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}
export function downloadStorageFile({ url, type, name }) {
  const e = document.createEvent('MouseEvents')
  let a = document.createElement('a')
  a.download = name
  a.href = url
  a.target = '_blanck'
  a.dataset.downloadurl = [type, a.download, a.href].join(':')
  e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}
export function kml({ documentName, placemarkName, coordinates }) {
  return `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
    <Document>
      <name>${documentName}</name>
      <StyleMap id="msn_ylw-pushpin6101">
        <Pair>
          <key>normal</key>
          <styleUrl>#sn_ylw-pushpin170</styleUrl>
        </Pair>
        <Pair>
          <key>highlight</key>
          <styleUrl>#sh_ylw-pushpin611</styleUrl>
        </Pair>
      </StyleMap>
      <Style id="sh_ylw-pushpin611">
        <IconStyle>
          <scale>1.3</scale>
          <Icon>
            <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
        </IconStyle>
        <LineStyle>
          <color>ff0000ff</color>
          <width>3</width>
        </LineStyle>
        <PolyStyle>
          <color>4d00ffff</color>
          <fill>0</fill>
        </PolyStyle>
      </Style>
      <Style id="sn_ylw-pushpin170">
        <IconStyle>
          <scale>1.1</scale>
          <Icon>
            <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
        </IconStyle>
        <LineStyle>
          <color>ff0000ff</color>
          <width>3</width>
        </LineStyle>
        <PolyStyle>
          <color>4d00ffff</color>
          <fill>0</fill>
        </PolyStyle>
      </Style>
      ${polygons(placemarkName, coordinates)}
    </Document>
    </kml>`
}
function polygons(placemarkName, coordinates) {
  return coordinates.reduce((_coordinates , coords) => _coordinates += polygon(placemarkName, coords),'')
}
function polygon(placemarkName, coordinates) {
  const _coordinates = coordinates.poligono ? coordinates.poligono : coordinates
  const _info = coordinates.info ? Object.keys(coordinates.info).reduce((info, key) => info += `${key} ${coordinates.info[key]}`, '') : placemarkName
  return `
  <Placemark>
    <name>${_info}</name>
    <styleUrl>#msn_ylw-pushpin6101</styleUrl>
    <Polygon>
      <tessellate>1</tessellate>
      <outerBoundaryIs>
        <LinearRing>
          <coordinates>
            ${_coordinates.reduce((coords, point) => coords += `${point.lng},${point.lat},0 `,'')}
          </coordinates>
        </LinearRing>
      </outerBoundaryIs>
    </Polygon>
  </Placemark>`
}