<template>
  <div :class="{'alert': float}">
    <v-alert :type="alert.type" v-model="alert.show" elevation="4" :class="{'float': float}">
      <v-row align="center">
        <v-col class="grow">{{ alert.text }}</v-col>
        <v-col class="shrink"><v-btn v-if="alert.dismissible" @click="hideAlert" outlined small>Fechar</v-btn></v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "Alert",
  props: ['float'],
  computed: { ...mapState(['alert']) },
  watch: {
    'alert.show': function(value) {
      if (value == true && this.alert.time) { setTimeout(() => { this.$store.commit("hideAlert") }, this.alert.time) }
    }
  },
  methods: {
    hideAlert() { this.$store.commit("hideAlert") }
  }
}
</script>

<style>
  div.alert {
    position: absolute;
    width: 100%;
    white-space: pre-wrap;
  }
  .v-alert.float {
    margin: 0px auto;
    min-width: 320px;
    width: 30%;
    z-index: 9999;
  }
</style>