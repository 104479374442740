<template>
  <div v-if="field.type === 'button' && (field.show == undefined || field.show)" :class="{ 'hideOnPrint': field.hideOnPrint }">
    <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs" v-on="on"
          v-if="field.type === 'button' && (field.show == undefined || field.show)"
          :key="field.name"
          :name="field.name"
          :ref="field.name"
          :solo="field.solo"
          :outlined="!field.solo"
          dense
          hide-details
          :disabled="field.disabled || blockField(field.name, multiline) || blockLine()"
          :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class, 'button': true }"
          :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
          :background-color="field.backgroundcolor"
          :color="styles['--over-color']"
          v-on:keyup="event(field.keyup)"
          @click="event(field.click || field.method)"
          :autofocus="field.focus"
        >
          {{ field.label || field.text }}
          <v-icon>{{ field.icon }}</v-icon>
          <template slot="append">
            <AppendIconTooltip :field="field" />
          </template>
        </v-btn>
      </template>
      <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldText",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { AppendIconTooltip },
  computed: { ...mapState(['register', 'styles']) },
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in (this.vmodel || [])
      return this.field.disabledLine && !enabledLine ? true : false
    },
    event(functionName) {
      if (this.FormLines && this.FormLines.actionIsActive) { this.FormLines.actionIsActive(functionName) ? this.FormLines.actionsActive = this.FormLines.actionsActive.filter(action => action !== functionName) : this.FormLines.actionsActive.push(functionName) }
      if (functionName && this.logic && this.logic[functionName]) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines ?? {}, name: this.field.name, value: '', vmodel: this.vmodel, lineNumber: this.line }) }
    }
  },
  mounted() {
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  div { padding: 2px 5px; }
  @media print { div.hideOnPrint { display: none; } }
</style>