<template>
  <v-expansion-panels class="Import">
    <v-expansion-panel>
      <v-expansion-panel-header>Importar Dados</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="divLine">
          <v-file-input label="Escolha um arquivo" truncate-length="50" variant="outlined" v-model="file" @change="loadRegisters" @click:clear="jsonRegisters = null" show-size></v-file-input>
        </div>
        <div v-if="file"><FieldLinkSimple :field="loadModelField()" :vmodel="model" :logic="this" :FormLines="FormLines"/></div>
        <div v-show="!onlyModels() && !model.modelId && jsonRegisters" class="divLine">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>De - Para</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="jsonRegisters" class="line">
                  <div class="subtitle">
                    Quantidade de registros: {{ jsonRegisters.length }}
                    <v-btn @click="unifyRegisters" class="buttom" small outlined>Unificar</v-btn>
                  </div>
                </div>
                <div v-if="jsonRegisters" class="divLine">
                  <FieldMultilines :field="targets()" :vmodel="fromTo" :logic="Form.logic" :FormLines="FormLines"/>
                </div>
                <div v-if="jsonRegisters">
                  <v-progress-linear v-model="progressBar" height="25" :color="FormLines.styles['--over-color']">
                    <strong>{{ updateProgress() }}%</strong>
                  </v-progress-linear>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="!onlyModels() && loadConfig.addFields && jsonRegisters" class="divLine">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Adicionar Campo</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div><input type="file" id="configFile" ref="configFile"></div>
                <div class="divLine">
                  <v-btn @click="addField" :color="FormLines.styles['--over-color']" class="buttom">Adicionar campo</v-btn>
                  <FieldText :field="addFieldName()" :vmodel="fromTo" :logic="Form.logic" :FormLines="FormLines"/>
                  <FieldText :field="addFieldLabel()" :vmodel="fromTo" :logic="Form.logic" :FormLines="FormLines"/>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="!onlyModels() && !model.modelId && jsonRegisters" class="divLine">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Carregar Configurações de Arquivo</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="divLine">
                  <v-file-input label="Escolha um arquivo" truncate-length="50" variant="outlined" v-model="configFile" show-size></v-file-input>
                </div>
                <div class="divLine">
                  <v-btn @click="loadConfig.fileFields = !loadConfig.fileFields" :class="{ 'active': loadConfig.fileFields }" class="buttom" small>fileFields</v-btn>
                  <v-btn @click="loadConfig.formFields = !loadConfig.formFields" :class="{ 'active': loadConfig.formFields }" class="buttom" small>formFields</v-btn>
                  <v-btn @click="loadConfig.targets = !loadConfig.targets" :class="{ 'active': loadConfig.targets }" class="buttom" small>targets</v-btn>
                </div>
                <div class="divLine">
                  <v-btn @click="loadConfigData" :color="FormLines.styles['--over-color']" class="buttom">Carregar</v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="!onlyModels() && (!model.modelId || loadConfig.saveConfig) && jsonRegisters" class="divLine">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Salvar Configurações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="loadConfig.addFields" label="Permitir adicionar campos?"></v-checkbox>
                      <v-checkbox v-model="loadConfig.saveConfig" label="Permitir salvar configurações?"></v-checkbox>
                      <v-checkbox v-model="loadConfig.importDB" label="Permitir importação para o banco de dados?"></v-checkbox>
                      <v-checkbox v-model="loadConfig.importRegister" label="Permitir importação para o registro?"></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-model="loadConfig.fileFields" label="Salvar lista de campos atuais do arquivo?"></v-checkbox>
                      <v-checkbox v-model="loadConfig.formFields" label="Salvar lista de campos atuais do formulário?"></v-checkbox>
                      <v-checkbox v-model="loadConfig.targets" label="Salvar tabela de-para?"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
                <div class="divLine">
                  <v-btn @click="saveConfigFile" class="buttom" :disabled="!isDeParaValid()">Salvar Configurações em arquivo</v-btn>
                  <FieldText :field="modelNameField()" :vmodel="model" :logic="this" :FormLines="FormLines"/>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="!onlyModels() && !model.modelId && jsonRegisters" class="divLine">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Executar função</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div><FieldText :field="execFuncName()" :vmodel="loadConfig" :logic="Form.logic" :FormLines="FormLines"/></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="jsonRegisters" class="divLine">
          <v-btn v-if="loadConfig.importDB && isDeParaValid()" @click="importRegisters" color="success" class="buttom" :disabled="!isDeParaValid()">Importar no Banco de Dados</v-btn>
          <v-btn v-if="loadConfig.importRegister && loadConfig.isOneRegister && isDeParaValid()" @click="clickLoadData" color="success" class="buttom" :disabled="!isDeParaValid()">Carregar dados neste registro</v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import * as convert from '@/lib/convert'
import * as backend from '@/lib/backend'
import * as downloads from '@/lib/downloads'
import { mapState } from 'vuex'
import FieldMultilines from './FieldMultilines'
import FieldText from './FieldText'
import FieldLinkSimple from './FieldLinkSimple'
export default {
  name: "Import2",
  components: { FieldMultilines, FieldText, FieldLinkSimple },
  props: ['Form', 'FormLines', 'opened'],
  computed: { ...mapState(['user', 'register']) },
  data: () => ({
    file: null,
    configFile: null,
    jsonRegisters: null,
    fileFields: [],
    formFields: [],
    fromTo: {
      addFieldLabel: '',
      addFieldName: '',
      targets: []
    },
    progressBar: 0,
    loadConfig: {
      fileFields: true,
      formFields: false,
      targets: true,
      saveConfig: true,
      addFields: true,
      importDB: true,
      importRegister: true,
      isOneRegister: false,
      execFuncName: ''
    },
    model: {
      modelName: '',
      modelId: ''
    }
  }),
  methods: {
    ifIsNotTest(code) { return !this.$store || code() },
    open_close() { this.opened = !this.opened },
    canAction(action) { return this.$store.getters.canAction(action) },
    targets() { return {
      "name": "targets",
      "type": 'multilines',
      "fields": [
        {
          "name": "from",
          "label": 'De',
          "type": "select",
          "multiple": true,
          "showFirstSelected": true,
          "options": this.fileFields,
          "columnwidth": "18%"
        },
        {
          "name": "to",
          "label": 'Para',
          "type": "select",
          "options": this.formFields,
          "columnwidth": "18%",
          "maxwidth": "400px"
        },
        {
          "name": "line",
          "label": 'Linha',
          "type": "text",
          "columnwidth": "5%"
        },
        {
          "name": "type",
          "label": 'Tipo',
          "type": "select",
          "multiple": true,
          "options": [
            { "option": { "text": "Coordenadas", "value": "coords" } },
            { "option": { "text": "Chave Atualização", "value": "updateKey" } },
            { "option": { "text": "Chave Carregamento", "value": "loadKey" } },
            { "option": { "text": "Data", "value": "date" } }
          ],
          "columnwidth": "13%"
        },
        {
          "type": "link",
          "fields": [
            {
              "name": "module",
              "label": "Link Módulo",
              "source": "DB",
              "module": "controls",
              "dataId": "_data",
              "linkField": "title",
              "linkValue": "dataId",
              "linkIcon": false
            },
            {
              "name": "fields",
              "label": "Link Campo",
              "source": "JSON",
              "linkField": "label",
              "linkValue": "name",
              "linkIcon": false
            }
          ]
        },
        {
          "type": "text",
          "name": "filter",
          "label": "Filtro",
          "columnwidth": "12%"
        }
      ]
    }},
    addFieldLabel() { return {
      "name": "addFieldLabel",
      "label": "Rótulo",
      "type": 'text',
    }},
    addFieldName() { return {
      "name": "addFieldName",
      "label": "Nome",
      "type": 'text',
    }},
    modelNameField() { return {
      "name": "modelName",
      "label": "Nome do Modelo",
      "type": 'text',
      "actions": [
        { "type": "button", "text": "Salvar Modelo", "method": "saveModel", "loading": false }
      ],
      "disabled": !this.isDeParaValid()
    }},
    loadModelField() { return {
      "name": "modelId",
      "label": "Modelo de Importação",
      "type": 'linkSimple',
      "module": "controls",
      "dataId": "_import",
      "linkField": "name",
      "field": "dataId",
      "value": this.$route.query.menuId,
      "change": "loadConfigData"
    }},
    execFuncName() { return {
      "name": "execFuncName",
      "label": "Função",
      "type": 'text',
    }},
    addField() {
      if (!this.fromTo.addFieldLabel || this.fromTo.addFieldLabel == '' || !this.fromTo.addFieldName || this.fromTo.addFieldName == '') { return this.$store.commit("showError", { data: 'Preencha o rótulo e o nome do campo!' }) }
      this.formFields.push({ "option": { "text": this.fromTo.addFieldLabel, "value": { "name": this.fromTo.addFieldName } } })
      this.formFields = this.formFields.sort((a, b) => a.option.text.localeCompare(b.option.text))
      this.fromTo.addFieldLabel = this.fromTo.addFieldName = ''
      this.$store.commit("showAlert", { type: 'success', text: 'Campo adicionado!', dismissible: true, time: 2000 })
    },
    onlyModels() { return this.$store ? !this.user?.permission?.controlsModules?.find(module => module.module == '_import')?.import : false },
    isDeParaValid() { return this.progressBar > 0 ? true : false },
    updateProgress() {
      let done = []
      if (!this.fromTo.targets || !this.fileFields) { return }
      this.fromTo.targets.map(target => {
        if (target.from && target.to && !done.includes(target.from)) { done.push(target.from) }
      })
      this.progressBar = (done.length && this.fileFields.length) ? done.length / this.fileFields.length * 100 : 0
      return `Campos à importar: ${done.length ?? 0} de ${this.fileFields.length ?? 0} - ${Math.ceil(this.progressBar)}`
    },
    async loadRegisters() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.getRegisters(this.FormLines) })) { return this.$store.commit("hideOverlay") }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.showFields(this.FormLines) })) { return this.$store.commit("hideOverlay") }
      this.$store.commit("hideOverlay")
    },
    async getRegisters(FormLines) {
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'file'}, func: () => this.getFile() })) { return { status: false } }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'fileType'}, func: () => this.getFileType(this.file) })) { return { status: false } }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkConvertFileType(this.fileType) })) { return { status: false } }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'jsonRegisters'}, func: () => this.convert() })) { return { status: false } }
      return { status: true }
    },
    async showFields(FormLines) {
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'fileFields'}, func: () => this.getAllOptions() })) { return { status: false } }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this.fromTo, name: 'targets'}, func: () => this.showFieldsFromTo() })) { return { status: false } }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'formFields'}, func: () => this.getFormFields() })) { return { status: false } }
      return { status: true }
    },
    getFile(fileName) {
      const _file = fileName ? this[fileName] : this.file
      if (!_file) { return { status: false, alertType: 'warning', alertText: 'Selecione um arquivo!' } }
      return { status: true, data: _file }
    },
    getFileType(file) {
      if (!file || !file.name) { return { status: false, alertType: 'error', alertText: 'Não foi possível identificar o arquivo.' } }
      return { status: true, data: convert.getExtension(file.name) }
    },
    checkConvertFileType(fileType) {
      if (!convert[`${fileType}_to_json`]) { return { status: false, alertType: 'error', alertText: 'Tipo de arquivo não suportado.' } }
      return { status: true }
    },
    convert() {
      return convert[`${this.fileType}_to_json`](this.file).then(_jsonRegisters => {
        return { status: true, data: _jsonRegisters}
      }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao converter o arquivo', error: e } })
    },
    getAllOptions() {
      let _options = []
      const getOptionsOfParent = (parent, parentKey) => {
        const _parentPath = parentKey ? `${parentKey} > ` : ''
        Object.keys(parent).map(key => {
          let _option = { "option": { "text": `${_parentPath}${key}`, "value": `${parentKey ? parentKey + '>' : ''}${key}`, "name": key } }
          if (parentKey) { Object.assign(_option.option, { "parent": parentKey }) }
          if (!_options.find(item => item.option.value == `${parentKey ? parentKey + '>' : ''}${key}`)) { _options.push(_option) }
        })
      }
      this.jsonRegisters.map(_register => {
        getOptionsOfParent(_register)
        Object.keys(_register).map(key => {
          if (Array.isArray(_register[key])) {
            _register[key].map(line => { getOptionsOfParent(line, key) })
          }
        })
      })
      return { status: true, data: _options.sort((a, b) => a.option.text.localeCompare(b.option.text)) }
    },
    showFieldsFromTo() {
      let _targets = []
      this.fileFields.map((option, index) => {
        let _target = { from: [option.option.value], to: this.fromTo?.targets[index]?.to ?? '', type: '' }
        if (option.option.parent) { Object.assign(_target, { parent: option.option.parent }) }
        _targets.push(_target)
      })
      return { status: true, data: _targets }
    },
    getFormFields() {
      let _formFields = []
      this.Form.fields.map(line => {
        _formFields.push(...this.getFieldsOfParent(line))
        !line.fields || line.fields.map(field => field.type != 'multilines' || _formFields.push(...this.getFieldsOfParent(field)))
      })
      return { status: true, data: _formFields.sort((a, b) => a.option.text.localeCompare(b.option.text)) }
    },
    getFieldsOfParent(parent) {
      const parentPath = (parent.type == 'multilines') ? `${parent.label ? parent.label + ' ' : ''}[${parent.name}] > ` : ''
      const multilinesLineParts = ['fields', 'fields2']
      let _formFields = []
      multilinesLineParts.map(linePart => {
        !parent[linePart] || parent[linePart].map(field => {
          if (field.type != 'link' && field.type != 'linkSimple') {
            let _option = { "option": {  } }
            const multilines = (field.type == 'multilines') ? ' (multilines)' : ''
            Object.assign(_option.option, { "text": `${parentPath}${field.label ? field.label + ' ' : ''}[${field.name}]${multilines}`, "value": { "name": field.name } })
            if (parent.type == 'multilines') { Object.assign(_option.option.value, { "parent": parent.name }) }
            _formFields.push(_option)
          }
          else if (field.type == 'linkSimple') {
            let _option = { "option": {  } }
            Object.assign(_option.option, { "text": `${parentPath}${field.label ? field.label + ' ' : ''}[${field.name}]`, "value": { "name": field.name } })
            if (parent.type == 'multilines') { Object.assign(_option.option.value, { "parent": parent.name }) }
            _formFields.push(_option)
            _option = { "option": {  } }
            Object.assign(_option.option, { "text": `${parentPath}${field.label ? field.label + ' ' : ''}[${field.name}] (link)`, "value": { "name": `link_${field.name}` } })
            if (parent.type == 'multilines') { Object.assign(_option.option.value, { "parent": parent.name }) }
            _formFields.push(_option)
          }
          else {
            field.fields.map(_field => {
              let _option = { "option": {  } }
              Object.assign(_option.option, { "text": `${parentPath}${_field.label ? _field.label + ' ' : ''}[${_field.name}]`, "value": { "name": _field.name } })
              if (parent.type == 'multilines') { Object.assign(_option.option.value, { "parent": parent.name }) }
              _formFields.push(_option)
            })
            field.fields.map(_field => {
              let _option = { "option": {  } }
              Object.assign(_option.option, { "text": `${parentPath}${_field.label ? _field.label + ' ' : ''}[${_field.name}] (link)`, "value": { "name": `link_${_field.name}` } })
              if (parent.type == 'multilines') { Object.assign(_option.option.value, { "parent": parent.name }) }
              _formFields.push(_option)
            })
          }
        })
      })
      return _formFields
    },
    async unifyRegisters() {
      this.loadConfig.isOneRegister = true
      if (this.jsonRegisters.length == 1) { return }
      this.jsonRegisters = [{ registros: [...this.jsonRegisters] }]
      await this.showFields(this.FormLines)
    },
    clickLoadData() { this.insertRegister() },
    async insertRegister(previousRegister) {
      this.ifIsNotTest(() => this.$store.commit("showOverlay"))
      if (!this.loadConfig.isOneRegister) { return this.ifIsNotTest(() => this.$store.commit("hideOverlay")) }
      const loadKeys = this.fromTo.targets.filter(target => Array.isArray(target.type) && target.type.find(type => type == "loadKey")).map(target => target.to)
      const data = await this.fromToRegister()
      if (!data[0]) {
        this.$store.commit("showAlert", { type: 'warning', text: 'Nenhum registro encontrado para importar!', dismissible: true })
        this.$store.commit("hideOverlay")
        return {}
      }
      let _register = previousRegister ? previousRegister : { ...this.FormLines.register }
      Object.keys(data[0]).map(key => {
        const loadKey = loadKeys.find(_loadKey => _loadKey.parent == key)
        if (loadKey) {
          let _multilines = []
          data[0][key].map(line => {
            const _registerLine = { ..._register[key]?.find(_line => _line[loadKey.name] == line[loadKey.name]) }
            if (_registerLine && _registerLine[loadKey.name]) {
              Object.keys(line).map(col => { if (line[col]) { _registerLine[col] = line[col] } })
              _multilines.push(_registerLine)
            }
            else { _multilines.push(line) }
          })
          _register[key] = _multilines
        }
        else {
          _register[key] = data[0][key]
        }
      })
      this.ifIsNotTest(() => this.$store.commit('changeRegisterKeepOriginal', { ..._register }))
      if (this.loadConfig.execFuncName && typeof this.Form.logic[this.loadConfig.execFuncName] == 'function') { _register = this.Form.logic[this.loadConfig.execFuncName](this.FormLines, _register) }
      this.ifIsNotTest(() => this.$store.commit("hideOverlay"))
      return _register
    },
    getUpdateKeys() { return this.fromTo.targets.filter(target => target.type == 'updateKey').map(target => target.to.name) },
    checkImport() {
      if (this.Form.js_functions.findDuplicates(this.fromTo.targets.map(target => target.from)).length > 0) { return { status: false, alertType: 'warning', alertText: 'De-Para de campo duplicado!' } }
      return { status: true }
    },
    async saveRegistersOnDB() {
      return backend.post({
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        action: 'importfile',
        body: { updateKeys: this.getUpdateKeys(), registers: await this.fromToRegister() },
      }).then(res => {
        if (!res.ok || res.ok != 1) { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao adicionar os registros no Banco de Dados' } }
        const textAlert = `Arquivo importado com sucesso!\n - ${res.nInserted + res.nUpserted} registros adicionados\n - ${res.nModified} registros atualizados`
        return { status: true, alertType: 'success', alertText: textAlert }
      }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao adicionar os registros no Banco de Dados', error: e } })
    },
    async importRegisters() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkImport() })) { return this.$store.commit("hideOverlay") }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.saveRegistersOnDB() })) { return this.$store.commit("hideOverlay") }
      this.$store.commit("hideOverlay")
    },
    onlyUnique(value, index, self) { return self.indexOf(value) === index },
    filterRegisters() { 
      const filterTargets = this.fromTo.targets.filter(target => target.from && target.filter).filter(this.onlyUnique)
      const filterRegister = (register) => filterTargets
        .filter(target => !target.parent)
        .reduce((pass, target) => pass = pass ? (register[target.from] == target.filter) : false, true)
      const filteredRegisters = this.jsonRegisters.filter(register => filterRegister(register))
      const filterMultilinesLines = (register) => {
        let _register = register
        filterTargets
          .filter(target => target.parent)
          .map(target => _register[target.parent] = _register[target.parent]
            .filter(line => (line[target.from[0].replace(`${target.parent}>`, '')] != target.filter) ? false : true)
          )
        return _register
      }
      const filteredMultilinesRegisters = filteredRegisters.map(register => filterMultilinesLines(register))
      return filteredMultilinesRegisters
     },
    async fromToRegister() {
      const FormLines = this.FormLines
      const linkModules = this.getLinkModules()
      const linksFields = this.getLinkFields(linkModules)
      const linkData = await this.getLinkData(FormLines, linkModules, linksFields)
      const filterRegisters = this.filterRegisters()
      return filterRegisters.map(register => this.getFromTo(register, linkData)).filter(register => JSON.stringify(register) !== '{}')
    },
    getLinkModules() { return this.fromTo.targets.filter(target => target.module && target.fields).map(target => target.module).filter(this.onlyUnique) },
    getLinkFields(linkModules) {
      let _links = {}
      linkModules.map(module => {
        _links[module] = []
        const linkfields = this.fromTo.targets.filter(target => target.module == module).map(target => target.fields).filter(this.onlyUnique)
        linkfields.map(field => { _links[module].push(field) })
      })
      return _links
    },
    async getLinkData(FormLines, linkModules, linksFields) {
      let _linkData = {}
      await Promise.all(linkModules.map(async module => {
        const _this = {}
        await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: _this, name: 'linksData'}, func: () => this.getLinksData(FormLines, module, linksFields[module]) })
        Object.assign(_linkData, { [module]: _this.linksData })
        // Object.assign(_linkData, { [module]: await this.getLinksData(FormLines, module, linksFields[module]) })
      }))
      return _linkData
    },
    getLinkId(linkData, module, field, value) {
      const linkedRegister = linkData[module].find(values => values[field] == value)
      return linkedRegister ? linkedRegister._id : value
    },
    insertPair({ target, baseRegister, objToInsert, index, linkData }) {
      const getFromName = (from) => this.fileFields.find(option => option.option.value == from)?.option.name
      const existFrom = (from) => baseRegister[getFromName(from)] ? true : false
      const existFroms = () => target.from.reduce((exist, from) => exist = exist == false ? false : existFrom(from), true)
      if (target.to && target.from && existFroms()) {
        let _value = target.from.map(from => baseRegister[getFromName(from)])
        _value = (_value[0] && Array.isArray(_value[0])) ? _value[0] : _value.join('')
        !Array.isArray(target.type) || target.type.map(type => _value = (type != 'updateKey' && type != 'loadKey') ? convert[`${type}_trasformation`](_value) : _value)
        _value = (target.module && target.fields) ? this.getLinkId(linkData, target.module, target.fields, _value) : _value
        if (index != undefined && !objToInsert[index]) { objToInsert[index] = {} }
        Object.assign(index == undefined ? objToInsert : objToInsert[index], { [`${target.to.name}`]: _value })
      }
    },
    getFromTo(register, linkData) {
      let _register = {}
      this.fromTo.targets.map(target => {
        const type = this.typeOfFromTo(target, register)
        !type || this.executeFromTo(target, register, _register, linkData)[type]()
      })
      return _register
    },
    typeOfFromTo(target, register) {
      if (this.isFieldToField(target)) { return 'fieldToField' }
      if (this.isFieldToMultilines(target)) { return 'fieldToMultilines' }
      if (this.isMultilinesToMultilines(target, register)) { return 'multilinesToMultilines' }
      if (this.isMultilinesToField(target, register)) { return 'multilinesToField' }
    },
    thereIsToWithParent(target) { return (target.to && target.to != undefined && target.to.parent && target.to.parent != undefined) ? true : false },
    thereIsParentOfFrom(target) { return (target.parent && target.parent != undefined) ? true : false },
    isFieldToField(target) { return (!this.thereIsParentOfFrom(target) && !this.thereIsToWithParent(target)) ? true : false },
    isFieldToMultilines(target) { return (!this.thereIsParentOfFrom(target) && this.thereIsToWithParent(target)) ? true : false },
    isMultilinesToMultilines(target, register) { return (this.thereIsParentOfFrom(target) && this.thereIsToWithParent(target) && register[target.parent]) ? true : false },
    isMultilinesToField(target) { return (this.thereIsParentOfFrom(target) && !this.thereIsToWithParent(target)) ? true : false },
    executeFromTo(target, register, _register, linkData) {
      const getFromField = target.parent ? target.from[0].replace(`${target.parent}>`, '') : target.from
      const line = (target.line != undefined && target.line != '') ? target.line : 0
      return {
        fieldToField: () => this.insertPair({ target, baseRegister: register, objToInsert: _register, linkData }),
        fieldToMultilines: () => {
          if (!register[target.from]) { return }
          if (!_register[target.to.parent]) { _register[target.to.parent] = [] }
          this.insertPair({ target, baseRegister: register, objToInsert: _register[target.to.parent], index: line, linkData })
        },
        multilinesToMultilines: () => {
          if (!register[target.parent].find(line => line[getFromField])) { return }
          register[target.parent].map((item, index) => {
            if (!_register[target.to.parent]) { _register[target.to.parent] = [] }
            this.insertPair({ target, baseRegister: register[target.parent][index], objToInsert: _register[target.to.parent], index, linkData })
          })
        },
        multilinesToField: () => {
          if (!register[target.parent].find(line => line[getFromField])) { return }
          register[target.parent].map(() => {
            this.insertPair({ target, baseRegister: register[target.parent][0], objToInsert: _register, linkData })
          })
        }
      }
    },
    async getLinksData(FormLines, dataId, fields) {
      if (!fields) return ''
      const project = fields.reduce((obj, field) => Object.assign(obj, { [field]: 1 }), { _id: 1 })
      const isPublic = await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.isPublic(dataId) })
      return backend.post({
        company: isPublic ? 'public' : '',
        module: 'data',
        dataId,
        action: 'read/many',
        body: { project }
      }).then(registers => {
        if (!registers.length > 0) { return { status: false, alertType: 'warning', alertText: 'Não foi encontrado nenhum registro' } }
        return { status: true, data: registers }
      }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao carregar os registros', error: e } })
    },
    async isPublic(dataId) {
      return backend.post({
        module: 'controls',
        dataId: "_data",
        action: 'read/one',
        body: { query: { dataId }, project: { type: 1 } }
      }).then(register => {
        if (!register) { return { status: false } }
        return { status: register.type == 'public' ? true : false }
      }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao checar se os dados são públicos.', error: e } })
    },
    saveConfigFile() {
      const data = JSON.stringify({ loadConfig: this.loadConfig, fileFields: this.fileFields, formFields: this.formFields, fromTo: this.fromTo })
      downloads.downloadFile({ data, type: 'text/json', name: `import_${this.file.name}_to_${this.$route.query.menuId}.json` })
    },
    saveModel() {
      if (!this.model.modelName) { return this.$store.commit("showAlert", { type: 'warning', text: 'Preencha um nome para o modelo.', dismissible: true }) }
      const register = {
        name: this.model.modelName,
        dataId: this.$route.query.menuId,
        fileFields: this.fileFields,
        formFields: this.formFields,
        fromTo: this.fromTo,
        loadConfig: this.loadConfig
      }
      return backend.post({
        module: 'controls',
        dataId: '_import',
        body: register,
        early: true
      }).then(res => {
        if (res.status == 200) {
          this.$store.commit("hideOverlay")
          this.$store.commit("showAlert", { type: 'success', text: 'Modelo gravado com sucesso!', dismissible: true, time: 2000 })
          return true
        }
        else { throw { data: "Ocorreu um problema ao gravar." } }
      }).catch(e => {
        this.$store.commit("showError", e)
        return false
      })
    },
    checkConfigFileType(fileType) {
      if (fileType != 'json') { return { status: false, alertType: 'error', alertText: 'Tipo de arquivo não suportado.' } }
      return { status: true }
    },
    loadConfigsFromFile(file) {
      const onReaderLoad = async (event) => {
        this.$store.commit("showOverlay")
        const config = JSON.parse(event.target.result)
        this.loadConfig = config.loadConfig
        if (this.loadConfig.isOneRegister) { await this.unifyRegisters() }
        if (this.loadConfig.fileFields) { this.fileFields = config.fileFields }
        if (this.loadConfig.formFields) { this.formFields = config.formFields }
        if (this.loadConfig.targets) { this.fromTo.targets = config.fromTo.targets }
        this.$store.commit("hideOverlay")
      }
      let reader = new FileReader()
      reader.onload = onReaderLoad
      reader.readAsText(file)
      return { status: true, alertType: 'success', alertText: 'Configurações carregadas.' }
    },
    async loadConfigsFromDB(config) {
      this.$store.commit("showOverlay")
      this.loadConfig = config.loadConfig
      if (this.loadConfig.isOneRegister) { await this.unifyRegisters() }
      if (this.loadConfig.fileFields) { this.fileFields = config.fileFields }
      if (this.loadConfig.formFields) { this.formFields = config.formFields }
      if (this.loadConfig.targets) { this.fromTo.targets = config.fromTo.targets }
      this.$store.commit("hideOverlay")
      return { status: true, alertType: 'success', alertText: 'Modelo carregado.' }
    },
    async loadConfigData() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      const _this = {}
      if (this.model.modelId) {
         if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: _this, name: 'modelData'}, func: () => this.getConfigRegister() })) { return this.$store.commit("hideOverlay") }
         if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.loadConfigsFromDB(_this.modelData) })) { return this.$store.commit("hideOverlay") }
      }
      else {
        if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: _this, name: 'file'}, func: () => this.getFile('configFile') })) { return this.$store.commit("hideOverlay") }
        if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.loadConfigsFromFile(_this.file) })) { return this.$store.commit("hideOverlay") }
      }
      this.$store.commit("hideOverlay")
    },
    async getConfigRegister() {
      return backend.post({
        module: 'controls',
        dataId: '_import',
        action: 'read/one',
        body: { query: { _id: this.model.modelId } }
      }).then(register => {
        if (!register) { return { status: false, alertType: 'warning', alertText: 'Não foi encontrado nenhum registro' } }
        return { status: true, data: register }
      }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao carregar os registros', error: e } })
    }
  }
}
</script>

<style scoped>
  .Import {
    margin-top: 10px;
  }
  div.divLine {
    display: flex;
    margin-top: 10px;
    flex-direction: row-reverse;
  }
  div.divLine input#file, div.divLine input#configFile {
    width: 100%;
    margin-left: 6px;
  }
  div.divLine .buttom {
    margin-right: 6px;
  }
  div.divLine div .active { background: var(--active-background-color); }
</style>

<style>
  div.Import div.multiline.targets table * { font-size: 12px; }
</style>