"use strict"

export class map {
  #field
  #map
  #mapAux
  #poligonsSemInfo
  #poligonsComInfo
  #itemsOnMap
  // constructor({ field, div, map, options, poligonsSemInfo, poligonsComInfo }) {
  constructor({ field, div, options, poligonsSemInfo, poligonsComInfo }) {
    this.#field = field
    if (div) { this.#map = new window.google.maps.Map(div, options) }
    // if (map) { this.#map = map }
    this.#poligonsSemInfo = poligonsSemInfo
    this.#poligonsComInfo = poligonsComInfo
  }
  get map() { return this.#map }
  set map(value) { this.#map = value }
  get field() { return this.#field }
  // add = (objects) => objects.forEach(object => object.setMap(this.#map))
  // addAll = () => this.#poligonsComInfo.setMap(this.#map)
  setMapxAux(mapAux) { this.#mapAux = mapAux }
  openMapAux({ mainTitle, auxTitle }) {
    this.#field.aux.show = true
    this.#field.aux.mainTitle = mainTitle
    this.#field.aux.auxTitle = auxTitle
  }
  closeMapAux() {
    this.#field.aux.show = false
    this.#field.aux.mainTitle = ''
    this.#field.aux.auxTitle = ''
  }
  #getObjectType = ({ type, coords }) => {
    if (type) { return type }
    return coords?.length > 1 ? 'Polygon' : 'Marker'
  }
  insertObject = ({ type, coords, coordsWithHoles, options, infoRight, eventsRight, info, events, data }) => {
    console.log({ infoRight, eventsRight })
    if (type == 'Polyline' && coords?.[0] != coords?.[coords?.length - 1]) { coords?.push(coords?.[0]) }
    const paths = coords ? { path: coords } : { paths: coordsWithHoles }
    const objectType = this.#getObjectType({ type, coords })
    const objectsOptions = {
      Polygon: () => { return { position: coords ? coords?.[0] : coordsWithHoles?.[0]?.[0], ...paths, ...options({ type, coords, coordsWithHoles, options }) } },
      Marker: () => { return { position: coords?.[0], ...options({ type, coords, coordsWithHoles, options }) } },
      Circle: () => options({ type, coords, coordsWithHoles, options }),
      Rectangle: () => options({ type, coords, coordsWithHoles, options })
    }
    const insert = (_objectType) => {
      const object = new window.google.maps[_objectType](objectsOptions[_objectType]())
      object.setMap(this.#map)
      let builderRight = {  }
      // builderRight.info = infoRight ? infoRight : this.defaultInfoWindowRight.info
      // builderRight.events = eventsRight ? eventsRight : this.defaultInfoWindowRight.events
      if (info) { object.addListener('click', (event) => { this.mouseClick({ info, events, builderRight, event, object, button: 'left', data }) }) }
      // object.addListener('rightclick', (event) => { this.mouseClick(_builder, builderRight, event, object, 'right') })
      // if (_builder.Map.objectsName) { this.addToMapArray({ builder: _builder, array: 'objects', item: object }) }
      return object
    }
    // if (builder.extraMarker) {
    //   const extraMarkerBuilder = { ...builder }
    //   extraMarkerBuilder.name = `${builder.name}Marker`
    //   extraMarkerBuilder.label = `${builder.label} (Marker)`
    //   insert(extraMarkerBuilder, 'Marker')
    // }
    return insert(objectType)
  }
  insertAll = () => this.#itemsOnMap = this.#poligonsComInfo.map(_polygon => this.insertObject(_polygon))
  saveOnField = (field, items) => field = items ? items : this.#itemsOnMap
  sync = (map) => {
    const maps = [map, this.#map]
    let center = map.getCenter()
    let zoom = map.getZoom()
    this.#map.setCenter(center)
    this.#map.setZoom(zoom)
    const update = (changedMap) => {
      maps.forEach(m => {
        if (m === changedMap) { return }
        m.setCenter(center)
        m.setZoom(zoom)
      })
    }
    maps.forEach(m => {
      m.addListener("bounds_changed", () => {
        const changedCenter = m.getCenter()
        const changedZoom = m.getZoom()
        if (changedCenter !== center || changedZoom !== zoom) {
          center = changedCenter
          zoom = changedZoom
          update(m)
        }
      })
    })
  }
  mouseClick = function({ info, events, builderRight, event, object, button, data }) {
    console.log({ builderRight, button })
    // const _button = button == 'left' ? 'right' : 'left'
    // button = (builderRight.Map.$refs.ToolBar && builderRight.Map.$refs.ToolBar.mouseReverse.active) ? _button : button
    // if (builder.Map?.field?.clickRightObject && builder.Map?.field?.mouseReverse) { button = builder.Map?.field?.mouseReverse ? _button : button }
    // if (button == 'right') { this.rightClick(builderRight, event, object) }
    // else { this.openInfoWindow({ info, events, event, object }) }
    this.openInfoWindow({ info, events, event, object, data })
  }
  openInfoWindow = async function({ info, events, event, object, data }) {
    let infoWindow = new window.google.maps.InfoWindow()
    infoWindow.setContent(await info(object))
    !events || infoWindow.addListener("domready", () => {
      events.map(listener => {
        if (document.querySelector(listener.obj)) {
          const tags = document.querySelectorAll(listener.obj)
          tags.forEach((tag) => { tag[listener.event] = () => { listener.action({ infoWindow, object, tag, data }) } })
        }
      })
    })
    infoWindow.setPosition(event.latLng)
    infoWindow.open(this.#map)
    return infoWindow
  }
  async setMapCenter({ address, lat, lng, zoom, insertMarker, buttonInsertTerreno, infowindowTitle }) {
    let location
    if (address) {
      await new window.google.maps.Geocoder().geocode({ address }, (results, status) => {
        if (status == 'ZERO_RESULTS') { return window.$showAlert({ type: 'error', text: 'Nenhum endereço encontrado!' }) }
        if (status !== 'OK' || !results[0]) { throw new Error(status) }
        if (insertMarker == true && results[0].partial_match == true) { window.$showAlert({ type: 'warning', text: 'A busca não encontrou uma correspondencia exata! \nO PIN foi adicionado em um local aproximado.', dismissible: true }) }
        this.#map.setCenter(results[0].geometry.location)
        location = results[0].geometry.location
      })
    }
    if (lat && lng) {
      this.#map.setCenter(new window.google.maps.LatLng(lat, lng))
      location = new window.google.maps.LatLng(lat, lng)
    }
    const getMarkerType = () => {
      if (address) { return 'address' }
      if (lat && lng && infowindowTitle) { return 'customDefault' }
      return 'default'
    }
    const markerInfoWindowConfig = (markerType) => {
      return {
        address: `<h3 class="infowindowTitle">Endereço ou CEP</h3><h3>${address}</h3>`,
        customDefault: `<h3 class="infowindowTitle">${infowindowTitle}</h3><div class="infoBlock"><h4>Coordenadas</h4><ul><li><label>Latitude:</label> ${lat}</li><li><label>Longitude:</label> ${lng}</li></ul></div>`,
        default: `<div class="infoBlock"><h4>Coordenadas</h4><ul><li><label>Latitude:</label> ${lat}</li><li><label>Longitude:</label> ${lng}</li></ul></div>`
      }[markerType]
    }
    if (insertMarker) {
      this.insertObject({
        type: 'Marker',
        coords: [{ lat: location.lat(), lng: location.lng() }],
        options: (builder) => !builder || {},
        info: (builder) => {
          if (!builder) { return true }
          const button = buttonInsertTerreno ? '<button id="infoWindow_cadastrarNoMarker">+ Terreno</button>' : ''
          return `${markerInfoWindowConfig(getMarkerType())}<div class="infoButtons">${button}</div>`
        },
        events: [
          {
            obj: '#infoWindow_cadastrarNoMarker',
            event: 'onclick',
            action: async (params) => {
              params.infoWindow.close()
              const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: 'terrenos' })
              const register = { poligono: [[params.builder.coords[0]]] }
              params.builder.Map.$store.commit("showOverlay")
              params.builder.Map.$store.commit('changeKeepRegister', true)
              params.builder.Map.$store.commit('changeRegister', register)
              params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 0 } })
                .then(()=>{  params.builder.Map.$store.commit("hideOverlay") })
                .catch(()=>{  params.builder.Map.$store.commit("hideOverlay") })
            }
          }
        ]
      })
    }
    this.#map.setZoom(zoom)
  }
}