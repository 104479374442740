"use strict"

export class Page {
  #store
  constructor({ global, store }) {
    this.#store = store
    Object.getOwnPropertyNames(Page.prototype).map(key => { global[key] = (params) => this[key](params) })
    Object.defineProperty(global, 'user', {
      get () { return store.getters.user() },
      set () { }
    })
  }
  get $store() { return this.#store }
  #openAlert = ({ type, text, dismissible, time }) => this.#store.commit("showAlert", { type, text, dismissible, time })
  showSuccess({ text, dismissible, time }) { this.#openAlert({ type: 'success', text, dismissible, time }) }
  showInfo({ text, dismissible, time }) { this.#openAlert({ type: 'info', text, dismissible, time }) }
  showWarning({ text, dismissible, time }) { this.#openAlert({ type: 'warning', text, dismissible, time }) }
  showError({ text, dismissible, time }) { this.#openAlert({ type: 'error', text, dismissible, time }) }
  openDialog({ dialog }) { this.#store.commit('showDialog', { ...dialog, show: true }) }
  closeDialog() { this.#store.commit("hideDialog") }
  showOverlay() { this.#store.commit("showOverlay") }
  hideOverlay() { this.#store.commit("hideOverlay") }
}