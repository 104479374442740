"use strict"

import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class vtos {
  #LDVM
  #lastDataId
  constructor() {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'vto' })
    this.#lastDataId = this.#LDVM?.getLastDataId()
  }
  get lastDataId() { return this.#lastDataId }
  setLinkDataVersion = ({ register }) =>
    this.#LDVM.setLinkDataVersion({
      register,
      versionDataId: this.#lastDataId //checar se os valores de vtos do registro realmente estão atualizados.
    })
  calculeMaxVTO = function({ fields, lotes }) {
    const field = jsf.getJSONObject({ fields, name: 'lotes' })
    field.footers[3].text = jsf.numberOut(this.getMaxVTO(lotes), 2)
  }
  getMaxVTO = (lotes) => jsf.operationArrayProp(lotes, 'max', 'valorOutorga')
  // getMaxVTO = ({ lotes }) => jsf.numberOut(jsf.operationArrayProp(lotes, 'max', 'valorOutorga'), 2)
}