<template>
  <v-tooltip v-if="typeof value == 'string' && (value.indexOf('http://') &gt; -1 || value.indexOf('https://') &gt; -1)" top>
    <template v-slot:activator="{ on, attrs }">
      <a :href="getLink()" target="_blank">
        <v-icon medium v-bind="attrs" v-on="on" class="tooltip">mdi-arrow-up-right</v-icon>
      </a>
    </template>
    <span>Ir para {{ getLink() }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AppendIconLink",
  props: ['value'],
  methods: {
    getLink() {
      const link = this.value.substringBetween('http', ' ', true, true)
      return link != '' ? link : this.value.substringBetween('http', '', true, true)
    }
  }
}
</script>

<style>
</style>