<template>
  <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top v-if="(field.type === 'text' || field.type === 'password') && (field.show == undefined || field.show)">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs" v-on="on"
        v-if="(field.type === 'text' || field.type === 'password') && (field.show == undefined || field.show)"
        :key="field.name"
        :name="field.name"
        :ref="field.name"
        :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
        v-model="vmodel[field.name]"
        :type="field.type"
        :solo="field.solo"
        :outlined="!field.solo"
        autocomplete="new-password"
        autocorrect="off"
        autocapitalize="off"
        dense
        hide-details
        :prepend-inner-icon="field.prependInnerIcon"
        :suffix="field.suffix"
        :disabled="field.disabled"
        :readonly="field.readonly || blockField(field.name, multiline) || blockLine()"
        v-mask-decimal.br="field.mask == 'decimal' ? field.maskOptions.decimals : null"
        v-mask-phone.br="field.mask == 'phone' ? 0 : null"
        v-mask="FormLines.mask(field)"
        :counter="field.maxlength"
        :rules="FormLines.rules(field)"
        :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
        :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
        :background-color="field.backgroundcolor"
        @keydown="onKeyDown"
        v-on:keyup="event(field.keyup)"
        @click="event(field.click)"
        @change="event(field.change)"
        :color="FormLines.styles['--over-color']"
        :autofocus="field.focus"
      >
        <template slot="append">
          <v-icon v-for="(icon, index) in FormLines.showIcon(field, line)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
          <AppendIconTooltip :field="field" />
          <AppendIconLink :value="vmodel[field.name]" />
          <template v-for="action in field.actions">
            <v-icon v-if="action.type == 'icon' && !action.outer" :key="action.text" @click="event(action.method)" class="hover">{{ action.text }}</v-icon>
            <v-btn
              v-if="action.type == 'button' && !action.outer"
              :style="action.enabled ? 'pointer-events: auto;' : ''"
              :key="action.text"
              @click="event(action.method)"
              outlined
              small
              class="inLineButton"
              :loading="action.loading"
              :color="FormLines.styles['--over-color']"
            >{{ action.text }}</v-btn>
          </template>
        </template>
        <template slot="append-outer">
          <template v-for="action in field.actions">
            <v-icon v-if="action.type == 'icon' && action.outer" :key="action.text" @click="event(action.method)" class="hover">{{ action.text }}</v-icon>
            <v-btn
              v-if="action.type == 'button' && action.outer"
              :style="action.enabled ? 'pointer-events: auto;' : ''"
              :key="action.text"
              @click="event(action.method)"
              outlined
              class="inLineButton outer"
              :loading="action.loading"
              :color="FormLines.styles['--over-color']"
            >{{ action.text }}</v-btn>
          </template>
        </template>
      </v-text-field>
    </template>
    <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import AppendIconLink from './pieces/AppendIconLink'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldText",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { AppendIconLink, AppendIconTooltip },
  computed: { ...mapState(['register']) },
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], vmodel: this.vmodel, lineNumber: this.line }) } },
    onKeyDown(evt) {
      !this.field.keydown || this.event(this.field.keydown)
      const keysAllowed = [8, 9, 46, 37, 39]
      const _vmodel = this.vmodel ? this.vmodel[this.field.name] : this.register[this.field.name]
      if (!keysAllowed.includes(evt.keyCode) && _vmodel && _vmodel.length >= this.field.maxlength) {
        evt.preventDefault()
        return
      }
    },
    defaultValue() { this.vmodel[this.field.name] = (!this.field.defaultvalue || this.vmodel[this.field.name]) ? this.vmodel[this.field.name] : this.field.defaultvalue },
    fixDecimals() {
      if (this.field.mask == undefined) { return }
      if (this.field.mask != 'decimal') { return }
      if (this.field.maskOptions == undefined) { return }
      if (this.field.maskOptions.decimals == undefined) { return }
      let value = this.vmodel[this.field.name]
      if (value == undefined || value == '') { return }
      const numberDecimals = value.indexOf(',') > 0 ? value.split(',')[1].length : 0
      value = numberDecimals == 0 ? `${value},` : value
      const maskDecimals = this.field.maskOptions.decimals
      if (numberDecimals < maskDecimals) {
        for (let d = numberDecimals; d < maskDecimals; d++) { value += '0' }
      }
      if (numberDecimals > maskDecimals) {
        value = this.FormLines.Form.js_functions.numberOut(this.FormLines.Form.js_functions.numberIn(value), maskDecimals)
      }
      this.vmodel[this.field.name] = value
    }
  },
  created() {
    this.defaultValue()
    this.fixDecimals()
  },
  mounted() {
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  .inLineButton.outer {
    margin-top: -6px;
  }
</style>