<template>
  <v-dialog v-model="vmodel[action]" persistent max-width="520">
      <v-card @keydown.enter="click()">
        <v-card-title class="headline"><v-icon medium>{{ icon }}</v-icon> {{ headline }}</v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" @click="vmodel[action] = false">Cancelar</v-btn>
          <v-btn @click="click()" color="success">{{ command }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "ToolBarWindow",
  props: ['icon', 'headline', 'vmodel', 'action', 'click', 'command']
}
</script>

<style scoped>
  .headline { color: #666666; }
  .headline .v-icon { margin-right: 10px; }
</style>