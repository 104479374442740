<template>
  <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top v-if="field.type === 'linkSimple' && field.show == undefined || field.show">
    <template v-slot:activator="{ on, attrs }">
      <component :is="tag"
        v-bind="attrs" v-on="on"
        v-if="field.type === 'linkSimple' && field.show == undefined || field.show"
        :key="field.name"
        :name="field.name"
        :ref="field.name"
        :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
        v-model="vmodel[field.name]"
        :items="items"
        item-text="option.text"
        item-value="option.value"
        outlined
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        dense
        hide-details
        :multiple="field.multiple"
        :no-data-text="`Nenhum(a) ${field.label || 'dado'} encontrado(a)`"
        :disabled="field.disabled"
        :readonly="field.readonly || blockField(field.name, multiline) || blockLine()"
        :rules="FormLines.rules(field)"
        class="link"
        :class="{ 'disabled': field.disabled || field.readonly  || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
        :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
        :background-color="field.backgroundcolor"
        @click="event(field.click)"
        @change="getRegisterLink(); event(field.change)"
        :loading="loading"
        :color="FormLines.styles['--over-color']"
      >
        <template v-if="field.multiple" v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.option.text }}</span>
          <v-chip v-if="item && index === 1">
            <span>+{{ vmodel[field.name].length - 1 }}</span>
          </v-chip>
        </template>
        <template slot="append">
          <v-icon v-if="field.openLinkInSameWindow && field.dataId && (field.linkIcon == undefined || field.linkIcon)" @click="showRegisterLink()" @contextmenu.prevent="load({ updateCache: true }); return false">{{ registerLinkNew ? 'mdi-plus' : 'mdi-link' }}</v-icon>
          <a v-if="!field.openLinkInSameWindow && field.dataId && (field.linkIcon == undefined || field.linkIcon)" :href="registerLink" target="_blank"><v-icon @contextmenu.prevent="load({ updateCache: true }); return false">{{ registerLinkNew ? 'mdi-plus' : 'mdi-link' }}</v-icon></a>
          <v-icon v-for="(icon, index) in FormLines.showIcon(field)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
          <AppendIconTooltip :field="field" />
          <AppendIconLink :value="vmodel[field.name]" />
          <v-icon>mdi-menu-down</v-icon>
        </template>
        <template slot="append-outer">
          <template v-for="action in field.actions">
            <v-btn
              v-if="action.type == 'button'"
              :key="action.text"
              @click="event(action.method)"
              outlined
              class="inLineButton"
              :loading="action.loading"
              :color="FormLines.styles['--over-color']"
            >{{ action.text }} </v-btn>
          </template>
        </template>
      </component>
    </template>
    <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { VSelect, VAutocomplete } from 'vuetify/lib'
import * as get_options from '@/lib/get_options'
import * as js_functions from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
import { mapState } from 'vuex'
import AppendIconLink from './pieces/AppendIconLink'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldLinkSimple",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line', 'index'],
  components: { VSelect, VAutocomplete, AppendIconLink, AppendIconTooltip },
  computed: {
    ...mapState(['user', 'register', 'dataItens']),
    tag() { return (this.field.autocomplete || this.field.autocomplete == undefined) ? 'VAutocomplete' : 'VSelect' }
  },
  data: () => ({
    items: [],
    loading: 'false',
    registerLink: '',
    registerLinkNew: true
  }),
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    getLinkDescription() { return js_functions.getOptionTextByValue(this.items, this.vmodel[this.field.name]) },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], text: this.getLinkDescription(), vmodel: this.vmodel, lineNumber: this.line }) } },
    load({ updateCache }) {
      this.parent = this._parent()
      if (this.field.watchField && !this.vmodel[this.field.watchField]) { return }
      this.loading = this.FormLines.styles["--over-color"]
      get_options.configAndGetOptions(this, {
        ...this.field,
        updateCache,
        cache: (this.multiline != undefined),
        cacheName: this.field.cacheName ?? this.field.dataId,
        multilines: (this.multiline != undefined) ? this.parent : null,
        query: { [this.field.baseField]: this.vmodel[this.field.watchField] }
      }).then(() => {
        if (Array.isArray(this.items)) { js_functions.addLinkDescription(this.vmodel, this.field.name, this.items) }
        if (Array.isArray(this.items) && this.items[0] && this.field.blanckText) { this.items[0].option.text = this.field.blanckText }
        if (Array.isArray(this.items) && this.FormLines.Form) {
          this.FormLines.Form.links.data[`${this.multiline ? this.multiline + '/' : ''}${this.field.name}`] = this.items
          if (this.field.await) { this.FormLines.Form.links.loaded += 1 }
        }
        this.getRegisterLink()
        this.loading = 'false'
      }).catch(e => {
        this.loading = 'false'
        // mostrar de alguma forma no campo (sem v-model) que os itens não foram carregados
        this.FormLines.$store.commit("showError", e)
      })
    },
    getLink(register, linkFieldsNames) {
      const _linkFieldsNames = (linkFieldsNames.constructor === Array) ? linkFieldsNames : [linkFieldsNames]
      return _linkFieldsNames.reduce((text, fieldName) => text += register[fieldName] ? `${register[fieldName]} - ` : '', '').slice(0, -3)
    },
    async showRegisterLink() {
      const menu = this.dataItens.find(_dataItem => _dataItem.dataId == this.field.dataId)
      const query = {
        hmenuOpened: 'Dados',
        menuId: menu.dataId,
        menuOpened: menu.title,
        tabNumber: !this.vmodel[this.field.name] ? 0 : 2,
        id: !this.vmodel[this.field.name] ? '' : this.vmodel[this.field.name]
      }
      this.FormLines.$router.push({ name: 'data', query })
        .then(()=>{})
        .catch(()=>{})
    },
    async getRegisterLink() {
      const menu = this.dataItens.find(_dataItem => _dataItem.dataId == this.field.dataId)
      if (!menu) return
      this.registerLinkNew = !this.vmodel[this.field.name] ? true : false
      this.registerLink = `${window.location.protocol}//${window.location.host}/data?hmenuOpened=Dados&menuId=${menu.dataId}&menuOpened=${menu.title}&tabNumber=${this.registerLinkNew ? 0 : 2}${!this.registerLinkNew ? '&id=' + this.vmodel[this.field.name] : ''}`
    },
    async setDataId() {
      const company = this.field.company || this.user.company
      const newLinkDataVersionsManager = new linkDataVersionsManager({ company, dataVersionName: this.field.dataVersionName })
      this.field.dataId = await newLinkDataVersionsManager.getCurrentDataId({ register: this.register }) || this.field.dataId
      newLinkDataVersionsManager.setLinkDataVersion({ register: this.register, versionDataId: this.field.dataId })
    }
  },
  async mounted() {
    await this.setDataId()
    this.$watch(`vmodel.${this.field.name}`, () => { js_functions.addLinkDescription(this.vmodel, this.field.name, this.items) })
    this.$watch(`vmodel.${this.field.watchField}`, () => { this.load({}) })
    this.load({})
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .v-btn.inLineButton { margin-top: -6px; }
</style>