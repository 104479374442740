<template>
  <v-form id="form" ref="form" v-if="load" :class="{ [getFolder()]: getFolder(), [$route.query.menuId]: $route.query.menuId }">
    <Buttons :mode="mode" :actions="fields?.[0]?.actions" :logic="logic" :Form="mySelf()"/>
    <FormLines ref="FormLines" :key="key" :mode="mode" :fields="fields" :logic="logic" :Form="mySelf()"/>
    <Buttons :mode="mode" :actions="fields?.[0]?.actions" :logic="logic" :Form="mySelf()"/>
  </v-form>
  <div id="divLoading" v-else>
    <v-skeleton-loader type="actions, article" width="100%"></v-skeleton-loader>
    <v-skeleton-loader type="article" width="100%"></v-skeleton-loader>
    <v-skeleton-loader type="article, actions" width="100%"></v-skeleton-loader>
  </div>
</template>

<script>
import * as backend from '@/lib/backend'
import * as storage from '@/lib/storage'
import * as files from '@/lib/files'
import * as get_info from '@/lib/get_info'
import * as js_functions from '@/lib/js_functions'
import { chart_functions } from '@/lib/chart/chart_functions'
import { map_functions } from '@/lib/map_functions'
import FormLines from './FormLines'
import Buttons from './Buttons'
import { mapState } from 'vuex'
export default {
  name: "Form",
  components: { FormLines, Buttons },
  computed: { ...mapState(['user', 'register', 'keepRegister', 'menus']) },
  props: ['mode'],
  data: () => ({
    key: 0,
    load: false,
    fields: [],
    links: {
      total: 0,
      loaded: 0,
      data: {}
    },
    maps: {},
    logic: null,
    backend: null,
    storage: null,
    files: null,
    get_info: null,
    js_functions: null,
    chart_functions: null,
    map_functions: null
  }),
  watch: {
    'links.loaded': function() { this.runLogic() }
  },
  methods: {
    mySelf() { return this },
    // Info
    getFolder() { return this.$route.query.folder ? this.$route.query.folder : this.$route.query.menuId },
    getInfo() {
      this.company = (this.$route.name == 'controls') ? 'default' : this.user.company
      this.folder = this.getFolder()
      this.dataId = this.$route.query.menuId
      this.dataIdPath = (this.$route.name == 'reports') ? `reports/${this.dataId}` : this.dataId
      this.folderPath = (this.$route.name == 'reports') ? `reports/${this.folder}` : this.folder
    },
    // Fields
    async getFields() {
      if (this.folder != '' && this.folder != undefined) {
        this.fields = await files.get(`${this.company}/${this.dataIdPath}/fields.json`)
        if (!Array.isArray(this.fields)) { this.fields = await files.get(`${this.company}/${this.folderPath}/fields.json`) }
        if (!Array.isArray(this.fields)) {
          this.fields = await files.get(`default/${this.folderPath}/fields.json`)
          if (Array.isArray(this.fields)) { this.company = 'default' }
        }
        if (!Array.isArray(this.fields)) { return this.$store.commit("showError", { data: 'Não foi possível carregar.\nO arquivo de configurações não foi encontrado.' }) }
        if (typeof this.setFields === 'function') { this.setFields({ value: this.fields }) }
      }
      return
    },
    // Logic
    async runLogic() {
      if (this.links.loaded != this.links.total) { return }
      if (this.logic && typeof this.logic.load === 'function') {
        await this.$refs.FormLines
        const _FormLines = this.$refs.FormLines
        this.logic.load(_FormLines)
      }
      if (this.logic && typeof this.logic.loadForm === 'function') {
        await this.logic.loadForm({ register: this.register, fields: this.fields })
      }
      this.$store.commit("hideOverlay")
      return
    },
    async loadLogicAndRun(logic, notRun) {
      this.logic = logic
      if (this.logic && typeof this.logic.configForm === 'function') {
        await this.logic.configForm({ component: this, register: this.register, fields: this.fields })
      }
      if (!notRun) await this.runLogic()
      return
    },
    async loadScript(path, dataIdOrFolder, notRun) {
      const logicObject = await files.getJSNewObject({ path:`${this.company}/${path}/logic.js`, newParams: { } })
      if (logicObject) { return await this.loadLogicAndRun(logicObject, notRun) }
      return files.loadScript(`${this.company}/${path}/logic.js`)
        .then(async () => { await this.loadLogicAndRun(window[`${this.$route.name}_${dataIdOrFolder}`], notRun) })
        .catch(async () => { await this.loadLogicAndRun({ load: () => {} }, notRun) })
    },
    async loadLogic(notRun) {
      const logicFileExists = async (path) => !(await files.get(`${this.company}/${path}/logic.js`)).status
      if (this.logic != null && !notRun) { this.runLogic() }
      if (window[`${this.$route.name}_${this.dataId}`]) { await this.loadLogicAndRun(window[`${this.$route.name}_${this.dataId}`], notRun) }
      if (this.logic == null && await logicFileExists(this.dataIdPath)) { await this.loadScript(this.dataIdPath, this.dataId, notRun) }
      if (this.logic == null && window[`${this.$route.name}_${this.folder}`]) { await this.loadLogicAndRun(window[`${this.$route.name}_${this.folder}`], notRun) }
      if (this.logic == null && await logicFileExists(this.folderPath)) { await this.loadScript(this.folderPath, this.folder, notRun) }
      if (this.logic == null) { await this.loadLogicAndRun({ load: () => {} }, notRun) }
      return
    },
    // Style
    async loadStyle() {
      const styleFile = await files.get(`${this.company}/${this.dataIdPath}/style.css`)
      styleFile.status && styleFile.status == 404 ? files.loadCss({ company: this.company, type: this.folderPath, name: 'style' }) : files.loadCss({ company: this.company, type: this.dataIdPath, name: 'style' })
      return
    },
    // Register
    async getRegister() {
      this.$store.commit("showOverlays", 2)
      if (typeof this.$route.query.id !== "string" || this.$route.query.id.length != 24) {
        this.$store.commit("showError", { data: "Existe algum problema com os parâmetros da URL.", dismissible: true })
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber: 1, id: '' } })
          .then(()=>{})
          .catch(()=>{})
        this.$store.commit("hideOverlays", 2)
        return false
      }
      return await backend.post({
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        action: 'read/one',
        body: { "query": { _id: this.$route.query.id } },
        early: true
      }).then(async res => {
        this.$store.commit("hideOverlays", 2)
        return res.data
      }).catch(e => {
        this.$store.commit("showError", e)
        this.$store.commit("hideOverlays", 2)
        return false
      })
    },
    async loadRegister() {
      if (!this.fields.length) { await this.getFields() }
      const register = (this.$route.query.id && !this.keepRegister) ? await this.getRegister() : this.register
      if (!register) return false
      const { registerEmpty, fixedRegister, linksTotal } = files.fixRegister(register, this.fields)
      this.links.total = linksTotal
      this.$store.commit('changeRegisterEmpty', registerEmpty)
      this.$store.commit('changeRegister', fixedRegister)
      this.$store.commit('changeKeepRegister', false)
      this.resetValidation()
      return true
    },
    // Form
    async loadForm() {
      this.$store.commit("showOverlay")
      this.links.loaded = 0
      await this.loadLogic(true)
      await this.getFields()
      if (this.folder != '' && this.folder != undefined) {
        try {
          this.logic = null
          if (await this.loadRegister()) {
            this.load = true
            await this.loadLogic()
            await this.loadStyle()
          }
          this.$store.commit("hideOverlay")
        } catch(e) {
          console.error(e)
          this.$store.commit("hideOverlay")
        }
      }
      else { this.$store.commit("hideOverlay") }
      return
    },
    // Validation
    validate() {
      let maps = ''
      const isPoligonEmpty = (fieldName) => !this.register[fieldName] || !Array.isArray(this.register[fieldName]) || this.register[fieldName].length == 0
      this.fields.map(line => {
        if (line.fields) { maps += line.fields.reduce((alert, field) => alert += field.type == 'map' && field.required == true && isPoligonEmpty(field.name) ? `${field.label}, ` : '', '') }
      })
      if (!this.$refs.form.validate() || maps != '') {
        this.$store.commit("showAlert", { type: 'error', text: `Os campos com ( ! ) ${maps ? 'e os campos: ' + maps : ''}são obrigatórios!`, dismissible: true, time: 5000 })
        return false
      }
      return true
    },
    resetValidation() { return !this.$refs.form || this.$refs.form.resetValidation() },
    async checkOnlyone() {
      const _module = this.$route.name == 'controls' ? 'controls' : 'data'
      for (let lineNumber = 0; lineNumber < this.fields.length; lineNumber++) {
        if (this.fields[lineNumber].fields && Array.isArray(this.fields[lineNumber].fields)) {
          for (let fieldNumber = 0; fieldNumber < this.fields[lineNumber].fields.length; fieldNumber++) {
            let field = this.fields[lineNumber].fields[fieldNumber]
            if (field.type != 'multilines' && field.type != 'link') {
              if (field.onlyone == true) {
                const response = await backend.post({
                  module: _module,
                  dataId: this.$route.query.menuId,
                  action: 'read/onlyone',
                  body: { "query": { [field.name]: this.register[field.name] } }
                })
                if (response._id && response._id != this.register._id) {
                  field.onlyoneError = true
                  this.$store.commit("showAlert", { type: 'error', text: `Esse(a) ${field.label} já existe!`, dismissible: true, time: 5000 })
                  return false
                }
                field.onlyoneError = false
              }
            }
            else if (field.type == 'link') {
              for (let linkFieldNumber = 0; linkFieldNumber < field.fields.length; linkFieldNumber++) {
                let linkField = field.fields[linkFieldNumber]
                if (linkField.onlyone == true) {
                  const response = await backend.post({
                    module: _module,
                    dataId: this.$route.query.menuId,
                    action: 'read/onlyone',
                    body: { "query": { [linkField.name]: this.register[linkField.name] } }
                  })
                  if (response._id && response._id != this.register._id) {
                    linkField.onlyoneError = true
                    this.$store.commit("showAlert", { type: 'error', text: `Esse(a) ${linkField.label} já existe!`, dismissible: true, time: 5000 })
                    return false
                  }
                  linkField.onlyoneError = false
                }
              }
            }
            else {
              for (let multilineFieldNumber = 0; multilineFieldNumber < field.fields.length; multilineFieldNumber++) {
                let multilineField = field.fields[multilineFieldNumber]
                if (multilineField.onlyone == true) {
                  if (!this.checkMultilinesColumnOnlyone(field, multilineField)) { return false }
                  if (!this.register[field.name]) { return true }
                  for (let multilineLineNumber = 0; multilineLineNumber < this.register[field.name].length; multilineLineNumber++) {
                    const response = await backend.post({
                      module: _module,
                      dataId: this.$route.query.menuId,
                      action: 'read/onlyone',
                      body: { query: { [`${field.name}.${multilineField.name}`]: this.register[field.name][multilineLineNumber][multilineField.name] } }
                    })
                    if (response._id && response._id != this.register._id) {
                      multilineField.onlyoneError.includes(multilineLineNumber) || multilineField.onlyoneError.push(multilineLineNumber)
                      const alertMessage = `Esse(a) ${multilineField.label} na linha ${multilineLineNumber + 1} dos(as) ${field.label} já existe!`
                      this.$store.commit("showAlert", { type: 'error', text: alertMessage, dismissible: true, time: 5000 })
                      return false
                    }
                    multilineField.onlyoneError = multilineField.onlyoneError.filter(line => line != multilineLineNumber)
                  }
                }
                if (multilineField.onlyonetable == true) {
                  if (!this.checkMultilinesColumnOnlyone(field, multilineField)) { return false }
                }
              }
            }
          }
        }
      }
      return true
    },
    checkMultilinesColumnOnlyone(field, multilineField) {
      let list = []
      const label = (multilineField.type == 'link') ? multilineField.fields[0].label : multilineField.label
      if (!this.register[field.name]) { return true }
      for (let multilineLineNumber = 0; multilineLineNumber < this.register[field.name].length; multilineLineNumber++) {
        const columnValue = this.register[field.name][multilineLineNumber][multilineField.name]
        if (list.includes(columnValue)) {
          if (Array.isArray(multilineField.onlyoneError)) { multilineField.onlyoneError.includes(multilineLineNumber) || multilineField.onlyoneError.push(multilineLineNumber) }
          const alertMessage = `Esse(a) ${label} na linha ${multilineLineNumber + 1} do(a)(s) ${field.label} já existe!`
          this.$store.commit("showAlert", { type: 'error', text: alertMessage, dismissible: true, time: 5000 })
          return false
        }
        list.push(columnValue)
        if (Array.isArray(multilineField.onlyoneError)) { multilineField.onlyoneError = multilineField.onlyoneError.filter(line => line != multilineLineNumber) }
      }
      return true
    },
    async checkBeforeAction(action) { return (this.logic && typeof this.logic[action] === 'function' && !await this.logic[action](this.$refs.FormLines)) ? false : true },
    resetNewRegister(query) {
      this.getInfo()
      if (query.tabNumber == 0  || this.$route.name == 'reports') {
        this.keepRegister || this.$store.commit('clearRegister')
        this.resetValidation()
        this.key++
      }
    }
  },
  mounted() {
    this.backend = backend
    this.storage = storage
    this.files = files
    this.get_info = get_info
    this.js_functions = js_functions
    this.chart_functions = new chart_functions,
    this.map_functions = new map_functions
  },
  // created() {
  //   this.$watch(
  //     () => this.$route.query,
  //     async (query, oldQuery) => {
  //       this.getInfo()
  //       if (query.tabNumber == 0  || this.$route.name == 'reports') {
  //         this.keepRegister || this.$store.commit('clearRegister')
  //         this.resetValidation()
  //         this.key++
  //       }
  //       if (query.menuId && (!oldQuery || query.menuId != oldQuery?.menuId || query.tabNumber != oldQuery?.tabNumber)) { await this.loadForm() }
  //     },
  //     { immediate: true }
  //   )
  // }
  async created() {
    this.$watch(
      () => this.$route.query,
      async (query, oldQuery) => {
        if (query.tabNumber != 2) {
          this.resetNewRegister(query)
          if (query.menuId && (!oldQuery || query.menuId != oldQuery?.menuId || query.tabNumber != oldQuery?.tabNumber)) { await this.loadForm() }
        }
      },
      { immediate: true }
    )
    if (this.$route.query.tabNumber == 2) {
      this.resetNewRegister(this.$route.query)
      await this.loadForm()
    }
  }
  // async destroyed() { await files.unloadScript(`${this.user.company}/${this.getFolder()}/logic.js`) }
}
</script>

<style>
  div#divLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>