<template>
  <div
    v-if="field.type === 'html' && (field.show == undefined || field.show)"
    :class="field.class"
    v-html="JSON.parse(JSON.stringify(field.html))"
  ></div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "FieldHTML",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  computed: { ...mapState(['register']) }
}
</script>

<style scoped>
</style>