// import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// export function numberMask(maskOptions) {
//   const customOptions = maskOptions.reduce((obj, item) => Object.assign(obj, item), {})
//   console.log(customOptions)
//   const currencyMask = createNumberMask({
//     prefix: '',
//     includeThousandsSeparator: getMaksOption(customOptions, 'includeThousandsSeparator', true),
//     thousandsSeparatorSymbol: getMaksOption(customOptions, 'thousandsSeparatorSymbol', '.'),
//     allowDecimal: getMaksOption(customOptions, 'allowDecimal', true),
//     decimalSymbol: getMaksOption(customOptions, 'decimalSymbol', ','),
//     decimalLimit: getMaksOption(customOptions, 'decimalLimit', 2),
//     integerLimit: getMaksOption(customOptions, 'integerLimit', 14),
//     // requireDecimal: getMaksOption(customOptions, 'requireDecimal', true),
//     allowNegative: getMaksOption(customOptions, 'allowNegative', false),
//     allowLeadingZeroes: getMaksOption(customOptions, 'allowLeadingZeroes', false),
//   })
//   return currencyMask
// }

// function getMaksOption(maskOptions, optionName, defaultValue) { return optionName in maskOptions ? maskOptions[optionName] : defaultValue }

export const numberMask = '#'

export const dateMask = '##/##/####'

export const timeMask = '##:##:##'

export const foneMask = ['(', /\d/, /\d/, ') ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const cpfMask = '###.###.###-##'

export const cnpjMask = '##.###.###/####-##'