<template>
  <div class="router">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'Router',
}
</script>

<style>
  .router {
    grid-area: router;
    overflow-y: scroll;
  }
  @media print { .router { overflow-y: hidden; } }
</style>