<template>
  <div id="divList">
  <v-card v-if="$route.query.menuId != '' && canAction('list')">
    <v-card-title>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Busca"
        :disabled="FieldSelect.searchFields.length &lt;= 0"
        single-line
        hide-details
        outlined
        dense
        :style="`margin-top: 3px; border-radius: 0px 3px 3px 0px;`"
        @keydown.enter="key++"
      >
        <template slot="append">
          <v-btn
            @click="key++"
            outlined
            small
            class="inLineButton"
          >Buscar</v-btn>
        </template>
        <template slot="append">
          <v-btn
            @click="search='';key++"
            outlined
            small
            class="inLineButton"
          >Limpar</v-btn>
        </template>
      </v-text-field>
      <FieldSelect :field="optionsFields()" :vmodel="FieldSelect" :logic="this" :FormLines="FormLines()"/>
    </v-card-title>
    <v-data-table
      dense
      :key="key"
      :headers="headers"
      :items="items"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :no-data-text="'Nenhum registro encontrado'"
      :no-results-text="'A busca não encontrou nenhum resultado'"
      :server-items-length="pagination.itemsLength"
      :items-per-page="pagination.itemsPerPage"
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
        'items-per-page-options': [ 10, 20, 50, 100, -1 ],
        'page-text': `${((pagination.page - 1) * pagination.itemsPerPage) + 1}-${(pagination.page * pagination.itemsPerPage <= pagination.itemsLength ? pagination.page * pagination.itemsPerPage : pagination.itemsLength)} de ${pagination.itemsLength}`
      }"
      @pagination="pagination = $event"
      @update:sort-by="sortBy = $event; sortDesc = false; key++"
      @update:sort-desc="sortDesc = $event; key++"
      @click:row="showItem"
    ></v-data-table>
  </v-card>
  </div>
</template>

<script>
import FieldSelect from './Form/FieldSelect'
import * as backend from '@/lib/backend'
import * as files from '@/lib/files'
import * as js_functions from '@/lib/js_functions'
import { mapState } from 'vuex'
export default {
    name: "List",
    components: { FieldSelect },
    computed: { ...mapState(['user', 'permission']) },
    data: () => ({
      key: 0,
      freeze: false,
      search: '',
      FieldSelect: { searchFields: [] },
      searchOptions: [],
      headers: [],
      sortBy: '',
      sortDesc: true,
      items: [],
      pagination: {
        pageCount: 1,
        page: 1,
        itemsPerPage: 10,
        itemsLength: 100,
      },
      fields: [],
      filters: [],
      logic: null,
      backend: null,
      js_functions: null
    }),
    watch: {
      async key() { this.load(false) },
      pagination(newValue, oldValue) { this.paginationNotChanged(newValue, oldValue) || this.readListItems() }
    },
    methods: {
      FormLines() { return {
        Form: { backend, js_functions },
        rules: () => [],
        styles: { '--over-color': '' },
        showIcon: () => ''
      }},
      optionsFields() {
        return {
          type: 'select',
          autocomplete: true,
          name: 'searchFields',
          label: 'Buscar nos campos',
          multiple: true,
          options: this.searchOptions.map(option => { return { option } }),
          backgroundcolor: 'white',
          class: 'FieldsFilter',
          maxwidth: '200px'
        }
      },
      getFolder() { return this.$route.query.folder ? this.$route.query.folder : this.$route.query.menuId },
      async load(start) {
        this.$store.commit("showOverlay")
        await this.getLogic()
        this.freeze = true
        this.sortDesc = (this.sortBy == undefined || this.sortBy == '' || (this.sortBy == this.sortDefault && this.sortDefaultDescendant)) ? true : this.sortDesc
        if (start) {
          this.search = ''
          if (!await this.readListHeaders()) {
            this.filters = []
            this.items = []
            this.headers = []
            this.$store.commit("showError", { data: 'Não foi possível carregar.\nO arquivo de configurações não foi encontrado.' })
            return
          }
        }
        await this.readListCount()
        await this.readListItems()
        this.freeze = false
        this.$store.commit("hideOverlay")
      },
      paginationNotChanged(newValue, oldValue) {
        if (this.freeze) return true
        if (newValue.pageCount != oldValue.pageCount) return false
        if (newValue.page != oldValue.page) return false
        if (newValue.itemsPerPage != oldValue.itemsPerPage) return false
        if (newValue.itemsLength != oldValue.itemsLength) return false
        return true
      },
      getListProject() {
        if (!this.values) return ''
        const project = this.values.map(header => header.value)
        project.filter(value => value.startsWith('link_')).map(value => project.push(value.substring(5)))
        return project.join(';')
      },
      getLinkFields() {
        return this.headers.filter(header => header.type == 'link').map(header => `${header.value},${header.dataId},${header.fields}`).join(';')
      },
      async getFieldsName(company) {
        const folder = this.getFolder()
        const addFieldName = (fieldsNames, field, fieldName, multilinesName, multilinesLabel) => {
          const fieldTypesToSkip = ['chart', 'timeline', 'timelineControler', 'html']
          if (fieldTypesToSkip.includes(field.type)) { return }
          if (field.type == 'link') {
            field.fields.map(_field => {
              const fieldLinkPath = multilinesName ? `${multilinesName}.link_${_field.name}` : `link_${_field.name}`
              const optionText = multilinesName ? `${multilinesLabel} > ${_field.label}` : _field.label
              fieldsNames.push(fieldLinkPath)
              this.searchOptions.push({ "text": optionText, "value": fieldLinkPath })
            })
          }
          else {
            const optionText = multilinesName ? `${multilinesLabel} > ${field.label}` : field.label
            if (field.type == 'linkSimple' || (field.type == 'select' && field.addlink == true)) {
              const fieldLinkPath = multilinesName ? `${multilinesName}.link_${field.name}` : `link_${field.name}`
              fieldsNames.push(fieldLinkPath)
              this.searchOptions.push({ "text": optionText, "value": fieldLinkPath })
            }
            else {
              const fieldPath = multilinesName ? `${multilinesName}.${fieldName}` : fieldName
              fieldsNames.push(fieldPath)
              this.searchOptions.push({ "text": optionText, "value": fieldPath })
            }
          }
        }
        this.fields = []
        this.fields = await files.get(`${company}/${this.$route.query.menuId}/fields.json`)
        if (!Array.isArray(this.fields)) { this.fields = await files.get(`${company}/${folder}/fields.json`) }
        if (!Array.isArray(this.fields)) {
          this.items = []
          this.headers = []
          this.$store.commit("showError", { data: 'Não foi possível carregar.\nO arquivo de configurações não foi encontrado.' })
          return false
        }
        const fieldsNames = []
        this.fields.map(line => {
          !line.fields || line.fields.map(field => {
            if (field.searchable != false) {
              if (field.type != 'multilines') { addFieldName(fieldsNames, field, field.name) }
              else { field.fields.map(multilinesField => {
                if (multilinesField.searchable != false) {
                  addFieldName(fieldsNames, multilinesField, multilinesField.name, field.name, field.label)
                }
              }) }
            }
          })
        })
        return Array.isArray(fieldsNames) && fieldsNames.length > 0 ? fieldsNames : []
      },
      async readListHeaders() {
        if (!this.$route.query.menuId) { return }
        const folder = this.getFolder()
        const company = (this.$route.name == 'controls') ? 'default' : this.user.company
        let list = await files.get(`${company}/${this.$route.query.menuId}/list.json`)
        if (!Array.isArray(list)) { list = await files.get(`${company}/${folder}/list.json`)}
        if (!Array.isArray(list)) { return false }
        this.filters = list ? list.filter(item => item.filter) : ''
        this.headers = list ? list.filter(header => header.text) : ''
        this.values = list ? list.filter(header => header.value) : ''
        const sortDefault = list ? list.find(header => header.sortDefault) : {}
        this.sortBy = sortDefault && sortDefault.sortDefault ? sortDefault.sortDefault : ''
        this.dateFields = list.filter(header => header.dataType == 'date').map(header => header.value)
        this.numberFields = list.filter(header => header.dataType == 'number').map(header => header.value)
        this.sortDefault = this.sortBy
        this.sortDefaultDescendant = sortDefault && sortDefault.sortDefaultDescendant ? sortDefault.sortDefaultDescendant : false
        this.FieldSelect.searchFields = await this.getFieldsName(company, folder)
        return true
      },
      async readListCount() {
        if (!this.$route.query.menuId) { return }
        await backend.get({
          module: this.$route.name,
          dataId: this.$route.query.menuId,
          early: true,
          query: {
            search: this.search,
            count: true
          },
          config: {
            headers: {
              searchFields: this.search != '' && Array.isArray(this.FieldSelect.searchFields) && this.FieldSelect.searchFields.length > 0 ? this.FieldSelect.searchFields.join(';') : ''
            }
          }
        }).then(res => {
          this.pagination.itemsLength = res.data
          this.pagination.pageCount = this.pagination.itemsLength / this.pagination.itemsPerPage
        }).catch(e => { this.$store.commit("showError", e) })
      },
      async readListItems() {
        if (!this.$route.query.menuId) { return }
        this.$store.commit("showOverlay")
        const sort = (this.sortBy && this.sortBy != '') ? (Array.isArray(this.sortBy) ? this.sortBy.join(',') : this.sortBy) : ''
        const linkfields = this.getLinkFields()
        await backend.get({
          module: this.$route.name,
          dataId: this.$route.query.menuId,
          early: true,
          query: {
            filterField: (Array.isArray(this.filters) && this.filters.length > 0 ) ? this.filters[0].field : '',
            filter: (this.logic?.list && Array.isArray(this.filters) && this.filters.length > 0 && typeof this.logic.list[this.filters[0].filter] === 'function') ? await this.logic.list[this.filters[0].filter](this) : '',
            search: this.search,
            sort,
            dateFields: Array.isArray(this.dateFields) && this.dateFields.includes(sort) ? sort : '',
            numberFields: Array.isArray(this.numberFields) && this.numberFields.includes(sort) ? sort : '',
            sortDirection: this.sortDesc ? -1 : 1,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            limit: this.pagination.itemsPerPage > 0 ? this.pagination.itemsPerPage : this.pagination.itemsLength
          },
          config: {
            headers: {
              searchFields: this.search != '' && Array.isArray(this.FieldSelect.searchFields) && this.FieldSelect.searchFields.length > 0 ? this.FieldSelect.searchFields.join(';') : '',
              projectFields: this.getListProject(),
              linkfields
            }
          }
        }).then(async res => {
          const linkByRule = this.headers.find(header => header.type == 'linkByRule')
          this.items = linkByRule ? await window.universal.lib.replaceAllLinks({ backend, originalData: res.data, dataId: this.$route.query.menuId }) : res.data
          this.items = linkByRule ? await window.universal.lib.filterAllRegisters({ originalData: this.items, dataId: this.$route.query.menuId }) : this.items
        }).catch(e => {
          this.items = []
          this.$store.commit("showError", e)
        })
        this.$store.commit("hideOverlay")
      },
      async showItem(item) {
        if (this.canAction('read')) {
          if (item._id != this.$route.query.id || this.$route.query.tabNumber != 2) {
            this.$store.commit("showOverlay")
            this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber: 2, id: item._id } })
              .then(()=>{ this.$store.commit("hideOverlay") })
              .catch(()=>{ this.$store.commit("hideOverlay") })
          }
        }
        else { this.$store.commit("showError", { data: 'Sem permissão para visualizar' }) }
      },
      canAction(action) { return this.$store.getters.canAction(action) },
      async getLogic() {
        if (!this.$route.query.menuId) { return }
        const company = (this.$route.name == 'controls') ? 'default' : this.user.company
        const folder = this.getFolder()
        const dataId = this.$route.query.menuId
        const dataIdPath = (this.mode == 'report') ? `reports/${dataId}` : dataId
        const folderPath = (this.mode == 'report') ? `reports/${folder}` : folder
        const loadLogicAndRun = (logic) => {
          this.logic = logic
          this.runLogic()
        }
        const loadScript = (path, dataIdOrFolder) => {
          return files.loadScript(`${company}/${path}/logic.js`)
            .then(() => { loadLogicAndRun(window[`${this.$route.name}_${dataIdOrFolder}`]) })
            .catch(() => { loadLogicAndRun({ loadList: () => {} }) })
        }
        const logicFileExists = async (path) => !(await files.get(`${company}/${path}/logic.js`)).status
        this.logic = null
        if (window[`${this.$route.name}_${dataId}`]) { loadLogicAndRun(window[`${this.$route.name}_${dataId}`]) }
        if (this.logic == null && await logicFileExists(dataIdPath)) { await loadScript(dataIdPath, dataId) }
        if (this.logic == null && window[`${this.$route.name}_${folder}`]) { loadLogicAndRun(window[`${this.$route.name}_${folder}`]) }
        if (this.logic == null && await logicFileExists(folderPath)) { await loadScript(folderPath, folder) }
        if (this.logic == null) { loadLogicAndRun({ loadList: () => {} }) }
        return
      },
      async runLogic() {
        if (this.logic && typeof this.logic.loadList === 'function') { this.logic.loadList(this) }
        this.$store.commit("hideOverlay")
      }
    },
    mounted() {
      this.backend = backend
      this.js_functions = js_functions
    },
    created() {
      this.$watch(
        () => this.$route.query,
        async (query, oldQuery) => {
          if (query.menuId && (!oldQuery || query.menuId != oldQuery.menuId)) {
            this.searchOptions = []
            if (query.menuId != '') { this.load(true)}
          }
          if (query.tabNumber && (!oldQuery || query.tabNumber != oldQuery.tabNumber)) {
            if (query.tabNumber != '' && query.tabNumber == '1' && query.menuId != '') { this.load(false)}
          }
        },
        { immediate: true }
      )
    },
}
</script>

<style>
  div#divList .v-card__title {
    flex-direction: row-reverse;
    padding: 0px 0px 20px 0px;
  }
  div#divList .v-card__title .v-text-field {
    flex: none;
    width: 50%;
    padding: 0px;
    margin: 0px;
    background-color: white;
  }
  div#divList .v-card__title .v-text-field.FieldsFilter {
    margin: 3px -1px 0px 0px; 
    border-radius: 3px 0px 0px 3px;
  }
  div#divList div.v-data-table tr {
    cursor: pointer;
  }
  .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  div#divList div.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    background-color: var(--list-header-background-color);
    color: var(--list-header-color);
    padding-top: 5px;
    padding-bottom: 5px;
    border-right: 1px solid white;
  }
  div#divList div.v-data-table > .v-data-table__wrapper > table > thead > tr > th.active {
    background-color: var(--list-header-active-background-color);
    color: var(--list-header-active-color);
  }
  div#divList div.v-data-table > .v-data-table__wrapper > table > thead > tr > th i {
    display: none;
    /* min-width: 80%;
    color: var(--table-header-color); */
  }
  div#divList div.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(even) { background-color: #FAFAFA; }
  div#divList div.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover { background-color: var(--active-background-color); }
</style>