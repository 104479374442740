<template>
  <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top v-if="(field.type === 'textarea' || field.type === 'map') && (field.show == undefined || field.show)">
    <template v-slot:activator="{ on, attrs }">
      <v-textarea
        v-bind="attrs" v-on="on"
        v-if="(field.type === 'textarea' || field.type === 'map') && (field.show == undefined || field.show)"
        :key="field.name"
        :name="field.name"
        :ref="field.name"
        :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
        v-model="vmodel[field.name]"
        outlined
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        dense
        hide-details
        :disabled="field.disabled"
        :readonly="field.readonly || blockField(field.name, multiline) || blockLine()"
        :rules="FormLines.rules(field)"
        :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
        :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
        :background-color="field.backgroundcolor"
        @click="event(field.click)"
        @change="event(field.change)"
        :color="FormLines.styles['--over-color']"
        :autofocus="field.focus"
      >
        <template slot="append">
          <v-icon v-for="(icon, index) in FormLines.showIcon(field)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
          <AppendIconTooltip :field="field" />
          <AppendIconLink :value="vmodel[field.name]" />
          <template v-for="action in field.actions">
            <v-icon v-if="action.type == 'icon' && !action.outer" :key="action.text" @click="event(action.method)" class="hover">{{action.text}}</v-icon>
            <v-btn
              v-if="action.type == 'button' && !action.outer"
              :key="action.text"
              @click="event(action.method)"
              outlined
              small
              class="inLineButton"
              :color="FormLines.styles['--over-color']"
            >{{action.text}}</v-btn>
          </template>
        </template>
        <template slot="append-outer">
          <template v-for="action in field.actions">
            <v-icon v-if="action.type == 'icon' && action.outer" :key="action.text" @click="event(action.method)" class="hover">{{action.text}}</v-icon>
            <v-btn
              v-if="action.type == 'button' && action.outer"
              :style="action.enabled ? 'pointer-events: auto;' : ''"
              :key="action.text"
              @click="event(action.method)"
              outlined
              class="inLineButton outer"
              :loading="action.loading"
              :color="FormLines.styles['--over-color']"
            >{{action.text}}</v-btn>
          </template>
        </template>
      </v-textarea>
    </template>
    <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import AppendIconLink from './pieces/AppendIconLink'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldTextarea",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { AppendIconLink, AppendIconTooltip },
  computed: { ...mapState(['register']) },
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), name: this.field.name, value: this.vmodel[this.field.name], vmodel: this.vmodel, lineNumber: this.line }) } }
  },
  mounted() {
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  .inLineButton.outer {
    margin-top: -6px;
  }
</style>

<style>
  div.line .v-textarea textarea {  line-height: 1.25rem !important; }
</style>