import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './config/mq'
import store from './config/store'
import vuetify from './plugins/vuetify';
import router from './router'
import VueMask from 'v-mask'
import VueMaskDI from 'di-vue-mask'
import LoadScript from 'vue-plugin-load-script'
import Timeline from './lib/chart/timeline/timeline'

// Vue.config.productionTip = false
Vue.use(VueMask)
Vue.use(VueMaskDI)
Vue.use(LoadScript)
Vue.use(Timeline)

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
