export const userKey = '__btb_user'
export const baseApiUrl = getHost('api')
export const baseFilesUrl = getHost('files')

export function getHost(layer) {
  const _host = window.location.hostname
  const host = _host.indexOf('.') > -1 ? _host.substring(_host.indexOf('.') + 1) : _host
  const localPorts = {
    api: 3000,
    files: 3010
  }
  return host == 'localhost' ? `http://localhost:${localPorts[layer]}` : `https://${layer}.btb.app.br`
}

export default { userKey, baseApiUrl, baseFilesUrl }