<template>
  <div
    v-if="field.type === 'image' && (field.show == undefined || field.show)"
    class="d-flex justify-space-around align-center divImage"
    :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.width}; max-width: ${field.width};`"
  >
    <v-img
      :max-height="field.maxheight"
      :contain="true"
      :src="vmodel[field.name]"
    ></v-img>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "FieldImage",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  computed: { ...mapState(['register']) }
}
</script>

<style scoped>
  .divImage {
    min-height: 300px;
    padding: 2px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
  }
</style>