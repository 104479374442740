// import * as js_functions from '@/lib/js_functions'

export function chart_functions() {
  this.configChart = function(Chart, divChart, callback) {
    try {
        if (window.google != undefined && divChart) {
          window.google.load('visualization', '1', { packages: ['corechart', 'vegachart'], callback: () => {
            Chart.data = new window.google.visualization.DataTable()
            callback()
          }})
        }
    }
    catch (error) { console.error(error) }
  }
  this.loadChart = function(builder) {
    builder.columns.map(column => builder.Chart.data.addColumn(column))
    builder.Chart.data.addRows(builder.rows)
    builder.Chart.chart = new window.google.visualization[builder.type](builder.canvas)
    builder.Chart.chart.draw(builder.Chart.data, builder.options)
  }
  this.updateChart = function(params) {
    const { FormLines, name } = params
    FormLines.Form.js_functions.getJSONObject({ fields: FormLines.fields, name }).key += 1
  }
}