<template>
  <div v-if="field.show == undefined || field.show" class="FieldTimeline" :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
    <div ref="divTimeline" class="divTimeline" :class="field.class"></div>
    <div class="buttons">
      <v-btn outlined small @click="changeZoomable" :class="{ 'active': zoomable }" class="hover"><v-icon small>mdi-cursor-move</v-icon></v-btn>
      <v-btn outlined small @click="showFirst" :class="{ 'active': first }" class="hover">Previsões</v-btn>
      <v-btn outlined small @click="showDetails" :class="{ 'active': details }" class="hover">Detalhes</v-btn>
    </div>
  </div>
</template>

<script>
import * as files from '@/lib/files'
import * as js_functions from '@/lib/js_functions'
import { timeline_functions } from '@/lib/chart/timeline_functions'
import { mapState } from 'vuex'
export default {
  name: "FieldTimeline",
  props: ['FormLines', 'logic', 'field', 'vmodel'],
  computed: { ...mapState(['register', 'user', 'timelineEditable']) },
  data: () => ({
    timeline: null,
    logicTimeline: null,
    js_functions: null,
    functions: null,
    data: null,
    first: false,
    details: false,
    zoomable: false
  }),
  watch: {
    register: {
      deep: true,
      handler() { this.data == null || this.loadTimeline() }
    }
  },
  methods: {
    start() {
      this.loadLogic()
      this.functions = new timeline_functions
      this.functions.configTimeline(this, this.$refs.divTimeline)
    },
    async loadLogic() {
      try {
        await files.loadScript(`${this.user.company}/logicTimeline.js`)
        this.logicTimeline = window.objectLogicTimeline
      }
      catch(e) { return }
    },
    loadTimeline() { this.functions.loadTimeline(this, this.$refs.divTimeline, this.first) },
    showFirst() {
      this.first = !this.first
      this.loadTimeline()
    },
    showDetails() {
      this.details = !this.details
      this.loadTimeline()
    },
    changeZoomable() {
      this.zoomable = !this.zoomable
      this.timeline.options['zoomable'] = this.zoomable
    }
  },
  mounted() {
    this.js_functions = js_functions
    this.start()
  },
  created() {
    this.$watch(
      () => this.$route.query.tabNumber,
      (tabNumber) => {
        if ((tabNumber == 2 && this.FormLines.mode == 'edit') || (tabNumber == 0 && this.FormLines.mode == 'new')) { this.start() }
      },
      { immediate: true }
    )
  }
}
</script>

<style src='../../../lib/chart/timeline/timeline.css'></style>
<style src='../../../lib/chart/timeline/timeline-theme.css'></style>

<style>
  div.FieldTimeline {
    padding: 0px 5px;
    position: relative;
    margin-bottom: 10px;
  }
  div.FieldTimeline div.buttons { width: 280px; margin-top: -38px; margin-left: 2px; display: flex; justify-content: space-around; }
  div.FieldTimeline div.buttons button.v-btn { color: var(--over-color); }
  div.FieldTimeline div.buttons button.v-btn.active { background: var(--active-background-color); }
  div.FieldTimeline div.divTimeline {
    min-height: 105px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
  }
  div.FieldTimeline div.divTimeline:hover { border-color: rgba(0, 0, 0, 0.87); }

  @media screen { div.FieldTimeline div.divTimeline.ZoomActive { border: 1px solid red; } }
  div.FieldTimeline div.divTimeline div.timeline-event-line { border-width: 4px; }
  div.FieldTimeline div.divTimeline div.timeline-event-line,
    div.FieldTimeline div.divTimeline div.timeline-event-box,
    div.FieldTimeline div.divTimeline div.timeline-event-range,
    div.FieldTimeline div.divTimeline div.timeline-event-dot { background-color: #CCCCCC; border-color: #CCCCCC; color: #FFFFFF; }
  div.FieldTimeline div.divTimeline div.timeline-event-box { padding: 0px; cursor: pointer; font-size: 10pt; }
  div.FieldTimeline div.divTimeline div.timeline-event-box div.timeline-event-content { margin: 0px 3px; padding: 0px; }
  div.FieldTimeline div.divTimeline div.timeline-event-range { height: 14px; cursor: pointer; border: 0px; margin: 0px; padding: 0px 3px; font-size: 8pt; }
  div.FieldTimeline div.divTimeline div.timeline-event-content { border: 0px; margin: 0px; padding: 0px; }
  div.FieldTimeline div.divTimeline div.timeline-currenttime { background-color: #00467f; }
  div.FieldTimeline div.divTimeline div.Previsao { background-color: #CCCCCC; border-color: #CCCCCC; color: #666666; }
  /* div.FieldTimeline div.divTimeline div.Previsao_Copy { background-color: #DDDDDD; border-color: #DDDDDD; color: #666666; } */
  div.FieldTimeline div.divTimeline div.Previsao_Copy { background-color: #CCCCCC; border-color: #CCCCCC; color: #666666; }
  div.FieldTimeline div.divTimeline div.ASuperar { background-color: #FFA500; border-color: #FFA500; color: white; }
  div.FieldTimeline div.divTimeline div.ASuperar_Copy { background-color: #BBAC87; border-left: 5px solid #FFA500; color: white; }
  div.FieldTimeline div.divTimeline div.timeline-event-line.ASuperar_Copy, div.FieldTimeline div.divTimeline div.timeline-event-dot.ASuperar_Copy  { border-color: #BBAC87; }
  div.FieldTimeline div.divTimeline div.Superado { background-color: #88CC88; border-color: #88CC88; color: white; }
  div.FieldTimeline div.divTimeline div.Superado_Copy { background-color: #99AA99; border-left: 5px solid #88CC88; color: white; }
  div.FieldTimeline div.divTimeline div.timeline-event-line.Superado_Copy, div.FieldTimeline div.divTimeline div.timeline-event-dot.Superado_Copy { border-color: #99AA99; }
  div.FieldTimeline div.divTimeline div.Atrasado { background-color: #EE8888; border-color: #EE8888; color: white; }
  /* div.FieldTimeline div.divTimeline div.Atrasado_Copy { background-color: #FF9999; border-left: 5px solid #EE8888; color: white; } */
  div.FieldTimeline div.divTimeline div.Atrasado_Copy { background-color: #BF9A9E; border-left: 5px solid #EE8888; color: white; }
  div.FieldTimeline div.divTimeline div.timeline-event-line.Atrasado_Copy, div.FieldTimeline div.divTimeline div.timeline-event-dot.Atrasado_Copy { border-color: #BF9A9E; }
  div.FieldTimeline div.divTimeline div.timeline-groups-text div.Atrasado { color: #FF0000; }
  div.FieldTimeline div.divTimeline div.timeline-groups-text div.Atrasado_Copy { color: #FF0000; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container { border: 0px; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container, div.FieldTimeline div.divTimeline div.timeline-event-dot-container * { background: none; color: black; }

  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.ASuperar div.timeline-event-content { color: #FFA500; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.ASuperar_Copy div.timeline-event-content { color: #FFA500; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.Superado div.timeline-event-content { color: #88CC88; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.Superado_Copy div.timeline-event-content { color: #88CC88; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.Atrasado div.timeline-event-content { color: #EE8888; }
  div.FieldTimeline div.divTimeline div.timeline-event-dot-container.Atrasado_Copy div.timeline-event-content { color: #EE8888; }

  .ButtonsLine { float: right; margin-top: -36px; margin-right: 4px; }
  div.FieldTimeline div.divTimeline div.timeline-groups-text { font: 9pt Helvetica, Arial, Sans-Serif; display: inline; position: relative; padding-left: 2px; vertical-align: center; }
  div.FieldTimeline div.divTimeline div.timeline-groups-text { width: 280px; }
  div.FieldTimeline div.divTimeline div.DuracaoColumn { display: inline; position: absolute; width: 50px; right: 0px; background-color: #FFFFFF; padding-left: 1px; border-left: 1px solid #bebebe; text-align: center; }
  div.FieldTimeline div.divTimeline div.MesReferenciaColumn { display: inline; position: absolute; width: 42px; right: 52px; background-color: #FFFFFF; padding-left: 1px; border-left: 1px solid #bebebe; text-align: center; }
  div.FieldTimeline div.divTimeline div.DateColumn { display: inline; position: absolute; right: 0px; background-color: #FFFFFF; padding-left: 4px; border-left: 1px solid #bebebe; }
  div.FieldTimeline div.divTimeline div.DateColumn { width: 112px; }
  div.FieldTimeline div.divTimeline div.DepartamentoColumn { display: inline; position: absolute; width: 35px; right: 118px; background-color: #FFFFFF; padding-left: 4px; border-left: 1px solid #bebebe; text-align: center; }
  div.FieldTimeline div.divTimeline div.timeline-axis-text { font-size: 8pt; padding-left: 2px; }
  div.FieldTimeline div.divTimeline span.Group_TimelineHeading { color: #FFFFFF; }
  div.FieldTimeline div.divTimeline div.TimelineHeading { font-weight: bold; font-size: 8pt; }

</style>