import * as js_functions from '@/lib/js_functions'
import { getGenericRegisters } from '@/lib/core/universal'
import { vtos } from '@/lib/core/public/vtos'
import { iptus } from '@/lib/core/public/iptus'

export function map_functions_public() {
  this.existCustom = (Map, name) => Map.logicMaps && Map.logicMaps.infowindows && Map.logicMaps.infowindows[name] && typeof Map.logicMaps.infowindows[name] == "function"
  this.returnCustom = (Map, name, defaultValue) => this.existCustom(Map, name) ? Map.logicMaps.infowindows[name](Map) : defaultValue
  this.getCustom = (Map, name) => this.returnCustom(Map, name, this.defaultValue[name])
  this.defaultValue = {
    quadras: { cadastrarNaQuadra: { text: "Terreno", dataId: "terrenos" } },
    lotes: { cadastrarNosLotes: { text: "Terreno", dataId: "terrenos" } },
    operacoes_urbanas: { detalhes: { show: false } },
    operacoes_urbanas_setores: { detalhes: { show: false } },
    macro_areas_poligonos: { detalhes: { show: false } },
    cartorios: { detalhes: { show: false } }
  }
  this.insertDistritos = async function(Map) {
    Map.$store.commit("showOverlay")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'distritos',
      label: 'Distritos',
      company: 'public',
      module: 'data',
      dataId: 'distritos',
      action: 'read/many',
      body: { "project": { Poligono: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': 'public/distritos.json'},
      options: (builder) => { return !builder || { fillColor: "#999999", fillOpacity: 0.2, strokeColor: "#999999", strokeOpacity: 0.8, strokeWeight: 3, zIndex: 4 } },
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { Nome: 1 })
        return `<h3 class="infowindowTitle">Distrito</h3><h3>${registerValues.Nome}</h3>
          <div class="infoButtons">
            <div class="buttonOption">
              <button id="infoWindow_abrirquadra">Abrir Quadras</button>
              <div>
                <input type="checkbox" id="infoWindow_remove" name="infoWindow_remove" checked>
                <label for="infoWindow_remove">Remover Distrito</label>
              </div>
            </div>
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_abrirquadra',
          event: 'onclick',
          action: (params) => {
            const remove = document.getElementById('infoWindow_remove').checked
            if (remove) {
              params.object.setMap(null)
              params.builder.Map.functions.removeFromAllMapArrays(params.builder.Map, 'distritos', params.object)
            }
            const query = { connections: { $elemMatch: { dataId: 'distritos', _id: params.builder.register._id } } }
            const queryFile = `/distrito=${params.builder.register._id}`
            params.infoWindow.close()
            this.insertQuadras(Map, query, queryFile)
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertSetores = async function(Map) {
    if (Map.functions.isOnMap(Map, 'setores')) return
    Map.$store.commit("showOverlay")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'setores',
      label: 'Setores',
      company: 'public',
      module: 'data',
      dataId: 'setores',
      action: 'read/many',
      body: { "project": { Poligono: 1, st_codigo: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': 'public/setores.json' },
      options: (builder) => { return !builder || { fillColor: "#0000FF", fillOpacity: 0.2, strokeColor: "#0000FF", strokeOpacity: 0.8, strokeWeight: 3, zIndex: 3 } },
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { st_codigo: 1 })
        return `<h3 class="infowindowTitle">Setor</h3><h3>${registerValues.st_codigo}</h3>
          <div class="infoButtons">
            <div class="buttonOption">
              <button id="infoWindow_abrirquadra">Abrir Quadras</button>
              <div>
                <input type="checkbox" id="infoWindow_remove" name="infoWindow_remove" checked>
                <label for="infoWindow_remove">Remover Setor</label>
              </div>
            </div>
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_abrirquadra',
          event: 'onclick',
          action: (params) => {
            const remove = document.getElementById('infoWindow_remove').checked
            if (remove) {
              params.object.setMap(null)
              params.builder.Map.functions.removeFromAllMapArrays(params.builder.Map, 'setores', params.object)
            }
            const query = { Setor: params.builder.register.st_codigo }
            const queryFile = `/setor=${params.builder.register.st_codigo}`
            params.infoWindow.close()
            this.insertQuadras(Map, query, queryFile)
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertQuadras = async function(Map, query, queryFile) {
    const custom = this.getCustom(Map, "quadras")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'quadras',
      label: 'Quadras',
      company: 'public',
      module: 'data',
      dataId: 'quadras',
      action: 'read/many',
      body: { "query": { ...query }, "project": { Poligono: 1, Setor: 1, Quadra: 1, qd_tipo: 1, sqt: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/quadras.json${queryFile}` },
      options: (builder) => { return !builder || { fillColor: "#95A2B8", fillOpacity: 0.2, strokeColor: "#95A2B8", strokeOpacity: 0.8, strokeWeight: 1, zIndex: 2 } },
      info: async (builder) => {
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { Setor: 1, Quadra: 1, connections: 1, qd_tipo: 1, qd_tx_tipo: 1, qd_subqua: 1 })
        const hasSubqua = (setor, quadra, tipo) => builder.Map.objects.quadras.filter(_quadra => _quadra.builder.register.Setor == setor && _quadra.builder.register.Quadra == quadra && _quadra.builder.register.qd_tipo == tipo).length > 1
        let content = `<h3 class="infowindowTitle">Quadra</h3>`
        content += `<h3>${registerValues.qd_tipo} | ${registerValues.Setor}.${registerValues.Quadra}${hasSubqua(registerValues.Setor, registerValues.Quadra, registerValues.qd_tipo) ? ' (' + registerValues.qd_subqua + ')' : ''}</h3>`
        content += `<div class="infoBlock">
          <h4>Geral</h4>
            <ul>
              <li><label>Tipo:</label> ${registerValues.qd_tx_tipo}</li>
              <li><label>Subquadra:</label> ${registerValues.qd_subqua}</li>
              <li><label>Distrito:</label> ${js_functions.getConnectionIdByDataId(registerValues.connections, 'distritos', 'all', 'link__id')}</li>
            </ul>
        </div>`
        const operacaoUrbanaId = js_functions.getConnectionIdByDataId(registerValues.connections, 'operacoes_urbanas', 'first')
        if (operacaoUrbanaId != false) {
          const operacaoUrbana = await this.getOperacaoUrbana(builder.Map, operacaoUrbanaId)
          if (operacaoUrbana) {
            content += `<div class="infoBlock">
              <h4>Operação Urbana</h4>
                <ul>
                  <li>${operacaoUrbana.nome}</li>
                </ul>
            </div>`
          }
        }
        const zoneamentoIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'lpuos2016', 'all')
        if (zoneamentoIds != false) {
          const zoneamentos = await this.getZoneamentos(builder.Map, zoneamentoIds, { nome: 1, caBasico: 1, caMaximo: 1, gabarito: 1, taxaOcupacao: 1, cotaTerreno: 1, vagasPorUnidade: 1 })
          if (zoneamentos.length > 0) {
            const zoneamentosTableLines = zoneamentos.reduce((lines, zoneamento) => lines + `<tr>
              <td>${zoneamento.nome || '-'}</td>
              <td>${zoneamento.caBasico || '-'}</td>
              <td>${zoneamento.caMaximo || '-'}</td>
              <td>${zoneamento.gabarito || '-'}</td>
              <td>${zoneamento.taxaOcupacao || '-'}</td>
              <td>${zoneamento.cotaTerreno || '-'}</td>
              <td>${zoneamento.vagasPorUnidade || '-'}</td>
            </tr>`,  '')
            content += `<div class="infoBlock">
              <h4>Zoneamento</h4>
              <table>
                <tr><th>Zona</th><th>CAbas</th><th>CAmax</th><th>Gabarito</th><th>T.O</th><th>Cota Terreno</th><th>Vagas/UH</th></tr>
                ${zoneamentosTableLines}
              </table>
            </div>`
          }
        }
        const macroAreasIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'macro_areas', 'all')
        if (macroAreasIds != false) {
          const macroAreas = await this.getMacroAreas(builder.Map, { _id: { $in: macroAreasIds } }, { nome: 1, setor: 1, FpR: 1, FpnR: 1 })
          if (macroAreas && macroAreas.length > 0) {
              const macroAreasTable = macroAreas.reduce((lines, macroArea) => lines + `<h4>${macroArea.nome}</h4>
              <table>
                <tr>${macroArea.setor ? '<th>' + 'Setor' + '</th>' : ''}<th>Fp R</th><th>Fp nR</th></tr>
                <tr>
                  ${macroArea.setor ? '<td>' + macroArea.setor + '</td>' : ''}
                  ${macroArea.FpR ? '<td>' + macroArea.FpR + '</td>' : '<td>-</td>'}
                  ${macroArea.FpnR ? '<td>' + macroArea.FpnR + '</td>' : '<td>-</td>'}
                </tr>
              </table>`,  '')
              content += `<div class="infoBlock">
                  ${macroAreasTable}
              </div>`
            }
        }
        const cartoriosIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'cartorios', 'all')
        if (cartoriosIds != false) {
          const cartorios = await this.getCartorios(builder.Map, cartoriosIds)
          if (cartorios && cartorios.length > 0) {
            const cartoriosListLines = cartorios.reduce((lines, cartorio) => lines + `<ul>
              <li>${cartorio.numero} - ${cartorio.subdistrito}</li>
            </ul>`,  '')
            content += `<div class="infoBlock">
              <h4>Cartório</h4>
                ${cartoriosListLines}
            </div>`
          }
        }
        const vtoDataId = (new vtos()).lastDataId
        const quadraVTOs = (await js_functions.getFunc({ FormLines: builder.Map, func: () => getGenericRegisters({ backend: builder.Map.backend, company: 'public', module: 'data', dataId: vtoDataId, query: { sq: { $in: [`${registerValues.Setor}${registerValues.Quadra}`] } }, project: { sq: 1, codlog: 1, valor: 1 } }), returnData: true })).data
        if (Array.isArray(quadraVTOs) && quadraVTOs.length > 0) {
          const codlog = quadraVTOs.map((vto) => vto.codlog)
          const logradouros = await this.getLogradouros(builder.Map, registerValues.Setor, registerValues.Quadra, codlog, '2010')
          const vtoTableLines = quadraVTOs.reduce((lines, vto) => {
            const _logradouro = logradouros.find(logradouro => logradouro.Codlog == vto.codlog)
            return _logradouro ? lines + `<tr><td>${_logradouro.Logradouro}</td><td>${vto.valor}</td></tr>`: lines + `<tr><td>${vto.codlog}</td><td>${vto.valor}</td></tr>`
          }, '')
          content += `<div class="infoBlock">
            <h4>Valor de Terreno para Outorga</h4>
            <table>
              <tr><th>Logradouro</th><th>Valor</th></tr>
              ${vtoTableLines}
            </table>
          </div>`
        }
        content += `<div class="infoButtons">
          <div class="buttonOption">
            <button id="infoWindow_abrirLotes">Abrir Lotes</button>
            <div>
              <input type="checkbox" id="infoWindow_remove" name="infoWindow_remove" checked>
              <label for="infoWindow_remove">Remover Quadra</label>
            </div>
          </div>
          <button id="infoWindow_mostrarZoneamento">Mostrar Zoneamento</button>
          <button id="infoWindow_cadastrarNaQuadra">+ ${custom.cadastrarNaQuadra.text}</button>
          <button id="infoWindow_transacoes_quadra" sq="${registerValues.Setor}${registerValues.Quadra}">Transações da Quadra</button>
        </div>`
        return content
      },
      events: [
        {
          obj: '#infoWindow_cadastrarNaQuadra',
          event: 'onclick',
          action: async (params) => {
            params.infoWindow.close()
            const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: custom.cadastrarNaQuadra.dataId })
            const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder, { connections: 1 })
            let register = {}
            register.cidade = '613f7b32cec3521dccd34bc9' // São Paulo
            register.distrito = js_functions.getConnectionIdByDataId(registerValues.connections, 'distritos', 0)
            register.quadra = params.builder.register._id
            register.poligono = params.builder.register.Poligono
            register.poligonoArea = ''
            register.operacaoUrbana = ''
            register.link_operacaoUrbana = ''
            const operacaoUrbanaId = js_functions.getConnectionIdByDataId(registerValues.connections, 'operacoes_urbanas', 'first')
            if (operacaoUrbanaId) {
              const operacaoUrbana = await this.getOperacaoUrbana(params.builder.Map, operacaoUrbanaId)
              register.operacaoUrbana = operacaoUrbana._id
              register.link_operacaoUrbana = operacaoUrbana.nome
            }
            register.zoneamentos = []
            const zoneamentoIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'lpuos2016', 'all')
            if (zoneamentoIds) {
              zoneamentoIds.map(async zoneamentoId => {
                const zoneamento = await this.getZoneamento(params.builder.Map, zoneamentoId)
                register.zoneamentos.push(zoneamento)
              })
            }
            register.macroAreas = []
            const macroAreasIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'macro_areas', 'all')
            if (macroAreasIds) {
              const macroAreas = await this.getMacroAreas(params.builder.Map, { _id: { $in: macroAreasIds } }, { nome: 1, setor: 1, FpR: 1, FpnR: 1 })
              macroAreas.map(macroArea => {
                register.macroAreas.push({ macroArea: macroArea._id, link_macroArea: macroArea.nome, FpR: macroArea.FpR, FpnR: macroArea.FpnR })
              })
            }
            register.cartorios = []
            const cartoriosIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'cartorios', 'all')
            if (cartoriosIds) {
              const cartorios = await this.getCartorios(params.builder.Map, cartoriosIds)
              cartorios.map(cartorio => {
                register.cartorios.push({ cartorio: cartorio._id, link_cartorio: cartorio.subdistrito, numero: cartorio.numero })
              })
            }
            params.builder.Map.$store.commit("showOverlay")
            params.builder.Map.$store.commit('changeKeepRegister', true)
            params.builder.Map.$store.commit('changeRegister', register)
            params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 0 } })
              .then(()=>{ params.builder.Map.$store.commit("hideOverlay") })
              .catch(()=>{ params.builder.Map.$store.commit("hideOverlay") })
          }
        },
        {
          obj: '#infoWindow_abrirLotes',
          event: 'onclick',
          action: async (params) => {
            const remove = document.getElementById('infoWindow_remove').checked
            if (remove) {
              const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder, { qd_tipo: 1, Setor: 1, Quadra: 1, connections: 1, Poligono: 1 })
              params.object.setMap(null)
              params.builder.Map.functions.removeFromAllMapArrays(params.builder.Map, 'quadras', params.object)
              await this.insertLotes(Map, registerValues, params.object)
            }
            params.infoWindow.close()
          }
        },
        {
          obj: '#infoWindow_mostrarZoneamento',
          event: 'onclick',
          action: async (params) => {
            const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder, { connections: 1 })
            const zoneamentoIds = js_functions.getConnectionIdByDataId(registerValues.connections, 'lpuos2016', 'all')
            if (zoneamentoIds != false) {
              const zoneamentos = await this.getZoneamentos(params.builder.Map, zoneamentoIds, { nome: 1 })
              if (zoneamentos.length > 0) {
                zoneamentos.map(zoneamento => this.insertLPUOS2016(params.builder.Map, zoneamento.nome))
              }
            }
          }
        },
        {
          obj: '#infoWindow_transacoes_quadra',
          event: 'onclick',
          action: async (params) => {
            const project = {
              "N° do Cadastro (SQL)": 1,
              "Nome do Logradouro": 1,
              "Número": 1,
              "Complemento": 1,
              "Bairro": 1,
              "CEP": 1,
              "Natureza de Transação": 1,
              "Valor de Transação (declarado pelo contribuinte)": 1,
              "Data de Transação": 1,
              "Valor Venal de Referência": 1,
              "Proporção Transmitida (%)": 1,
              "Valor Venal de Referência (proporcional)": 1,
              "Base de Cálculo adotada": 1,
              "Tipo de Financiamento": 1,
              "Valor Financiado": 1,
              "Cartório de Registro": 1,
              "Matrícula do Imóvel": 1,
              "Situação do SQL": 1,
              "Área do Terreno (m2)": 1,
              "Testada (m)": 1,
              "Fração Ideal": 1,
              "Área Construída (m2)": 1,
              "Uso (IPTU)": 1,
              "Descrição do uso (IPTU)": 1,
              "Padrão (IPTU)": 1,
              "Descrição do padrão (IPTU)": 1,
              "ACC (IPTU)": 1
            }
            const itbi = await this.getITBI({ Map: params.builder.Map, query: { sq: params.tag.getAttribute('sq') }, project })
            this.showTransacoesMarkers({ Map: params.builder.Map, itbi })
            params.infoWindow.close()
          }
        }
      ]
    })
  }
  this.insertLotes = async function(Map, quadra, quadraObject) {
    const custom = this.getCustom(Map, "lotes")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'lotes',
      label: 'Lotes',
      company: 'public',
      module: 'data',
      dataId: 'lotes',
      action: 'read/many',
      body: { "query": { tipoQuadra: quadra.qd_tipo, setor: quadra.Setor, quadra: quadra.Quadra }, "project": { poligono: 1, setor: 1, quadra: 1, lote: 1, condominio: 1 } },
      property: 'poligono',
      func: (registers) => registers.filter(register => Map.functions.isPolygonInsidePolygon(register.poligono[0], quadraObject, { minInside: 1 }, true)),
      cache: { 'name': 'maps/db/polygons', 'url': `public/lotes.json/setor=${quadra.Setor}/quadra=${quadra.Quadra}` },
      options: (builder) => { return !builder || { fillColor: "#CCCCCC", fillOpacity: 0.2, strokeColor: "#666666", strokeOpacity: 0.8, strokeWeight: 0.4, zIndex: 1 } },
      info: async (builder) => await this.lotes({ quadra, custom }).info(builder),
      events: this.lotes({ quadra, custom }).events
    })
  }
  this.lotes = ({ quadra, custom }) => { return {
    info: async (builder) => {
      const _setor = builder.register.Setor || builder.register.setor
      const _quadra = builder.register.Quadra || builder.register.quadra
      const _lote = builder.register.Lote || builder.register.lote
      const _condominio = builder.register.condominio
      if (!builder) { return true }
      let content = `<h3 class="infowindowTitle">Lote</h3><h3>${_lote}</h3>`
      content += `<div class="infoBlock">
        <h4>Geral</h4>
          <ul>
            <li><label>Setor.Quadra:</label> ${_setor}.${_quadra}</li>
            <li><label>Condomínio:</label> ${_condominio}</li>
          </ul>
      </div>`
      const transacoes_lote = _lote != '0000' ? `<button id="infoWindow_transacoes_lote" sql="${_setor}${_quadra}${_lote}">Transações do Lote</button>` : ''
      content += `<div class="infoButtons">
        <button id="infoWindow_cadastrarNosLotes">+ ${custom.cadastrarNosLotes.text}</button>
        <button id="infoWindow_informacoes">Informações</button>
        <button id="infoWindow_transacoes_quadra" sq="${_setor}${_quadra}">Transações da Quadra</button>
        ${transacoes_lote}
      </div>`
      return content
    },
    events: [
      {
        obj: '#infoWindow_cadastrarNosLotes',
        event: 'onclick',
        action: async (params) => {
          const _setor = params.builder.register.Setor || params.builder.register.setor
          const _quadra = params.builder.register.Quadra || params.builder.register.quadra
          quadra = quadra || this.getQuadra({ Map: params.builder.Map, query: { setor: _setor, quadra: _quadra }, project: '' })
          params.infoWindow.close()
          const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: custom.cadastrarNosLotes.dataId })
          if (!params.builder.Map.selected.lotes || !params.builder.Map.selected.lotes.find(lote => lote == params.object)) {
            params.builder.Map.functions.addToMapArray({ builder: params.builder, array: 'selected', item: params.object })
            params.object.setOptions({ strokeColor: '#00CC00', strokeWeight: 2, strokeOpacity: 1 })
            params.builder.Map.$refs.ToolBar.multiSelect.active = true
            params.builder.Map.$refs.ToolBar.mouseReverse.active = true
          }
          params.builder.Map.$store.commit("showBottomSheet", {
            title: 'Selecionar Lotes',
            text: 'Selecione os lotes que deseja usar no cadastro do terreno.',
            buttonYes: 'Cadastrar terreno',
            buttonNo: 'Fechar',
            actionNo: () => {
              params.builder.Map.selected.lotes.map(lote => {
                lote.setOptions({ strokeColor: "#666666", strokeOpacity: 0.8, strokeWeight: 0.4 })
                params.builder.Map.functions.removeFromMapArray(params.builder.Map, 'selected', 'lotes', lote)
              })
              params.builder.Map.$refs.ToolBar.multiSelect.active = false
              params.builder.Map.$refs.ToolBar.mouseReverse.active = false
            },
            action: async () => {
              params.builder.Map.$store.commit("showOverlays", '2')
              let register = {}
              register.cidade = '613f7b32cec3521dccd34bc9' // São Paulo
              register.distrito = js_functions.getConnectionIdByDataId(quadra.connections, 'distritos', 0)
              register.quadra = quadra._id
              register.operacaoUrbana = ''
              register.link_operacaoUrbana = ''
              const operacaoUrbanaId = js_functions.getConnectionIdByDataId(quadra.connections, 'operacoes_urbanas', 'first')
              if (operacaoUrbanaId) {
                const operacaoUrbana = await this.getOperacaoUrbana(params.builder.Map, operacaoUrbanaId)
                register.operacaoUrbana = operacaoUrbana._id
                register.link_operacaoUrbana = operacaoUrbana.nome
              }
              register.zoneamentos = []
              const zoneamentoIds = js_functions.getConnectionIdByDataId(quadra.connections, 'lpuos2016', 'all')
              if (zoneamentoIds) {
                zoneamentoIds.map(async zoneamentoId => {
                  const zoneamento = await this.getZoneamento(params.builder.Map, zoneamentoId)
                  register.zoneamentos.push(zoneamento)
                })
              }
              register.macroAreas = []
              const macroAreasIds = js_functions.getConnectionIdByDataId(quadra.connections, 'macro_areas', 'all')
              if (macroAreasIds) {
                const macroAreas = await this.getMacroAreas(params.builder.Map, { _id: { $in: macroAreasIds } }, { nome: 1, setor: 1, FpR: 1, FpnR: 1 })
                macroAreas.map(macroArea => {
                  register.macroAreas.push({ macroArea: macroArea._id, link_macroArea: macroArea.nome, FpR: macroArea.FpR, FpnR: macroArea.FpnR })
                })
              }
              register.cartorios = []
              const cartoriosIds = js_functions.getConnectionIdByDataId(quadra.connections, 'cartorios', 'all')
              if (cartoriosIds) {
                const cartorios = await this.getCartorios(params.builder.Map, cartoriosIds)
                cartorios.map(cartorio => {
                  register.cartorios.push({ cartorio: cartorio._id, link_cartorio: cartorio.subdistrito, numero: cartorio.numero })
                })
              }
              const project = {
                "NUMERO DO CONTRIBUINTE": 1,
                "AREA DO TERRENO": 1,
                "CODLOG DO IMOVEL": 1,
                "NUMERO DO CONDOMINIO": 1,
                "TESTADA PARA CALCULO": 1,
                "NOME DE LOGRADOURO DO IMOVEL": 1
              }
              const iptu = await (new iptus()).getIPTU({ project, info: { setor: quadra.Setor, quadra: quadra.Quadra } })
              const vtoDataId = (new vtos()).lastDataId
              const vto = (await js_functions.getFunc({ FormLines: params.builder.Map, func: () => getGenericRegisters({ backend: params.builder.Map.backend, company: 'public', module: 'data', dataId: vtoDataId, query: { sq: { $in: [`${quadra.Setor}${quadra.Quadra}`] } }, project: { sq: 1, codlog: 1, valor: 1 } }), returnData: true })).data
              register.lotes = []
              let polygon = []
              const lotesTemp = []
              params.builder.Map.selected.lotes.map(lote => {
                const _loteId = lote.builder.register._id
                const _lote = lote.builder.register.lote
                const condominio = lote.builder.register.condominio
                polygon.push({ info: { Lote: _lote }, poligono: lote.builder.register.poligono[0] })
                const { digito, areaIPTU, contribuinte, codlog, testada, logradouro, vtoValor } = this.extractIPTUInfo({ iptu, vto, setor: quadra.Setor, quadra: quadra.Quadra, lote: _lote, condominio })
                register.lotes.push({ lote: _loteId, link_lote: _lote, digito, areaIPTU, contribuinte, codlog, testada, valorOutorga: vtoValor })
                lotesTemp.push({ logradouro, codlog, testada })
              })
              register.testadas = []
              const sumTestadas = (codlog) => js_functions.numberOut(lotesTemp.reduce((sum, lote) => sum += lote.codlog == codlog ? js_functions.numberIn(lote.testada) : 0, 0), 2, "0,00")
              js_functions.executeIfUniq({ array: lotesTemp, prop: "codlog", func: (lote) => { register.testadas.push({ logradouro: lote.logradouro, codlog: lote.codlog, testada: sumTestadas(lote.codlog) }) } })
              register.poligono = polygon
              params.builder.Map.$store.commit('changeKeepRegister', true)
              params.builder.Map.$store.commit('changeRegister', register)
              params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 0 } })
                .then(()=>{ params.builder.Map.$store.commit("hideOverlays", '2') })
                .catch(()=>{ params.builder.Map.$store.commit("hideOverlays", '2') })
            }
          })
        }
      },
      {
        obj: '#infoWindow_informacoes',
        event: 'onclick',
        action: async (params) => {
          params.builder.Map.$store.commit("showOverlays", "2")
          const event = { latLng: params.infoWindow.getPosition() }
          params.infoWindow.close()
          const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder, { lote: 1, setor: 1, quadra: 1, condominio: 1 })
          const project = {
            "COMPLEMENTO DO IMOVEL": 1,
            "AREA CONSTRUIDA": 1,
            "NOME DO CONTRIBUINTE 1": 1,
            "CPF/CNPJ DO CONTRIBUINTE 1": 1,
            "NUMERO DO CONTRIBUINTE": 1,
            "REFERENCIA DO IMOVEL": 1,
            "TIPO DE USO DO IMOVEL": 1,
            "TIPO DE PADRAO DA CONSTRUCAO": 1,
            "ANO DA CONSTRUCAO CORRIGIDO": 1,
            "NOME DE LOGRADOURO DO IMOVEL": 1,
            "NUMERO DO IMOVEL": 1,
            "CEP DO IMOVEL": 1,
            "AREA DO TERRENO": 1,
            "AREA OCUPADA": 1,
            "QUANTIDADE DE PAVIMENTOS": 1,
            "TESTADA PARA CALCULO": 1,
            "VALOR DO M2 DO TERRENO": 1
          }
          const iptu = await (new iptus()).getIPTU({ backend: params.builder.Map.backend, project, info: registerValues })
          const builder = {
            Map: params.builder.Map,
            info: async (builder) => {
              if (!builder) { return true }
              if (iptu.length > 0) {
                if (iptu.length == 1) {
                  const keys = Object.keys(iptu[0])
                  const ajustKey = (key) => key == 'NOME DO CONTRIBUINTE 1' || key == 'CPF/CNPJ DO CONTRIBUINTE 1' ? `${key} (2020)` : key
                  const lis = keys.reduce((items, key) => items += key != '_id' ? `<li><label>${ajustKey(key)}:</label> ${iptu[0][key]}</li>` : '', '')
                  const buttons = `<div class="infoButtons">
                    <button class="infoWindow_mesmo_propietario" nome="${iptu[0]['NOME DO CONTRIBUINTE 1'] ? iptu[0]['NOME DO CONTRIBUINTE 1'] : ''}" documento="${iptu[0]['CPF/CNPJ DO CONTRIBUINTE 1'] ? iptu[0]['CPF/CNPJ DO CONTRIBUINTE 1'] : ''}">Mostrar imóveis do mesmo propietário (2020)</button>
                  </div>`
                  return `<div class="infoBlock"><ul>${lis}</ul></div>${buttons}`
                }
                else {
                  const trs = iptu.reduce((imoveis, imovel) => imoveis += `<tr>
                    <td>${imovel["COMPLEMENTO DO IMOVEL"] ? imovel["COMPLEMENTO DO IMOVEL"] : '-'}</td>
                    <td>${imovel["AREA CONSTRUIDA"] ? imovel["AREA CONSTRUIDA"] : '-'}</td>
                    <td>${imovel["NOME DO CONTRIBUINTE 1"] ? imovel["NOME DO CONTRIBUINTE 1"] : '-'}</td>
                    <td>${imovel["CPF/CNPJ DO CONTRIBUINTE 1"] ? imovel["CPF/CNPJ DO CONTRIBUINTE 1"] : '-'}</td>
                    <td>${imovel["NUMERO DO CONTRIBUINTE"] ? imovel["NUMERO DO CONTRIBUINTE"] : '-'}</td>
                    <td><a class="infoWindow_mesmo_propietario" nome="${imovel["NOME DO CONTRIBUINTE 1"] ? imovel["NOME DO CONTRIBUINTE 1"] : ''}" documento="${imovel["CPF/CNPJ DO CONTRIBUINTE 1"] ? imovel["CPF/CNPJ DO CONTRIBUINTE 1"] : ''}">Imóveis (2020)</a></td>
                    <td><a class="infoWindow_transacoes" sql="${imovel["NUMERO DO CONTRIBUINTE"] ? imovel["NUMERO DO CONTRIBUINTE"].split('-')[0] : ''}">Transações</a></td>
                  </tr>`, '')
                  return `<div class="infoBlock">
                    <h4>Informações Gerais</h4>
                    <ul>
                      <li><label>REFERENCIA DO IMOVEL:</label> ${iptu[0]["REFERENCIA DO IMOVEL"] ? iptu[0]["REFERENCIA DO IMOVEL"] : '-'}</li>
                      <li><label>TIPO DE USO DO IMOVEL:</label> ${iptu[0]["TIPO DE USO DO IMOVEL"] ? iptu[0]["TIPO DE USO DO IMOVEL"] : '-'}</li>
                      <li><label>TIPO DE PADRAO DA CONSTRUCAO:</label> ${iptu[0]["TIPO DE PADRAO DA CONSTRUCAO"] ? iptu[0]["TIPO DE PADRAO DA CONSTRUCAO"] : '-'}</li>
                      <li><label>ANO DA CONSTRUCAO CORRIGIDO:</label> ${iptu[0]["ANO DA CONSTRUCAO CORRIGIDO"] ? iptu[0]["ANO DA CONSTRUCAO CORRIGIDO"] : '-'}</li>
                      <li><label>NOME DE LOGRADOURO DO IMOVEL:</label> ${iptu[0]["NOME DE LOGRADOURO DO IMOVEL"] ? iptu[0]["NOME DE LOGRADOURO DO IMOVEL"] : '-'}</li>
                      <li><label>NUMERO DO IMOVEL:</label> ${iptu[0]["NUMERO DO IMOVEL"] ? iptu[0]["NUMERO DO IMOVEL"] : '-'}</li>
                      <li><label>CEP DO IMOVEL:</label> ${iptu[0]["CEP DO IMOVEL"] ? iptu[0]["CEP DO IMOVEL"] : '-'}</li>
                      <li><label>AREA DO TERRENO:</label> ${iptu[0]["AREA DO TERRENO"] ? iptu[0]["AREA DO TERRENO"] : '-'}</li>
                      <li><label>AREA OCUPADA:</label> ${iptu[0]["AREA OCUPADA"] ? iptu[0]["AREA OCUPADA"] : '-'}</li>
                      <li><label>QUANTIDADE DE PAVIMENTOS:</label> ${iptu[0]["QUANTIDADE DE PAVIMENTOS"] ? iptu[0]["QUANTIDADE DE PAVIMENTOS"] : '-'}</li>
                      <li><label>TESTADA PARA CALCULO:</label> ${iptu[0]["TESTADA PARA CALCULO"] ? iptu[0]["TESTADA PARA CALCULO"] : '-'}</li>
                      <li><label>VALOR DO M2 DO TERRENO:</label> ${iptu[0]["VALOR DO M2 DO TERRENO"] ? iptu[0]["VALOR DO M2 DO TERRENO"] : '-'}</li>
                    </ul>
                  </div>
                  <div class="infoBlock">
                    <h4>Imóveis</h4>
                    <table>
                      <tr><th>Imóvel</th><th>Área</th><th>Nome (2020)</th><th>Documento (2020)</th><th>Contribuinte</th><th></th><th></th></tr>
                      ${trs}
                    </table>
                  </div>`
                }
              }
              else { return '<div class="infoBlock">Nenhuma informação encontrada!</div>' }
            },
            events: [
              {
                obj: '.infoWindow_mesmo_propietario',
                event: 'onclick',
                action: async (params) => {
                  this.showImoveisMesmoProprietario({ Map: params.builder.Map, params, nome: params.tag.getAttribute('nome'), documento: params.tag.getAttribute('documento') })
                }
              },
              {
                obj: 'a.infoWindow_transacoes',
                event: 'onclick',
                action: async (params) => {
                  this.showTransacoes({ Map: params.builder.Map, params, sql: params.tag.getAttribute('sql') })
                }
              }
            ]
          }
          params.builder.Map.functions.openInfoWindow(builder, event, params.object)
          params.builder.Map.$store.commit("hideOverlays", "2")
        }
      },
      {
        obj: '#infoWindow_transacoes_quadra',
        event: 'onclick',
        action: async (params) => {
          this.showTransacoes({ Map: params.builder.Map, params, sq: params.tag.getAttribute('sq') })
        }
      },
      {
        obj: '#infoWindow_transacoes_lote',
        event: 'onclick',
        action: async (params) => {
          this.showTransacoes({ Map: params.builder.Map, params, sql: params.tag.getAttribute('sql') })
        }
      }
    ]
  } }
  this.showImoveisMesmoProprietario = async function({ Map, params, nome, documento }) {
    params.builder.Map.$store.commit("showOverlay")
    const project = { ['NUMERO DO CONTRIBUINTE']: 1, ['NUMERO DO CONDOMINIO']: 1 }
    const iptusSameOwner = await this.getIPTUsByOwner({ Map, nome, documento, project })
    const sqlc = iptusSameOwner.map(iptuSameOwner => `${iptuSameOwner['NUMERO DO CONTRIBUINTE'].split('-')[0]}${iptuSameOwner['NUMERO DO CONDOMINIO'].split('-')[0]}`)
    await params.builder.Map.functions.public.insertLotesBySqlc(Map, sqlc)
    params.builder.Map.$store.commit("hideOverlay")
  }
  this.terrenos_descricoes = ["TERRENO", "RESIDENCIAL HORIZONTAL"]
  this.apartamentos_descricoes = ["RESIDENCIAL VERTICAL", "APARTAMENTO EM CONDOMÍNIO (EXIGE FRAÇÃO IDEAL)"]
  this.showTransacoes = async function({ Map, params, sql, sq }) {
    params.builder.Map.$store.commit("showOverlays", "2")
    const event = { latLng: params.infoWindow.getPosition() }
    params.infoWindow.close()
    const project = {
      "N° do Cadastro (SQL)": 1,
      "Nome do Logradouro": 1,
      "Número": 1,
      "Complemento": 1,
      "Bairro": 1,
      "CEP": 1,
      "Natureza de Transação": 1,
      "Valor de Transação (declarado pelo contribuinte)": 1,
      "Data de Transação": 1,
      "Valor Venal de Referência": 1,
      "Proporção Transmitida (%)": 1,
      "Valor Venal de Referência (proporcional)": 1,
      "Base de Cálculo adotada": 1,
      "Tipo de Financiamento": 1,
      "Valor Financiado": 1,
      "Cartório de Registro": 1,
      "Matrícula do Imóvel": 1,
      "Situação do SQL": 1,
      "Área do Terreno (m2)": 1,
      "Testada (m)": 1,
      "Fração Ideal": 1,
      "Área Construída (m2)": 1,
      "Uso (IPTU)": 1,
      "Descrição do uso (IPTU)": 1,
      "Padrão (IPTU)": 1,
      "Descrição do padrão (IPTU)": 1,
      "ACC (IPTU)": 1
    }
    const itbi = await this.getITBI({ Map, query: { ...(sq && { sq }), ...(sql && { sql }) }, project })
    const builder = {
      Map: Map,
      info: async (builder) => {
        if (!builder) { return true }
        if (itbi.length > 0) {
          const trs = itbi.reduce((transacoes, transacao) => transacoes += `<tr>
            <td>${transacao["Data de Transação"] ? transacao["Data de Transação"] : '-'}</td>
            <td>${transacao["Complemento"] ? transacao["Complemento"] : '-'}</td>
            <td>${transacao["Matrícula do Imóvel"] ? transacao["Matrícula do Imóvel"] : '-'}</td>
            <td>${transacao["Natureza de Transação"] ? transacao["Natureza de Transação"] : '-'}</td>
            <td>${transacao["Proporção Transmitida (%)"] ? transacao["Proporção Transmitida (%)"] : '-'}</td>
            <td>${transacao["Valor de Transação (declarado pelo contribuinte)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Valor de Transação (declarado pelo contribuinte)"])) : '-'}</td>
            <td>
              ${this.terrenos_descricoes.includes(transacao["Descrição do uso (IPTU)"]) && transacao["Área do Terreno (m2)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Área do Terreno (m2)"])) : ''}
              ${this.apartamentos_descricoes.includes(transacao["Descrição do uso (IPTU)"]) && transacao["Área Construída (m2)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Área Construída (m2)"])) : ''}
            </td>
            <td><a class="infoWindow_transacao_detalhes" id="${transacao._id}">Detalhes</a></td>
          </tr>`, '')
          const header = sql ? `
            <h3><label>Descrição do uso (IPTU):</label> ${itbi[0]["Descrição do uso (IPTU)"] ? itbi[0]["Descrição do uso (IPTU)"] : '-'}</h3>
            <h3><label>Situação do SQL:</label> ${itbi[0]["Situação do SQL"] ? itbi[0]["Situação do SQL"] : '-'}</h3>`
            : ''
          return `<div class="infoBlock">
            <h4>Transações</h4>
            ${header}
          </div>
          <div class="infoBlock">
            <table>
              <tr><th>Data</th><th>Complemento</th><th>Matrícula do Imóvel</th><th>Natureza</th><th>Proporção (%)</th><th>Valor</th><th>Área*</th><th></th></tr>
              ${trs}
            </table>
          </div>`
        }
        else { return '<div class="infoBlock">Nenhuma informação encontrada!</div>' }
      },
      events: [
        {
          obj: 'a.infoWindow_transacao_detalhes',
          event: 'onclick',
          action: async (params) => {
            this.showTransacoesDetalhes({ Map, params, _id: params.tag.getAttribute('id') })
          }
        }
      ]
    }
    params.builder.Map.functions.openInfoWindow(builder, event, params.object)
    params.builder.Map.$store.commit("hideOverlays", "2")
  }
  this.showTransacoesTable = function({ itbi }) {
    const trs = itbi.reduce((transacoes, transacao) => transacoes += `<tr>
      <td>${transacao["N° do Cadastro (SQL)"] ? transacao["N° do Cadastro (SQL)"].substr(-5, 4) : '-'}</td>
      <td>${transacao["Situação do SQL"] ? transacao["Situação do SQL"] : '-'}</td>
      <td>${transacao["Data de Transação"] ? transacao["Data de Transação"] : '-'}</td>
      <td>${transacao["Complemento"] ? transacao["Complemento"] : '-'}</td>
      <td>${transacao["Matrícula do Imóvel"] ? transacao["Matrícula do Imóvel"] : '-'}</td>
      <td>${transacao["Natureza de Transação"] ? transacao["Natureza de Transação"] : '-'}</td>
      <td>${transacao["Proporção Transmitida (%)"] ? transacao["Proporção Transmitida (%)"] : '-'}</td>
      <td>${transacao["Valor de Transação (declarado pelo contribuinte)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Valor de Transação (declarado pelo contribuinte)"])) : '-'}</td>
      <td>
        ${this.terrenos_descricoes.includes(transacao["Descrição do uso (IPTU)"]) && transacao["Área do Terreno (m2)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Área do Terreno (m2)"])) : ''}
        ${this.apartamentos_descricoes.includes(transacao["Descrição do uso (IPTU)"]) && transacao["Área Construída (m2)"] ? js_functions.numberOut(js_functions.numberIn(transacao["Área Construída (m2)"])) : ''}
      </td>
      <td><a class="infoWindow_transacao_detalhes" id="${transacao._id}">Detalhes</a></td>
    </tr>`, '')
    return `<div class="infoBlock">
      <h4>Transações</h4>
    </div>
    <div class="infoBlock">
      <table>
        <tr><th>Lote</th><th>Situação</th><th>Data</th><th>Complemento</th><th>Matrícula do Imóvel</th><th>Natureza</th><th>Proporção (%)</th><th>Valor</th><th>Área</th><th></th></tr>
        ${trs}
      </table>
    </div>`
  }
  this.showTransacoesMarkers = async function({ Map, itbi }) {
    Map.$store.commit("showOverlay")
    const itbiWithAddresses = await Promise.all(itbi.map(async transacao => {
      const coords = await Map.functions.convertAddressToCoords(Map, transacao["Nome do Logradouro"] + ', ' + transacao["Número"])
      return { ...transacao, address: coords["formatted_address"], coords } }))
    const points = itbiWithAddresses.removeDuplicatesObjectsProp('address')
    points.map(async point => {
      const transacoes = itbiWithAddresses.filter(transacao => transacao.address == point.address)
      !point.coords.geometry || Map.functions.insertMapObject({
        Map: Map,
        type: 'Marker',
        coords: [{ lat: point.coords.geometry.location.lat(), lng:  point.coords.geometry.location.lng() }],
        options: (builder) => !builder || { label: transacoes.length.toString() },
        info: (builder) => {
          if (!builder) { return true }
          return `
            <div class="infoBlock">
              <ul>
                <li><label>Endereço:</label> ${point.address}</li>
              </ul>
            </div>
            ${this.showTransacoesTable({ itbi: transacoes })}`
        },
        events: [
          {
            obj: 'a.infoWindow_transacao_detalhes',
            event: 'onclick',
            action: async (params) => {
              this.showTransacoesDetalhes({ Map, params, _id: params.tag.getAttribute('id') })
            }
          }
        ]
      })
    })
    Map.$store.commit("hideOverlay")
  }
  this.showTransacoesDetalhes = async function({ Map, params, _id }) {
    params.builder.Map.$store.commit("showOverlays", "2")
    const event = { latLng: params.infoWindow.getPosition() }
    params.infoWindow.close()
    const project = {
      "N° do Cadastro (SQL)": 1,
      "Nome do Logradouro": 1,
      "Número": 1,
      "Complemento": 1,
      "Bairro": 1,
      "CEP": 1,
      "Natureza de Transação": 1,
      "Valor de Transação (declarado pelo contribuinte)": 1,
      "Data de Transação": 1,
      "Valor Venal de Referência": 1,
      "Proporção Transmitida (%)": 1,
      "Valor Venal de Referência (proporcional)": 1,
      "Base de Cálculo adotada": 1,
      "Tipo de Financiamento": 1,
      "Valor Financiado": 1,
      "Cartório de Registro": 1,
      "Matrícula do Imóvel": 1,
      "Situação do SQL": 1,
      "Área do Terreno (m2)": 1,
      "Testada (m)": 1,
      "Fração Ideal": 1,
      "Área Construída (m2)": 1,
      "Uso (IPTU)": 1,
      "Descrição do uso (IPTU)": 1,
      "Padrão (IPTU)": 1,
      "Descrição do padrão (IPTU)": 1,
      "ACC (IPTU)": 1
    }
    const itbi = await this.getITBI({ Map, query: { _id }, project })
    const builder = {
      Map: Map,
      info: async (builder) => {
        if (!builder) { return true }
        if (itbi.length > 0) {
          const keys = Object.keys(itbi[0])
          const lis = keys.reduce((items, key) => items += key != '_id' ? `<li><label>${key}:</label> ${itbi[0][key]}</li>` : '', '')
          return `<div class="infoBlock"><ul>${lis}</ul></div>`
        }
        else { return '<div class="infoBlock">Nenhuma informação encontrada!</div>' }
      }
    }
    params.builder.Map.functions.openInfoWindow(builder, event, params.object)
    params.builder.Map.$store.commit("hideOverlays", "2")
  }
  this.insertLotesBySqlc = async function(Map, sqlc) {
    Map.$store.commit("showOverlay")
    const custom = this.getCustom(Map, "lotes")
    const lotesCondominios = sqlc.filter(_sqlc => !_sqlc.endsWith('00')).map(_sqlc => `${_sqlc.substr(0, 6)}0000${_sqlc.substr(10, 2)}`).removeDuplicates()
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      extraMarker: true,
      name: 'lotes',
      label: 'Lotes',
      company: 'public',
      module: 'data',
      dataId: 'lotes',
      action: 'read/many',
      body: { "query": { sqlc: { $in: [...sqlc, ...lotesCondominios] } }, "project": { poligono: 1, setor: 1, quadra: 1, lote: 1, condominio: 1 } },
      property: 'poligono',
      // func: (registers) => registers.filter(register => Map.functions.isPolygonInsidePolygon(register.poligono[0], quadraObject, { minInside: 1 }, true)),
      cache: { 'name': 'maps/db/polygons', 'url': `public/lotes.json/sqlc=${sqlc.join(';')}` },
      options: (builder) => { return !builder || { fillColor: "#FF0000", fillOpacity: 0.6, strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 0.4, zIndex: 100 } },
      info: async (builder) => await this.lotes({ custom }).info(builder),
      events: this.lotes({ custom }).events
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertMarkersByAddresses = function({ Map, addresses }) {
    Map.$store.commit("showOverlay")
    addresses.map(async address => {
      const result = await Map.functions.convertAddressToCoords(Map, address.address)
      Map.functions.insertMapObject({
        Map: Map,
        type: 'Marker',
        coords: [{ lat: result.geometry.location.lat(), lng:  result.geometry.location.lng() }],
        options: (builder) => !builder || {},
        info: (builder) => {
          if (!builder) { return true }
          return `<h3 class="infowindowTitle">${address.titleMarker || "-"}</h3>
            <div class="infoBlock">
              <ul>
                <li><label>Endereço:</label> ${result.formatted_address || "-"}</li>
              </ul>
            </div>`
        }
      })
    })
    Map.$store.commit("hideOverlay")
  }
  this.extractIPTUInfo = function({ iptu, vto, setor, quadra, lote, condominio }) {
    const _iptu = (lote != '0000')
      ? iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}${lote}-`))
      : iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}`) && register["NUMERO DO CONDOMINIO"].startsWith(`${condominio}-`))
    const digito = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
    const areaIPTU = (_iptu && _iptu["AREA DO TERRENO"]) ? `${_iptu["AREA DO TERRENO"]},00` : ''
    const contribuinte = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
    const codlog = (_iptu && _iptu["CODLOG DO IMOVEL"]) ? _iptu["CODLOG DO IMOVEL"] : ''
    const testada = (_iptu && _iptu["TESTADA PARA CALCULO"]) ? _iptu["TESTADA PARA CALCULO"] : ''
    const logradouro = (_iptu && _iptu["NOME DE LOGRADOURO DO IMOVEL"]) ? _iptu["NOME DE LOGRADOURO DO IMOVEL"] : ''
    const _vto = vto?.find(register => register.codlog == codlog.replace('-', ''))
    const vtoValor = (_vto && _vto.valor) ? _vto.valor : ''
    return { digito, areaIPTU, contribuinte, codlog, testada, logradouro, vtoValor }
  }
  this.insertOperacoesUrbanas = async function(Map, sigla) {
    if (Map.functions.isOnMap(Map, 'operacoes_urbanas', 'sigla', sigla)) return
    Map.$store.commit("showOverlay")
    const custom = this.getCustom(Map, "operacoes_urbanas")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'operacoes_urbanas',
      label: 'Operações Urbanas',
      company: 'public',
      module: 'data',
      dataId: 'operacoes_urbanas',
      action: 'read/many',
      body: { "query": { sigla: sigla }, "project": { nome: 1, poligono: 1, cor: 1, sigla: 1 } },
      property: 'poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/operacoes_urbanas.json/sigla=${sigla}` },
      options: (builder) => { return !builder || { fillColor: builder.register.cor, fillOpacity: 0.5, strokeColor: builder.register.cor, strokeOpacity: 0.8, strokeWeight: 3, zIndex: 6 }},
      info: async (builder) => {
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { nome: 1 })
        const detalhes = custom.detalhes.show ? '<button id="infoWindow_detalhes">Detalhes</button>' : ''
        return `<h3 class="infowindowTitle">Operação Urbana</h3><h3>${registerValues.nome}</h3>
          <div class="infoButtons">
            <div class="buttonOption">
              <button id="infoWindow_mostrarSetores">Mostrar Setores</button>
              <div>
                <input type="checkbox" id="infoWindow_remove" name="infoWindow_remove" checked>
                <label for="infoWindow_remove">Remover Operação Urbana</label>
              </div>
            </div>
            ${detalhes}
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_detalhes',
          event: 'onclick',
          action: async (params) => {
            const _Map = params.builder.Map
            params.infoWindow.close()
            const menu = await _Map.get_info.getMenuObject({ parent: _Map, hmenu: 'data', dataId: 'operacoes_urbanas' })
            const registerValues = await _Map.functions.getClickedObjectInfo(params.builder)
            _Map.$store.commit("showOverlay")
            _Map.$store.commit('changeKeepRegister', true)
            _Map.$store.commit('changeRegister', registerValues)
            params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 2 } })
              .then(()=>{  _Map.$store.commit("hideOverlay") })
              .catch(()=>{  _Map.$store.commit("hideOverlay") })
          }
        },
        {
          obj: '#infoWindow_mostrarSetores',
          event: 'onclick',
          action: async (params) => {
            Map.$store.commit("showOverlay")
            const remove = document.getElementById('infoWindow_remove').checked
            if (remove) {
              params.object.setMap(null)
              params.builder.Map.functions.removeFromAllMapArrays(params.builder.Map, 'operacoes_urbanas', params.object)
            }
            const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder, { cor: 1, sigla: 1 })
            params.infoWindow.close()
            this.insertOperacoesUrbanasSetores(Map, registerValues)
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertOperacoesUrbanasSetores = async function(Map, operacaoUrbana) {
    if (Map.functions.isOnMap(Map, 'operacoes_urbanas_setores', '_id', operacaoUrbana._id, 'connections')) { return }
    Map.$store.commit("showOverlay")
    const custom = this.getCustom(Map, "operacoes_urbanas_setores")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'operacoes_urbanas_setores',
      label: 'Operações Urbanas Setores',
      company: 'public',
      module: 'data',
      dataId: 'operacoes_urbanas_setores',
      action: 'read/many',
      body: { "query": { "connections._id": operacaoUrbana._id }, "project": { so_nome_ou: 1, poligono: 1, connections: 1 } },
      property: 'poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/operacoes_urbanas_setores.json/sigla=${operacaoUrbana.sigla}` },
      options: (builder) => { return !builder || { fillColor: operacaoUrbana.cor, fillOpacity: 0.5, strokeColor: operacaoUrbana.cor, strokeOpacity: 0.8, strokeWeight: 3, zIndex: 6 }},
      info: async (builder) => {
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { so_nome_ou: 1, so_setor: 1, so_nomesub: 1, so_area: 1, so_lei: 1 })
        const detalhes = custom.detalhes.show ? '<button id="infoWindow_detalhes">Detalhes</button>' : ''
        return `<h3 class="infowindowTitle">Operação Urbana Setor</h3><h3>${registerValues.so_nome_ou} ${registerValues.so_setor}${registerValues.so_nomesub ? ' ' + registerValues.so_nomesub : ''}</h3>
          <div class="infoBlock">
            <h4>Geral</h4>
            <ul>
              <li><label>Subsetor:</label> ${registerValues.so_nomesub}</li>
              <li><label>Área:</label> ${registerValues.so_area}</li>
              <li><label>Lei:</label> ${registerValues.so_lei}</li>
            </ul>
          </div>
          <div class="infoButtons">
            ${detalhes}
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_detalhes',
          event: 'onclick',
          action: async (params) => {
            Map.$store.commit("showOverlay")
            const _Map = params.builder.Map
            params.infoWindow.close()
            const menu = await _Map.get_info.getMenuObject({ parent: _Map, hmenu: 'data', dataId: 'operacoes_urbanas_setores' })
            const registerValues = await _Map.functions.getClickedObjectInfo(params.builder)
            _Map.$store.commit("showOverlay")
            _Map.$store.commit('changeKeepRegister', true)
            _Map.$store.commit('changeRegister', registerValues)
            params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 2 } })
              .then(()=>{  _Map.$store.commit("hideOverlay") })
              .catch(()=>{  _Map.$store.commit("hideOverlay") })
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertMacroAreas = async function(Map, params) {
    const sigla = params.split(';')[0]
    const setor = params.split(';')[1]
    const macroAreas = await this.getMacroAreas(Map, { sigla, setor }, { nome: 1, sigla: 1, areaKM2: 1, FpR: 1, FpnR: 1, cor: 1, setor: 1 })
    const macroArea = macroAreas[0]
    if (Map.functions.isOnMap(Map, 'macro_areas_poligonos', '_id', macroArea._id, 'connections')) { return }
    Map.$store.commit("showOverlay")
    const custom = this.getCustom(Map, "macro_areas_poligonos")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'macro_areas_poligonos',
      label: 'Macro Areas',
      company: 'public',
      module: 'data',
      dataId: 'macro_areas_poligonos',
      action: 'read/many',
      body: { "query": { "connections._id": macroArea._id }, "project": { poligono: 1, connections: 1 } },
      property: 'poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/macro_areas_poligonos.json/sigla=${sigla}/setor=${setor}` },
      options: (builder) => { return !builder || { fillColor: macroArea.cor, fillOpacity: 0.5, strokeColor: macroArea.cor, strokeOpacity: 0.8, strokeWeight: 3, zIndex: 7 }},
      info: async (builder) => {
        if (!builder) { return true }
        const setor = macroArea.setor ? `<h4>Setor: ${macroArea.setor}</li></h4>` : ''
        const detalhes = custom.detalhes.show ? '<button id="infoWindow_detalhes">Detalhes</button>' : ''
        return `<h3 class="infowindowTitle">Macroárea</h3><h3>${macroArea.nome}</h3>
          <div class="infoBlock">
          <ul>
            <li><label>Silga:</label> ${macroArea.sigla}</li>
            <li><label>Área em Km²:</label> ${macroArea.areaKM2}</li>
          </ul>
          ${setor}
          <ul>
              <li><label>FpR:</label> ${macroArea.FpR}</li>
              <li><label>FpnR:</label> ${macroArea.FpnR}</li>
            </ul>
          </div>
          <div class="infoButtons">
            ${detalhes}
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_detalhes',
          event: 'onclick',
          action: async (params) => {
            params.infoWindow.close()
            const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: 'macro_areas_poligonos' })
            const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder)
            params.builder.Map.$store.commit("showOverlay")
            params.builder.Map.$store.commit('changeKeepRegister', true)
            params.builder.Map.$store.commit('changeRegister', registerValues)
            params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 2 } })
              .then(()=>{  params.builder.Map.$store.commit("hideOverlay") })
              .catch(()=>{  params.builder.Map.$store.commit("hideOverlay") })
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertCartorios = async function(Map) {
    if (Map.functions.isOnMap(Map, 'cartorios')) return
    Map.$store.commit("showOverlay")
    const custom = this.getCustom(Map, "cartorios")
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: 'cartorios',
      label: 'Cartórios',
      company: 'public',
      module: 'data',
      dataId: 'cartorios',
      action: 'read/many',
      body: { "project": { Poligono: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/cartorios.json` },
      options: (builder) => { return !builder || { fillColor: '#F4ADBA', fillOpacity: 0.2, strokeColor: '#F4ADBA', strokeOpacity: 0.8, strokeWeight: 3, zIndex: 8 }},
      info: async (builder) => {
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { subdistrito: 1, numero: 1, area: 1, endereco: 1 })
        const detalhes = custom.detalhes.show ? '<button id="infoWindow_detalhes">Detalhes</button>' : ''
        return `<h3 class="infowindowTitle">Cartório</h3><h3>${registerValues.numero} | ${registerValues.subdistrito}</h3>
          <div class="infoBlock">
            <ul>
              <li><label>Endereço:</label> ${registerValues.endereco}</li>
              <li><label>Área (m²):</label> ${registerValues.area}</li>
            </ul>
          </div>
          <div class="infoButtons">
            ${detalhes}
          </div>`
      },
      events: [
        {
          obj: '#infoWindow_detalhes',
          event: 'onclick',
          action: async (params) => {
            params.infoWindow.close()
            const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: 'cartorios' })
            const registerValues = await params.builder.Map.functions.getClickedObjectInfo(params.builder)
            params.builder.Map.$store.commit("showOverlay")
            params.builder.Map.$store.commit('changeKeepRegister', true)
            params.builder.Map.$store.commit('changeRegister', registerValues)
            params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 2 } })
              .then(()=>{  params.builder.Map.$store.commit("hideOverlay") })
              .catch(()=>{  params.builder.Map.$store.commit("hideOverlay") })
          }
        }
      ]
    })
    Map.$store.commit("hideOverlay")
  }
  this.getOperacaoUrbana = function(Map, operacaoUrbanaId) {
    if (!operacaoUrbanaId) { return }
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'operacoes_urbanas',
      action: 'read/one',
      body: { "query": { _id: operacaoUrbanaId }, "project": { nome: 1 } },
      cache: { 'name': 'maps/db/info', 'url': `public/operacoes_urbanas.json/_id=${operacaoUrbanaId}` },
    }).then(register => register).catch(e => e)
  }
  this.getMacroAreas = function(Map, query, project) {
    if (!query) { return }
    const cacheParams = `${query._id ? '/_ids=' + query._id.$in : ''}${query.sigla ? '/sigla=' + query.sigla : ''}${query.setor ? '/setor=' + query.setor : ''}`
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'macro_areas',
      action: 'read/many',
      body: { "query": query, "project": project },
      cache: { 'name': 'maps/db/info', 'url': `public/macro_area.json${cacheParams}` },
    }).then(register => register).catch(e => e)
  }
  this.getCartorios = function(Map, cartoriosIds) {
    if (!cartoriosIds) { return }
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'cartorios',
      action: 'read/many',
      body: { "query": { _id: { $in: cartoriosIds } }, "project": { subdistrito: 1, numero: 1 } },
      cache: { 'name': 'maps/db/info', 'url': `public/cartorios.json/_ids=${cartoriosIds}` },
    }).then(register => register).catch(e => e)
  }
  this.getZoneamento = function(Map, zoneamento) {
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'lpuos2016',
      action: 'read/one',
      body: { "query": { _id: zoneamento }, "project": { nome: 1, caBasico: 1, caMaximo: 1, cotaTerreno: 1, gabarito: 1, taxaOcupacao: 1, vagasPorUnidade: 1 } },
      cache: { 'name': 'maps/db/info', 'url': `public/lpuos2016.json/_id=${zoneamento}` },
    }).then(register => register).catch(e => e)
  }
  this.getZoneamentos = function(Map, zoneamentoIds, project) {
    if (!zoneamentoIds) { return }
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'lpuos2016',
      action: 'read/many',
      body: { "query": { _id: { $in: zoneamentoIds } }, "project": { ...project } }
    }).then(registers => registers).catch(e => e)
  }
  this.getLogradouros = function(Map, setor, quadra, codlog, year) {
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: `pgv_${year}`,
      action: 'read/many',
      body: { "query": { Setor: setor, Quadra: quadra, Codlog: { $in: codlog } }, "project": { Codlog: 1, Logradouro: 1 } },
      cache: { 'name': 'maps/db/info', 'url': `public/pgv_${year}.json/quadra=${setor}.${quadra}` },
    }).then(registers => registers).catch(e => e)
  }
  this.insertLPUOS2016 = async function(Map, zona) {
    if (Map.functions.isOnMap(Map, `lpuos2016_poligonos_${zona}`, 'link_lpuos2016', zona)) return
    Map.$store.commit("showOverlay")
    let color = await Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'lpuos2016',
      action: 'read/many',
      body: { "query": { nome: zona }, "project": { color: 1 } },
      early: true
    })
    color = color && color.data && color.data[0] && color.data[0].color && color.data[0].color.hexa ? color.data[0].color.hexa : '#000000'
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: `lpuos2016_poligonos_${zona}`,
      label: `LPUOS2016 (${zona})`,
      company: 'public',
      module: 'data',
      dataId: 'lpuos2016_poligonos',
      action: 'read/many',
      body: { "query": { link_lpuos2016: zona }, "project": { Poligono: 1, link_lpuos2016: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/lpuos2016_poligonos.json/link_lpuos2016=${zona}` },
      options: (builder) => { return !builder || { fillColor: color, fillOpacity: 0.2, strokeColor: color, strokeOpacity: 0.8, strokeWeight: 3, zIndex: 5 } },
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { link_lpuos2016: 1 })
        return `<h3 class="infowindowTitle">Zoneamento 2016</h3><h3>${registerValues.link_lpuos2016}</h3>`
      },
      events: []
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertLPUOS2024 = async function(Map, zona) {
    if (Map.functions.isOnMap(Map, `lpuos2024_poligonos_${zona}`, 'link_lpuos2024', zona)) return
    Map.$store.commit("showOverlay")
    let color = await Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'lpuos2024',
      action: 'read/many',
      body: { "query": { nome: zona }, "project": { color: 1 } },
      early: true
    })
    const getColor = (color) => {
      if (!color || !Array.isArray(color.data) || !color.data[0] || !color.data[0].color) return '#000000'
      if (typeof color.data[0].color === 'string' && color.data[0].color.startsWith('#')) return color.data[0].color 
      if (color.data[0].color.hexa && typeof color.data[0].color.hexa === 'string' && color.data[0].color.hexa.startsWith('#')) return color.data[0].color.hexa
      return '#000000'
    }
    // color = color && color.data && color.data[0] && color.data[0].color && color.data[0].color.hexa ? color.data[0].color.hexa : '#000000'
    color = getColor(color)
    await Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Polygon',
      name: `lpuos2024_poligonos_${zona}`,
      label: `LPUOS2024 (${zona})`,
      company: 'public',
      module: 'data',
      dataId: 'lpuos2024_poligonos',
      action: 'read/many',
      body: { "query": { link_lpuos2024: zona }, "project": { Poligono: 1, link_lpuos2024: 1 } },
      property: 'Poligono',
      cache: { 'name': 'maps/db/polygons', 'url': `public/lpuos2024_poligonos.json/link_lpuos2024=${zona}` },
      options: (builder) => { return !builder || { fillColor: color, fillOpacity: 0.2, strokeColor: color, strokeOpacity: 0.8, strokeWeight: 3, zIndex: 5 } },
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { link_lpuos2024: 1 })
        return `<h3 class="infowindowTitle">Zoneamento 2024</h3><h3>${registerValues.link_lpuos2024}</h3>`
      },
      events: []
    })
    Map.$store.commit("hideOverlay")
  }
  this.getIPTUsByOwner = async function({ Map, nome, documento, project }) {
    const iptu = await Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'iptu2020',
      action: 'read/many',
      body: { "query": { ["NOME DO CONTRIBUINTE 1"]: nome, "CPF/CNPJ DO CONTRIBUINTE 1": documento }, "project": { ...project } },
      cache: { 'name': 'maps/db/info', 'url': `public/iptu2020.json/nome=${nome}/documento=${documento}` },
    })
    return iptu
  }
  this.getITBI = async function({ Map, query, project }) {
    const itbi = await Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'itbi',
      action: 'read/many',
      body: { query, "project": { ...project } },
      cache: { 'name': 'maps/db/info', 'url': `public/itbi.json/sq=${query.sq}/sql=${query.sql}/id=${query._id}` },
    })
    return itbi
  }
  this.getQuadra = async function({ Map, query, project }) {
    const itbi = await Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'quadras',
      action: 'read/one',
      body: { query, "project": { ...project } },
      cache: { 'name': 'maps/db/info', 'url': `public/quadra.json/setor=${query.setor}/quadra=${query.quadra}` },
    })
    return itbi
  }
  this.insertFeiras = function(Map) {
    if (Map.functions.isOnMap(Map, 'feiras')) return
    Map.$store.commit("showOverlay")
    Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Marker',
      name: 'feiras',
      label: 'Feiras',
      company: 'public',
      module: 'data',
      dataId: 'feiras',
      action: 'read/many',
      body: { "project": { coordenadas: 1 } },
      property: 'coordenadas',
      cache: { 'name': 'maps/db/polygons', 'url': 'public/feiras.json' },
      options: (builder) => { return !builder || {
        label: 'F',
        optimized: true,
        icon: { path: window.google.maps.SymbolPath.CIRCLE, scale: 10, fillColor: '#FFFF00', fillOpacity: 1, strokeWeight: 0 },
        // animation: window.google.maps.Animation.DROP,
        zIndex: 7
      }},
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { nome: 1, diasemana: 1, registro: 1, endereco: 1 })
        return `<h3 class="infowindowTitle">Feira</h3><h3>${registerValues.nome}</h3>
          <div class="infoBlock">
            <ul>
              <li><label>Dia da semana:</label> ${registerValues.diasemana}</li>
              <li><label>Registro:</label> ${registerValues.registro}</li>
              <li><label>Endereço:</label> ${registerValues.endereco}</li>
            </ul>
          </div>`
      }
    })
    Map.$store.commit("hideOverlay")
  }
  this.insertEstacoes = function(Map) {
    if (Map.functions.isOnMap(Map, 'estacoes')) return
    Map.$store.commit("showOverlay")
    Map.functions.insertMapObjectDB({
      Map: Map,
      type: 'Marker',
      name: 'estacoes',
      label: 'Estações',
      company: 'public',
      module: 'data',
      dataId: 'estacoes',
      action: 'read/many',
      body: { "project": { poligono: 1 } },
      property: 'poligono',
      cache: { 'name': 'maps/db/polygons', 'url': 'public/estacoes.json' },
      options: (builder) => { return !builder || {
        label: { text: 'E', color: 'white' },
        optimized: true,
        icon: { path: window.google.maps.SymbolPath.CIRCLE, scale: 10, fillColor: '#0000FF', fillOpacity: 1, strokeWeight: 0 },
        zIndex: 8
      }},
      info: async (builder) => { 
        if (!builder) { return true }
        const registerValues = await builder.Map.functions.getClickedObjectInfo(builder, { nome: 1, linha: 1, situacao: 1, empresa: 1 })
        return `<h3 class="infowindowTitle">Estação</h3><h3>${registerValues.nome}</h3>
          <div class="infoBlock">
            <ul>
              <li><label>Linha:</label> ${registerValues.linha}</li>
              <li><label>Situação:</label> ${registerValues.situacao}</li>
              <li><label>Empresa:</label> ${registerValues.empresa}</li>
            </ul>
          </div>`
      }
    })
    Map.$store.commit("hideOverlay")
  }
  this.getEstacoes = function(Map) {
    return Map.backend.post({
      company: 'public',
      module: 'data',
      dataId: 'estacoes',
      action: 'read/many',
      body: { "project": { poligono: 1 } }
    }).then(registers => registers).catch(e => e)
  }
  this.insertRaioEstacoes = async function(Map) {
    if (Map.functions.isOnMap(Map, 'raioEstacoes')) return
    Map.$store.commit("showOverlay")
    const estacoes = await this.getEstacoes(Map)
    estacoes?.map(estacao => {
      const coord = estacao.poligono[0][0]
      const options = {
        center: coord,
        radius: 700,
        zIndex: 10,
        fillColor: "#95A2B8",
        fillOpacity: 0.2,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        editable: false,
        draggable: false,
        clickable: true,
      }
      const infoWindow = 
      `<h3 class="infowindowTitle">Raio Estações</h3>
      <div class="infoBlock">
        <ul>
          <li><label>Raio:</label> 700 metros</li>
        </ul>
      </div>`
      Map.functions.insertMapObject({
        Map,
        type: 'Circle',
        name: 'raioEstacoes',
        label: 'Raio Estações',
        options: builder => builder ? options : true,
        info: builder => builder ? infoWindow : true,
      })
    })
    Map.$store.commit("hideOverlay")
  }
}