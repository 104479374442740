import * as backend from '@/lib/backend'

export function uploadFile(params) {
  return backend.upload(params)
  .then(() => { return { status: true, data: params.fileName, alertType: 'success', alertText: 'Arquivo salvo com sucesso!' } })
  .catch(e => { return { status: false, alertType: 'error', alertText: `Ocorreu um problema ao salvar os arquivos: ${e?.data?.response?.data?.message || e?.data?.data?.response?.data?.message || ''}`, error: e } })
}

export function downloadFile(params) {
  return backend.download(params)
  .then((res) => { return { status: true, data: res.data.url } })
  .catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao baixar o arquivo', error: e } })
}

export function listFiles(params) {
  return backend.listFiles(params)
  .then((res) => { return { status: true, data: res.data.files } })
  .catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao listar os arquivos', error: e } })
}

export function removeFile(params) {
  return backend.removeFile(params)
  .then(() => { return { status: true, alertType: 'success', alertText: 'Arquivo removido com sucesso!' } })
  .catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao remover o arquivo', error: e } })
}

export function getSettings(company) {
  return backend.getSettings({ company })
  .then((res) => { return { status: true, data: res } })
  .catch(e => { return { status: false, alertType: 'error', alertText: 'Não foi possível obter as informações sobre o armazenamento.', error: e } })
}