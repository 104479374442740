<template>
  <v-tooltip v-if="field.tooltipIcon" top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon medium v-bind="attrs" v-on="on" class="tooltip">mdi-information-outline</v-icon>
    </template>
    <span>{{ field.tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AppendIconTooltip",
  props: ['field']
}
</script>

<style>
</style>