<template>
  <div>
    <v-overlay v-model="overlay" :absolute="true" :opacity="0.5" z-index="160">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay v-if="this.overlays.length &gt; 0" :absolute="true" :opacity="0.5" z-index="160">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "Overlay",
  computed: { ...mapState(['overlay', 'overlays']) }
}
</script>

<style>

</style>