<template>
  <div v-if="field.show == undefined || field.show" class="FieldChart" :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
    <div ref="divChart" class="divChart"></div>
    <div class="buttons">
      <v-icon medium @click="start" class="hover">mdi-refresh</v-icon>
    </div>
  </div>
</template>

<script>
import { chart_functions } from '@/lib/chart/chart_functions'
import { mapState } from 'vuex'
export default {
  name: "FieldChart",
  props: ['FormLines', 'logic', 'field', 'vmodel'],
  computed: { ...mapState(['register', 'user']) },
  data: () => ({
    chart: null,
    functions: null,
    data: null
  }),
  watch: {
    field: {
      deep: true,
      handler() { this.start() }
    }
  },
  methods: {
    start() {
      if (this.canShowChart()) {
        this.functions.configChart(this, this.$refs.divChart, () => {
          this.logic[this.field.method](this.FormLines, this)
        })
      }
    },
    canShowChart() {
      const isReport = this.$route.name == 'reports'
      const logicPrefix = isReport ? 'reports' : 'data'
      if (this.logic == null) return false
      if (!isReport && this.$route.query.tabNumber == 1) return false
      if (this.logic.name != `${logicPrefix}_${this.$route.query.menuId}`) return false
      if (!this.logic[this.field.method]) return false
      return true
    }
  },
  mounted() {
    this.start()
  },
  created() {
    this.functions = new chart_functions
    this.$watch(
      () => this.$route.query.tabNumber,
      (tabNumber) => {
        if ((tabNumber == 2 && this.FormLines.mode == 'edit') || (tabNumber == 0 && this.FormLines.mode == 'new')) { this.start() }
        else if (this.$route.name == 'reports') { this.start() }
      },
      { immediate: true }
    )
  }
}
</script>

<style>
  div.FieldChart {
    padding: 0px 5px;
    position: relative;
    margin-bottom: 10px;
  }
  div.FieldChart div.buttons { margin-top: -35px; display: flex; justify-content: flex-end; }
  div.FieldChart div.buttons button { color: grey; margin-right: 12px; }
  div.FieldChart div.divChart {
    min-height: 105px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
  }
  div.FieldChart div.divChart:hover { border-color: rgba(0, 0, 0, 0.87); }
</style>