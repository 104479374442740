<template>
  <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  data: () => ({
    items: [
      {
        text: '',
        disabled: false,
        href: '',
      },
      {
        text: '',
        disabled: false,
        href: '',
      }
    ],
  }),
  created() {
    this.$watch(
      () => this.$route,
      (route) => {
        this.items[0].text = this.$route.name != 'settings' ? route.query.hmenuOpened : 'Ajustes'
        this.items[1].text = this.$route.name != 'settings' ? route.query.menuOpened : ''
      },
      { immediate: true }
    )
  }
}
</script>

<style lang="scss">
  ul.v-breadcrumbs {
    padding-left: 5px;
    border-bottom: 1px solid rgb(100 121 143 / 12%);
    color: gray;
  }
  @media print { ul.v-breadcrumbs { display: none; } }
</style>