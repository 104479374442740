import { map_functions_public } from '@/lib/map_functions_public'
import * as js_functions from '@/lib/js_functions'
import MeasureTool from '@/../node_modules/measuretool-googlemaps-v3/dist/gmaps-measuretool.umd.js'
import { getGenericRegisters } from '@/lib/core/universal'
import { vtos } from '@/lib/core/public/vtos'
import { iptus } from '@/lib/core/public/iptus'

export function map_functions() {
  this.public = new map_functions_public
  this.polygonInfos = null
  this.configMap = function(Map, divMap, options) {
    try {
      Map.map = new window.google.maps.Map(divMap, options)
      Map.geocoder = new window.google.maps.Geocoder()
      Map.$store.commit("setMap", Map)
    }
    catch (error) { console.error(error) }
  }
  this.getCenter = function(polygonCoords) {
    let bounds = new window.google.maps.LatLngBounds()
    polygonCoords.map(coords => bounds.extend(coords))
    return bounds.getCenter()
  }
  this.setMapCenter = async function({ Map, address, lat, lng, zoom, insertMarker, buttonInsertTerreno, infowindowTitle }) {
    let location
    if (address) {
      await Map.geocoder.geocode({ address }, (results, status) => {
        if (status == 'ZERO_RESULTS') { return Map.$store.commit("showError", { data: 'Nenhum endereço encontrado!' }) }
        if (status !== 'OK' || !results[0]) { throw new Error(status) }
        if (insertMarker == true && results[0].partial_match == true) { Map.$store.commit("showAlert", { type: 'warning', text: 'A busca não encontrou uma correspondencia exata! \nO PIN foi adicionado em um local aproximado.', dismissible: true }) }
        Map.map.setCenter(results[0].geometry.location)
        location = results[0].geometry.location
      })
    }
    if (lat && lng) {
      Map.map.setCenter(new window.google.maps.LatLng(lat, lng))
      location = new window.google.maps.LatLng(lat, lng)
    }
    const getMarkerType = () => {
      if (address) { return 'address' }
      if (lat && lng && infowindowTitle) { return 'customDefault' }
      return 'default'
    }
    const markerInfoWindowConfig = (markerType) => {
      return {
        address: `<h3 class="infowindowTitle">Endereço ou CEP</h3><h3>${address}</h3>`,
        customDefault: `<h3 class="infowindowTitle">${infowindowTitle}</h3><div class="infoBlock"><h4>Coordenadas</h4><ul><li><label>Latitude:</label> ${lat}</li><li><label>Longitude:</label> ${lng}</li></ul></div>`,
        default: `<div class="infoBlock"><h4>Coordenadas</h4><ul><li><label>Latitude:</label> ${lat}</li><li><label>Longitude:</label> ${lng}</li></ul></div>`
      }[markerType]
    }
    if (insertMarker) {
      this.insertMapObject({
        Map: Map,
        type: 'Marker',
        coords: [{ lat: location.lat(), lng: location.lng() }],
        options: (builder) => !builder || {},
        info: (builder) => {
          if (!builder) { return true }
          const button = buttonInsertTerreno ? '<button id="infoWindow_cadastrarNoMarker">+ Terreno</button>' : ''
          return `${markerInfoWindowConfig(getMarkerType())}<div class="infoButtons">${button}</div>`
        },
        events: [
          {
            obj: '#infoWindow_cadastrarNoMarker',
            event: 'onclick',
            action: async (params) => {
              params.infoWindow.close()
              const menu = await params.builder.Map.get_info.getMenuObject({ parent: params.builder.Map, hmenu: 'data', dataId: 'terrenos' })
              const register = { poligono: [[params.builder.coords[0]]] }
              params.builder.Map.$store.commit("showOverlay")
              params.builder.Map.$store.commit('changeKeepRegister', true)
              params.builder.Map.$store.commit('changeRegister', register)
              params.builder.Map.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 0 } })
                .then(()=>{  params.builder.Map.$store.commit("hideOverlay") })
                .catch(()=>{  params.builder.Map.$store.commit("hideOverlay") })
            }
          }
        ]
      })
    }
    Map.map.setZoom(zoom)
  }
  this.fitBoundsToVisibleObjects = function(map, objects, onlyVisible) {
    let bounds = new window.google.maps.LatLngBounds()
    objects.map(object => {
      if (onlyVisible && !object.getVisible()) { return }
      if (typeof object.getPosition === 'function') { bounds.extend(object.getPosition()) }
      if (typeof object.getPath === 'function' && object.getPath().getLength() > 1) { object.getPath().forEach(function(a) { bounds.extend(a) }) }
    })
    map.fitBounds(bounds)
  },
  this.fitBounds = function(map, object) {
    if (object && object.getPath().getLength() > 1) {
      var b = new window.google.maps.LatLngBounds()
      object.getPath().forEach( function(a) { b.extend(a) } )
      setTimeout(() => { map.fitBounds(b) }, 1)
    }
  }
  this.convertAddressToCoords = async function(Map, address) {
    return new Promise((resolve, reject) => {
      Map.geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0]
          resolve(location)
        }
        else if (status === 'ZERO_RESULTS') { resolve('') }
        else { reject(new Error(status)) }
      })
    })
  }
  this.insertMapObjectDB = async function(builder, registers) {
    let _registers = registers != undefined ? registers : await builder.Map.backend.post({ ...builder })
    if (builder.func && typeof builder.func === "function") { _registers = await builder.func(_registers) }
    let polygons = []
    !Array.isArray(_registers) || _registers.map(register => {
      const polygon = register[builder.property]
      if (!Array.isArray(polygon)) { return }
      const objectsHole = polygon.filter(_polygon => _polygon.info && _polygon.info.hole)
      const objectsNotHole = polygon.filter(_polygon => !_polygon.info || !_polygon.info.hole)
      const getHoles = () => objectsHole.map(hole => hole.info.reverse ? hole[builder.property].reverse() : hole[builder.property])
      const getCoords = (_polygon) => {
        if (!_polygon[builder.property]) { return { coords: _polygon } }
        if (objectsHole.length == 0) { return { coords: _polygon[builder.property] } }
        let coordsWithHoles = getHoles()
        coordsWithHoles.unshift(_polygon[builder.property])
        return { coordsWithHoles }
      }
      if (objectsNotHole && objectsNotHole[0]) {
        if (objectsNotHole[0][builder.property]) {
          objectsNotHole.map(_polygon => polygons.push(this.insertMapObject({ ...builder, registers: _registers, register, ...getCoords(_polygon) })))
        }
        else {
          polygons.push(this.insertMapObject({ ...builder, registers: _registers, register: register, coords: polygon[0] }))
        }
      }
    })
    if (polygons.length == 0) { builder.Map.$store.commit("showAlert", { type: 'error', text: 'Nenhum registro com coordenadas encontrado!', dismissible: true, time: 3000 }) }
    return polygons
  }
  this.getObjectType = function(builder) {
    if (builder.type) { return builder.type }
    return builder.coords.length > 1 ? 'Polygon' : 'Marker'
  }
  this.insertMapObject = function(builder) {
    if (builder.type == 'Polyline' && builder.coords[0] != builder.coords[builder.coords.length - 1]) { builder.coords.push(builder.coords[0]) }
    const paths = builder.coords ? { path: builder.coords } : { paths: builder.coordsWithHoles }
    const objectType = this.getObjectType(builder)
    const objectsOptions = {
      Polygon: () => { return { position: builder.coords ? builder.coords[0] : builder.coordsWithHoles[0][0], ...paths, ...builder.options(builder) } },
      Marker: () => { return { position: builder.coords[0], ...builder.options(builder) } },
      Circle: () => builder.options(builder),
      Rectangle: () => builder.options(builder)
    }
    const insert = (_builder, _objectType) => {
      const object = new window.google.maps[_objectType](objectsOptions[_objectType]())
      object.setMap(_builder.Map.map)
      let builderRight = { ..._builder }
      builderRight.info = _builder.infoRight ? _builder.infoRight : this.defaultInfoWindowRight.info
      builderRight.events = _builder.eventsRight ? _builder.eventsRight : this.defaultInfoWindowRight.events
      if (_builder.info) { object.addListener('click', (event) => { this.mouseClick(_builder, builderRight, event, object, 'left') }) }
      object.addListener('rightclick', (event) => { this.mouseClick(_builder, builderRight, event, object, 'right') })
      if (_builder.Map.objectsName) { this.addToMapArray({ builder: _builder, array: 'objects', item: object }) }
      return object
    }
    if (builder.extraMarker) {
      const extraMarkerBuilder = { ...builder }
      extraMarkerBuilder.name = `${builder.name}Marker`
      extraMarkerBuilder.label = `${builder.label} (Marker)`
      insert(extraMarkerBuilder, 'Marker')
    }
    return insert(builder, objectType)
  }
  this.showPolygonInMap = function(builder) {
    const options = { fillOpacity: 0.0, strokeColor: "#00FFFF", strokeOpacity: 1.0, strokeWeight: 2, clickable: true, zIndex: 1 }
    const _options = builder.options ? builder.options : options
    const object = new window.google.maps.Polygon({ path: builder.polygon, ..._options, map: builder.map })
    object.setMap(builder.map)
    object.addListener('click', (event) => { this.openInfoWindowShowPolygon({ event, object, builder }) })
    return object
  }
  this.openInfoWindowShowPolygon = function({ event, object, builder }) {
    let infoWindow = new window.google.maps.InfoWindow()
    const _infoWindow = builder.info && builder.events ? builder : this.showPolygonDefaultInfoWindow 
    infoWindow.setContent(_infoWindow.info(builder))
    infoWindow.addListener("domready", () => {
      _infoWindow.events.map(listener => {
        if (document.querySelector(listener.obj)) {
          document.querySelector(listener.obj)[listener.event] = () => { listener.action({ builder, map: builder.map, infoWindow, object }) }
        }
      })
    })
    infoWindow.setPosition(event.latLng)
    infoWindow.open(builder.map)
    return infoWindow
  }
  this.showPolygonDefaultInfoWindow = {
    info: (builder) => `<h3 class="infowindowTitle">${builder.name || '-'}</h3><div class="infoButtons"><button id="Info_RemoverObjeto">Remover</button><br/></div>`,
    events: [
      {
        obj: '#Info_RemoverObjeto',
        event: 'onclick',
        action: (params) => {
          params.infoWindow.close()
          params.object.setMap(null)
        }
      }
    ]
  }
  this.mouseClick = function(builder, builderRight, event, object, button) {
    const _button = button == 'left' ? 'right' : 'left'
    button = (builderRight.Map.$refs.ToolBar && builderRight.Map.$refs.ToolBar.mouseReverse.active) ? _button : button
    if (builder.Map?.field?.clickRightObject && builder.Map?.field?.mouseReverse) { button = builder.Map?.field?.mouseReverse ? _button : button }
    if (button == 'right') { this.rightClick(builderRight, event, object) }
    else { this.openInfoWindow(builder, event, object) }
  }
  this.rightClick = function(builderRight, event, object) {
    if (builderRight.Map?.field?.clickRightObject && typeof builderRight.Map.logic[builderRight.Map.field.clickRightObject] == 'function') { return builderRight.Map.logic[builderRight.Map.field.clickRightObject]({ builder: builderRight, event, object }) }
    if (!builderRight.Map.$refs.ToolBar) return
    if (builderRight.Map.$refs.ToolBar.multiSelect.active) {
      if (!builderRight.Map.selected[builderRight.name] || builderRight.Map.selected[builderRight.name].filter(item => item.builder.register._id == builderRight.register._id).length == 0) {
        this.addToMapArray({ builder: builderRight, array: 'selected', item: object })
        object.setOptions({ strokeColor: '#00CC00', strokeOpacity: 0.8, strokeWeight: 2 })
      }
      else {
        this.removeFromMapArray(builderRight.Map, 'selected', builderRight.name, object)
        object.setOptions({ strokeColor: builderRight.options.strokeColor, strokeOpacity: 0, strokeWeight: 0.4 })
      }
    }
    else { this.openInfoWindow(builderRight, event, object) }
  }
  this.openInfoWindow = async function(builder, event, object) {
    let infoWindow = new window.google.maps.InfoWindow()
    infoWindow.setContent(await builder.info(builder, object))
    !builder.events || infoWindow.addListener("domready", () => {
      builder.events.map(listener => {
        if (document.querySelector(listener.obj)) {
          const tags = document.querySelectorAll(listener.obj)
          tags.forEach((tag) => { tag[listener.event] = () => { listener.action({ builder, infoWindow, object, tag }) } })
        }
      })
    })
    infoWindow.setPosition(event.latLng)
    infoWindow.open(builder.Map.map)
    return infoWindow
  }
  this.defaultInfoWindowRight = {
    info: (builder) => { return !builder ||
      `<div class="infoButtons">
        <button id="rightInfo_Remover">Remover</button><br/>
        <button id="rightInfo_Selecionar">Selecionar</button><br/>
        <button id="rightInfo_SelecionarDentro">Selecionar dentro</button><br/>
      </div>`
    },
    events: [
      {
        obj: '#rightInfo_Remover',
        event: 'onclick',
        action: (params) => {
          params.builder.Map.$store.commit("showOverlay")
          params.infoWindow.close()
          params.builder.Map.$refs.ToolBar.remove.group = 'clicked'
          params.builder.Map.$refs.ToolBar.remove.objectsType = params.builder.name
          params.builder.Map.$refs.ToolBar.remove.clickedObject = params.object
          params.builder.Map.$refs.ToolBar.remove.open = true
          params.builder.Map.$store.commit("hideOverlay")
        }
      },
      {
        obj: '#rightInfo_Selecionar',
        event: 'onclick',
        action: (params) => {
          this.addToMapArray({ builder: params.builder, array: 'selected', item: params.object })
          params.infoWindow.close()
          params.object.setOptions({ strokeColor: '#00CC00', strokeWeight: 2, strokeOpacity: 1 })
        }
      },
      {
        obj: '#rightInfo_SelecionarDentro',
        event: 'onclick',
        action: async (params) => {
          params.builder.Map.$store.commit("showOverlay")
          params.infoWindow.close()
          params.builder.Map.$refs.ToolBar.select.base = params.builder.name
          params.builder.Map.$refs.ToolBar.select.group = 'clicked'
          params.builder.Map.$refs.ToolBar.select.objectsType = ''
          params.builder.Map.$refs.ToolBar.select.clickedObject = params.object
          params.builder.Map.$refs.ToolBar.select.open = true
          params.builder.Map.$store.commit("hideOverlay")
        }
      }
    ]
  }
  this.removeOneObjectFromMap = function(Map, subArray, obj) {
    obj.setMap(null)
    this.removeFromAllMapArrays(Map, subArray, obj)
  }
  this.removeObjectsFromMap = function(Map, array, subArray) {
    Map[array][subArray].map(obj => { this.removeOneObjectFromMap(Map, subArray, obj) })
  }
  this.removeFromAllMapArrays = function(Map, subArray, itemToRemove) {
    this.removeFromMapArray(Map, 'selected', subArray, itemToRemove)
    this.removeFromMapArray(Map, 'objects', subArray, itemToRemove)
  }
  this.removeFromMapArray = function(Map, array, subArray, itemToRemove) {
    if (Map[array][subArray]) { Map[array][subArray] = Map[array][subArray].filter(item => item != itemToRemove) }
    Map.$refs.FooterInfo.refreshSelectedsView()
  }
  this.addToMapArray = function(params) {
    if (!params.builder.Map[params.array][params.builder.name]) { params.builder.Map[params.array][params.builder.name] = [] }
    const { Map: Map, ...builder } = params.builder
    params.item.builder = builder
    Map[params.array][params.builder.name].push(params.item)
    if (Map.objectsName.filter(obj => obj.name == params.builder.name).length == 0) {
      Map.objectsName.push({ name: params.builder.name, label: params.builder.label })
    }
    if (Map.$refs.FooterInfo) { Map.$refs.FooterInfo.refreshSelectedsView() }
  }
  this.getObjectsFromMapArray = function(params) {
    const { Map, array, type, field, value } = params
    return Map[array][type] ? Map[array][type].filter(obj => obj.builder.register[field] == value) : false
  }
  this.multiUpdate = function(Map, objectsToChange, objectsType) {
    Map.$store.commit("showOverlay")
    try {
      if (objectsToChange.length == 0) throw { message: 'Nenhum objeto encontrado.' } 
      if (!Map.register.connections) throw { message: 'Adicione uma ou mais informações para vincular.' }
      let register = {}
      register.connections = { $each: [] }
      Map.register.connections.map(connection => { register.connections.$each.push(connection) })
      const label = Map.objectsName.find(obj => obj.name == objectsType).label
      Map.backend.patch({
        module: 'data',
        dataId: objectsType,
        body: {
          _ids: objectsToChange.map(item => item.builder.register._id),
          data: register
        },
        early: true
      }).then(res => {
        if (res.status == 200) {
          Map.$store.commit("showAlert", { type: 'success', text: `${objectsToChange.length} ${label} atualizados com sucesso!`, dismissible: true, time: 2000 })
        }
        else { throw { data: "Ocorreu um problema ao atualizar." } }
      }).catch(e => Map.$store.commit("showError", { data: `Erro ao atualizar. | ${e}` }))
    }
    catch(err) { Map.$store.commit("showError", { data: err.message }) }
    Map.$store.commit("hideOverlay")
  }
  this.filterObjects = async function(Map, objectsToChange, objectsType, company, connectionsToFind, type) {
    Map.$store.commit("showOverlay")
    if (!connectionsToFind) throw { message: 'Adicione uma ou mais informações para filtrar.' }
    let message = ''
    if (objectsToChange) {
      const objWithConnections = await this.getRegisterValues({
        Map: Map,
        company: company,
        module: 'data',
        dataId: objectsType,
        _ids: objectsToChange.map(item => item.builder.register._id),
        project: type == 'connections' ? { connections: 1 } : { connections: 0 }
      })
      if (objWithConnections.length == undefined || objWithConnections.length == 0) { return true }
      let filterResult = objectsToChange.filter(item => {
        let filtersError = connectionsToFind.filter(connectionFilter => {
          const itemData = objWithConnections.filter(obj => obj._id == item.builder.register._id)
          if (itemData.length == undefined || itemData.length == 0) { return true }
          if (type == 'connections') {
            if (!itemData[0].connections) { return true }
            let connectionsMatch = itemData[0].connections.filter(connectionRegister => {
              return connectionRegister.dataId == connectionFilter.dataId && connectionRegister._id == connectionFilter._id ? true : false
            })
            return connectionsMatch.length > 0 ? false : true
          }
          else { return itemData[0][connectionFilter.dataId] == connectionFilter.link__id ? false : true }
        })
        if (filtersError.length > 0) {
          this.removeFromAllMapArrays(Map, objectsType, item)
          item.setMap(null)
          return false
        }
        else { return true }
      })
      message += ` ${filterResult.length} ${Map.objectsName.find(item => item.name == objectsType).label}`
    }
    Map.$store.commit("hideOverlay")
    if (message != '') { Map.$store.commit("showAlert", { type: 'success', text: `Encontrado(s):${message}.`, dismissible: true, time: 2000 }) }
    else { Map.$store.commit("showError", { data: 'Não foram encontrados resultados para o filtro.' }) }
  }
  this.filterObjectsParams = async function(Map, objectsToChange, objectsType, company, paramsToFind, type) {
    Map.$store.commit("showOverlay")
    if (!paramsToFind) throw { message: 'Adicione uma ou mais informações para filtrar.' }
    let message = ''
    if (objectsToChange) {
      const query = {}
      paramsToFind.map(line => {
        switch (line.operation) {
          case "Igual":
            Object.assign(query, { parametro: line.field, valor: line.value })
          break
          case "Diferente":
            Object.assign(query, { parametro: line.field, valor: { $ne: line.value } })
          break
          case "Maior":
            Object.assign(query, { parametro: line.field, valor: { $gte: line.value } })
          break
          case "Menor":
            Object.assign(query, { parametro: line.field, valor: { $lte: line.value } })
          break
        }
      })
      const subregioesWithParams = await Map.backend.post({
        company: company,
        module: 'data',
        dataId: "subregioes",
        action: 'read/many',
        body: { "query": { parametros: { $elemMatch: { ...query } } } },
      })
      let filterResult = []
      if (objectsType == 'subregioes') {
        filterResult = objectsToChange.map(item => {
          if (subregioesWithParams.filter(subregiao => subregiao._id == item.builder.register._id).length == 0) {
            this.removeFromAllMapArrays(Map, objectsType, item)
            item.setMap(null)
          }
        })
      }
      else {
        const connectionsToFind = subregioesWithParams.map(subregiao => { return { dataId: "subregioes", _id: subregiao._id } })
        const objWithConnections = await this.getRegisterValues({
          Map: Map,
          company: company,
          module: 'data',
          dataId: objectsType,
          _ids: objectsToChange.map(item => item.builder.register._id),
          project: type == 'params' ? { connections: 1 } : { connections: 0 }
        })
        if (objWithConnections.length == undefined || objWithConnections.length == 0) { return true }
        filterResult = objectsToChange.filter(item => {
          let filtersError = []
          const itemData = objWithConnections.filter(obj => obj._id == item.builder.register._id)
          if (itemData.length == undefined || itemData.length == 0) { return true }
          if (type == 'params') {
            if (!itemData[0].connections) { filtersError.push([false]) }
            else {
              filtersError = itemData[0].connections.map(connectionRegister => {
                return connectionsToFind.map(connectionFilter => (connectionRegister.dataId == connectionFilter.dataId && connectionRegister._id == connectionFilter._id) ? '1' : '0')
              })
            }
          }
          if (filtersError.length > 0 && !filtersError[0].includes('1')) {
            this.removeFromAllMapArrays(Map, objectsType, item)
            item.setMap(null)
            return false
          }
          else { return true }
        })
      }
      message += ` ${filterResult.length} ${Map.objectsName.find(item => item.name == objectsType).label}`
    }
    Map.$store.commit("hideOverlay")
    if (message != '') { Map.$store.commit("showAlert", { type: 'success', text: `Encontrado(s):${message}.`, dismissible: true, time: 2000 }) }
    else { Map.$store.commit("showError", { data: 'Não foram encontrados resultados para o filtro.' }) }
  }
  this.getRegisterValues = function(params) {
    return params.Map.backend.post({
      company: params.company,
      module: params.module,
      dataId: params.dataId,
      action: 'read/many',
      body: { "query": { _id: { $in: params._ids } }, "project": params.project },
      early: true
    }).then(res => res.data).catch(e => e)
  }
  this.getClickedObjectInfo = async function(builder, project) {
    delete builder.body
    builder._ids = [builder.register._id]
    builder.project = project ? project : {}
    const registers = await this.getRegisterValues(builder)
    return registers[0]
  }
  this.getClickedShapeInfo = function(builder, object) {
    const objectsInfos = {
      Polygon: () => {
        const path = object.getPath()
        const area = js_functions.numberOut(window.google.maps.geometry.spherical.computeArea(path), 2)
        const perimeter = js_functions.numberOut(window.google.maps.geometry.spherical.computeLength(path), 2)
        return { title: 'Polígono', area, perimeter }
      },
      Circle: () => {
        const radius = object.getRadius()
        const area = js_functions.numberOut( Math.PI * Math.pow(radius, 2), 2)
        const perimeter = js_functions.numberOut(2 * Math.PI * radius, 2)
        return { title: 'Círculo', area, perimeter, radius: js_functions.numberOut(radius, 2) }
      },
      Rectangle: () => {
        const bounds = object.getBounds()
        const width = window.google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), window.google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getNorthEast().lng()))
        const height = window.google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), window.google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getSouthWest().lng()))
        const area = js_functions.numberOut(width * height, 2)
        const perimeter = js_functions.numberOut(2 * (width + height), 2)
        return { title: 'Retângulo', area, perimeter }
      }
    }
    return objectsInfos[builder.type]()
  }
  this.changeColorVTO = async function(Map, array, objectsType, params) {
    if (objectsType == 'quadras') { this.changeColorVTOQuadras(Map, array, objectsType, params) }
    if (objectsType == 'lotes') { this.changeColorVTOLotes(Map, array, objectsType, params) }
  }
  this.changeColorVTOQuadras = async function(Map, array, objectsType, params) {
    Map.$store.commit("showOverlay")
    const objectsToChange = array != 'notSelected' ? Map[array][objectsType] : Map.objects[objectsType].filter(item => !Map.selected[objectsType].includes(item, 0))
    const registersWithData = await this.getRegisterValues({
      Map: Map,
      company: 'public',
      module: 'data',
      dataId: objectsType,
      _ids: objectsToChange.map(item => item.builder.register._id),
      project: { Setor: 1, Quadra: 1 }
    })
    const allSQ = registersWithData.map(item => `${item.Setor}${item.Quadra}`)
    const vtoDataId = (new vtos()).lastDataId
    const VTOs = (await js_functions.getFunc({ FormLines: Map, func: () => getGenericRegisters({ backend: Map.backend, company: 'public', module: 'data', dataId: vtoDataId, query: { sq: { $in: allSQ } }, project: { sq: 1, codlog: 1, valor: 1 } }), returnData: true })).data
    const itemsVTOs = registersWithData.map(item => VTOs.filter(vto => vto.sq == `${item.Setor}${item.Quadra}`))
    const itemsVTOsValues = itemsVTOs.map(item => js_functions.operationArrayProp(item, params.operation, 'valor'))
    const VTOMax = js_functions.operationArray(itemsVTOsValues, 'max')
    const VTOMin = js_functions.operationArray(itemsVTOsValues, 'min')
    registersWithData.map(item => {
      const sq = `${item.Setor}${item.Quadra}`
      const quadraVTO = VTOs.filter(vto => vto.sq == sq)
      if (quadraVTO && quadraVTO[0]) {
        let VTOValor = js_functions.operationArrayProp(quadraVTO, params.operation, 'valor')
        const percentage = (VTOValor - VTOMin) / (VTOMax - VTOMin)
        const color = js_functions.getColorForPercentage(percentage)
        objectsToChange.find(obj => obj.builder.register._id == item._id).setOptions({ fillColor: color, fillOpacity: 0.6 })
      }
    })
    Map.$store.commit("hideOverlay")
  }
  this.changeColorVTOLotes = async function(Map, array, objectsType, params) {
    console.log((params))
    Map.$store.commit("showOverlay")
    const objectsToChange = array != 'notSelected' ? Map[array][objectsType] : Map.objects[objectsType].filter(item => !Map.selected[objectsType].includes(item, 0))
    const registersWithData = await this.getRegisterValues({
      Map: Map,
      company: 'public',
      module: 'data',
      dataId: objectsType,
      _ids: objectsToChange.map(item => item.builder.register._id),
      project: { setor: 1, quadra: 1, lote: 1, condominio: 1 }
    })
    const allSQ = registersWithData.map(item => `${item.setor}${item.quadra}`)
    const vtoDataId = (new vtos()).lastDataId
    const VTOs = (await js_functions.getFunc({ FormLines: Map, func: () => getGenericRegisters({ backend: Map.backend, company: 'public', module: 'data', dataId: vtoDataId, query: { sq: { $in: allSQ } }, project: { sq: 1, codlog: 1, valor: 1 } }), returnData: true })).data
    const project = {
      "NUMERO DO CONTRIBUINTE": 1,
      "CODLOG DO IMOVEL": 1
    }
    const IPTUs = await (new iptus()).getIPTU({ project, info: { setor: registersWithData[0].setor, quadra: registersWithData[0].quadra } })
    // const itemsVTOs = registersWithData.filter(item => item.condominio == '00' || item.lote == '0000')
    const itemsVTOs = registersWithData
    const lotesVTOs = itemsVTOs.map(item => VTOs.filter(vto => vto.sq == `${item.setor}${item.quadra}`))
    const itemsVTOsValues = lotesVTOs[0].map(item => item.valor)
    const VTOMax = js_functions.operationArray(itemsVTOsValues, 'max')
    const VTOMin = js_functions.operationArray(itemsVTOsValues, 'min')
    registersWithData.map(item => {
      const _iptu = IPTUs.find(iptu => iptu["NUMERO DO CONTRIBUINTE"].startsWith(`${item.setor}${item.quadra}${item.lote}`))
      if (!_iptu) return
      const codlog = _iptu["CODLOG DO IMOVEL"].replace('-', '')
      const codlogVTO = lotesVTOs[0].find(vto => vto.codlog == codlog).valor
      if (codlogVTO) {
        const percentage = (codlogVTO - VTOMin) / (VTOMax - VTOMin)
        const _percentage = percentage == 0 ? 0.01 : percentage
        console.log({_percentage})
        const color = js_functions.getColorForPercentage(_percentage)
        objectsToChange.find(obj => obj.builder.register._id == item._id).setOptions({ fillColor: color, fillOpacity: 0.6 })
      }
    })
    Map.$store.commit("hideOverlay")
  }
  this.changeColorParametros = async function(Map, array, objectsType, params) {
    Map.$store.commit("showOverlay")
    const objectsToChange = array != 'notSelected' ? Map[array][objectsType] : Map.objects[objectsType].filter(item => !Map.selected[objectsType].includes(item, 0))
    const registersWithData = await this.getRegisterValues({
      Map: Map,
      module: 'data',
      dataId: objectsType,
      _ids: objectsToChange.map(item => item.builder.register._id),
      project: { parametros: 1 }
    })
    const items = registersWithData.filter(item => item.parametros.filter(parametro => parametro.link_parametro == params))
    const itemsValues = items.map(item => item.parametros.find(parametro => parametro.link_parametro == params && parametro.valor).valor)
    const Max = js_functions.operationArray(itemsValues, 'max')
    const Min = js_functions.operationArray(itemsValues, 'min')
    registersWithData.map(item => {
      const itemValor = js_functions.numberIn(item.parametros.find(parametro => parametro.link_parametro == params).valor)
      if (itemValor) {
        const percentage = (itemValor - Min) / (Max - Min)
        const color = js_functions.getColorForPercentage(percentage)
        objectsToChange.find(obj => obj.builder.register._id == item._id).setOptions({ fillColor: color, fillOpacity: 0.6, strokeOpacity: 0 })
      }
    })
    Map.$store.commit("hideOverlay")
  }
  this.isPolygonInsidePolygon = function(innerPolygon, outerPolygon, points) {
    let pointsInside = 0
    let pointsOutside = 0
    const pointsArray = Array.isArray(innerPolygon) ? innerPolygon : innerPolygon.getPath().getArray()
    if (outerPolygon instanceof window.google.maps.Circle) {
      pointsArray.map(point => {
        const center = outerPolygon.getCenter()
        const radius = outerPolygon.getRadius()
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, point)
        distance <= radius ? pointsInside++ : pointsOutside++
      })
    }
    else if (outerPolygon instanceof window.google.maps.Rectangle) {
      pointsArray.map(point => {
        const bounds = outerPolygon.getBounds()
        bounds.contains(point) ? pointsInside++ : pointsOutside++
      })
    }
    else { pointsArray.map(point => window.google.maps.geometry.poly.containsLocation(point, outerPolygon) ? pointsInside++ : pointsOutside++ ) }
    const maxPointsOutside = (points && points.maxOutside) ? points.maxOutside : 0
    const isInside = (!points || !points.minInside || pointsInside >= points.minInside) ? true : false
    return (pointsOutside <= maxPointsOutside) ? true : isInside
  }
  this.selectPolygonsInside = function(Map, subArray, outerPolygon, points) {
    const array = 'objects'
    Map[array][subArray].filter(obj => {
      if (this.isPolygonInsidePolygon(obj, outerPolygon, points)) {
        const builder = { ...obj.builder, Map: Map }
        this.addToMapArray({ builder: builder, array: 'selected', item: obj })
        obj.setOptions({ strokeColor: '#00CC00', strokeWeight: 2, strokeOpacity: 1 })
      }
    })
  }
  this.isOnMap = function(Map, type, field, value, multilines) {
    if (!field) {
      if (Map.objects[type] && Map.objects[type].length > 0) {
        Map.functions.removeObjectsFromMap(Map, 'objects', type)
        return true
      }
      return false
    }
    else {
      if (Map.objects[type] && Map.objects[type].length > 0) {
        const checkValue = (obj) => multilines ? obj.builder.register[multilines] && obj.builder.register[multilines].find(item => item[field] == value) != undefined : obj.builder.register[field] == value
        const objectsToRemove = Map.objects[type].filter(obj => checkValue(obj))
        if (objectsToRemove.length > 0) {
          objectsToRemove.map(obj => this.removeOneObjectFromMap(Map, type, obj))
          return true
        }
        return false
      }
    }
  }
  // Função para medir distâncias no mapa
  this.startToolMeasure = function({ Map, mapObjects }) {
    let toolMeasure = this.setToolMeasure(Map, { showSegmentLength: true, unit: 'metric', language: 'pt-BR', invertColor: true, contextMenu: false } )
    this.setListeneres(toolMeasure, mapObjects)
    return toolMeasure
  }
  // Função para desenhar polígonos no mapa
  this.startDrawWithMeasure = function({ Map, mapObjects }) {
    let drawWithToolMeasure = this.setToolMeasure(Map, { showSegmentLength: true, unit: 'metric', language: 'pt-BR', invertColor: false, contextMenu: false })
    this.setListeneres(drawWithToolMeasure, mapObjects)
    return drawWithToolMeasure
  }
  this.setToolMeasure = function(Map, options) { if (window.google.maps != undefined) { return new MeasureTool(Map, options) } }
  this.setListeneres = function(toolMeasure, mapObjects) {
    const map_functions = this
    toolMeasure.addListener('measure_start', function() {
      map_functions.changeClickableAllObjectsInMap(mapObjects, false)
    })
    toolMeasure.addListener('measure_change', function() {
      map_functions.polygonInfos = { coords: toolMeasure.points, areaTotal: toolMeasure.area, areaTotalText: toolMeasure.areaText, comprimentoTotal: toolMeasure.length, comprimentoTotalText: toolMeasure.lengthText }
    })
    toolMeasure.addListener('measure_end', function() {
      map_functions.changeClickableAllObjectsInMap(mapObjects, true)
      map_functions.polygonInfos = { coords: toolMeasure.points, areaTotal: toolMeasure.area, areaTotalText: toolMeasure.areaText, comprimentoTotal: toolMeasure.length, comprimentoTotalText: toolMeasure.lengthText }
    })
  }
  this.changeClickableAllObjectsInMap = function(mapObjects, clickable) {
    const objectsType = mapObjects.map(nameObject => typeof nameObject)
    if (objectsType?.length == 0) { Object.keys(mapObjects).map(nameObject => mapObjects[nameObject].map(poligono => poligono.setOptions({ clickable }))) }
    else { mapObjects.map(poligono => poligono.setOptions({ clickable })) }
  }
  this.getUserCurrentPosition = function({ Map, zoom, insertMarker, buttonInsertTerreno }) {
    if (navigator.geolocation) {
      const options = { enableHighAccuracy: true, timeout: 9000, maximumAge: 0 }
      Map.$store.commit("showOverlay")
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setMapCenter({ Map, lat: position.coords.latitude, lng: position.coords.longitude, zoom, insertMarker, buttonInsertTerreno, infowindowTitle: 'Localização atual' })
          Map.$store.commit("hideOverlay")
        },
        () => {
          Map.$store.commit("showAlert", { type: 'warning', text: 'Sem acesso à localização', dismissible: true })
          Map.$store.commit("hideOverlay")
        },
        options
      )
    }
    else { Map.$store.commit("showAlert", { type: 'error', text: 'Geolocalização não suportada no seu navegador', dismissible: true }) }
  }
}