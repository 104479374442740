<template>
  <div>
    <Breadcrumbs />
    <div id="tabs">
      <v-tabs v-model="tab" height="30px">
        <v-tab key='0' @change="changeTab(0)">Alterar Senha</v-tab>
        <v-tab key='1' @change="changeTab(1)">Armazenamento</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-show="tab == 0" key='0'>
          <v-form ref="form">
            <v-text-field
              v-model="currentPassword"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              name="currentPassword"
              label="Digite a senha atual"
              counter
              outlined
              dense
              class="mt-8"
              style="max-width: 500px;"
              @click:append="showCurrentPassword = !showCurrentPassword"
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              name="newPassword"
              label="Digite a nova senha"
              counter
              outlined
              dense
              style="max-width: 500px;"
              @click:append="showNewPassword = !showNewPassword"
            ></v-text-field>
            <v-text-field
              v-model="newPasswordRepeat"
              :append-icon="showNewPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPasswordRepeat ? 'text' : 'password'"
              name="newPasswordRepeat"
              label="Repita a nova senha"
              counter
              outlined
              dense
              style="max-width: 500px;"
              @click:append="showNewPasswordRepeat = !showNewPasswordRepeat"
            ></v-text-field>
            <div class="buttons">
              <v-btn @click="changePassword" color="success">Alterar</v-btn>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item v-show="tab == 1" key='1'>
          <br/>
          <p>Disponível: <strong>{{ js_functions.formatFileSize(storage.settings.storageAvailable) }}</strong></p>
          <p>Utilizado:    <strong>{{ js_functions.formatFileSize(storage.size) }}</strong></p>
          <p>Tamanho máximo dos arquivos: {{ js_functions.formatFileSize(storage.settings.fileMaxSize) }}</p>
          <v-progress-linear :value="storage.percentage" height="30" :color="`var(--over-color)`">
            <strong>{{ storage.percentage }}%</strong>
          </v-progress-linear>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Breadcrumbs from './Breadcrumbs'
import * as backend from '@/lib/backend'
import * as storage from '@/lib/storage'
import * as js_functions from '@/lib/js_functions'
export default {
  name: "Data",
  components: { Breadcrumbs },
  computed: { ...mapState(['user']) },
  data: () => ({
    tab: 0,
    showCurrentPassword: false,
    currentPassword: '',
    showNewPassword: false,
    newPassword: '',
    showNewPasswordRepeat: false,
    newPasswordRepeat: '',
    storage: {
      settings: {},
      size: 0,
      percentage: 0
    },
    js_functions
  }),
  methods: {
    changeTab(tabNumber) {
      this.tabActions()[tabNumber]()
      this.tab = tabNumber
    },
    tabActions() { return {
      0: () => null,
      1: async () => {
        await this.getCompanyStorageSettings()
        this.getCompanySize()
      }
    } },
    changePassword() {
      if (!this.currentPassword) { return this.$store.commit("showAlert", { type: 'error', text: 'Digite a senha atual!', dismissible: true, time: 2000 }) }
      if (!this.newPassword) { return this.$store.commit("showAlert", { type: 'error', text: 'Digite a senha nova!', dismissible: true, time: 2000 }) }
      if (!this.newPasswordRepeat) { return this.$store.commit("showAlert", { type: 'error', text: 'Repita a senha nova!', dismissible: true, time: 2000 }) }
      if (this.newPassword != this.newPasswordRepeat) { return this.$store.commit("showAlert", { type: 'error', text: 'As senhas não estão iguais!', dismissible: true, time: 2000 }) }
      backend.post({
        company: 'checkPassword/' + this.user.company,
        module: 'controls',
        dataId: '_users',
        body: { ...this.user, password: this.currentPassword },
        early: true
      }).then(res => {
        if (res.status == 200) {
          backend.patch({
            company: this.user.company,
            module: 'controls',
            dataId: '_users',
            body: { set: { _id: this.user._id, password: this.newPassword, changedPassword: true } },
            early: true
          })
          .then(res => {
            if (res.status == 200) { this.$store.commit("showAlert", { type: 'success', text: 'Senha alterada com sucesso!', dismissible: true, time: 2000 }) }
            else { this.$store.commit("showAlert", { type: 'error', text: 'Erro ao alterar senha.', dismissible: true, time: 3000 }) }
          }).catch(() => { this.$store.commit("showAlert", { type: 'error', text: 'Erro ao alterar senha.', dismissible: true, time: 3000 }) })
        }
      })
      .catch(() => { this.$store.commit("showAlert", { type: 'error', text: 'Senha atual Inválida!', dismissible: true, time: 2000 }) })
    },
    getCompanySize() {
      backend.getSize({
        company: this.user.company,
        early: true
      })
      .then(res => {
        if (res.status == 200) {
          this.storage.size = res.data.totalSize
          const percentage = Math.round((this.storage.size / this.storage.settings.storageAvailable) * 100)
          this.storage.percentage = isNaN(percentage) ? 0 : percentage
        }
        else { this.$store.commit("showAlert", { type: 'error', text: 'Não foi possível obter o tamanho da pasta.', dismissible: true, time: 3000 }) }
      }).catch(() => { this.$store.commit("showAlert", { type: 'error', text: 'Não foi possível obter o tamanho da pasta.', dismissible: true, time: 3000 }) })
    },
    async getCompanyStorageSettings() {
      await js_functions.getFunc({ FormLines: this, receiver: { object: this.storage, name: 'settings' }, func: () => storage.getSettings(this.user.company) })
    }
  },
}
</script>

<style scoped>
  div.buttons {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
  }
</style>