<template>
  <v-dialog v-model="dialog.show" persistent :max-width="dialog.width || '570'" scrollable content-class="withTable" :fullscreen="dialog.fullscreen">
    <v-card>
        <v-icon v-if="dialog.icon" x-large :color="dialog.icon.color">{{ dialog.icon.name }}</v-icon>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="dialog.buttonNo != false" color="grey lighten-1" @click="hideDialog">{{ dialog.buttonNo }}</v-btn>
          <v-btn v-if="dialog.buttonYes != false" color="error" @click="dialog.action">{{ dialog.buttonYes }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "Dialog",
  computed: { ...mapState(['dialog']) },
  methods: {
    hideDialog() { this.$store.commit("hideDialog") }
  }
}
</script>

<style scoped>
  div.v-dialog p.emphasis { color: #ff5252; font-weight: bold; }
  div.v-dialog .v-card .v-icon { width: 50px; position: absolute; right: 10px; top: 10px; }
  div.v-dialog .v-card .v-card__title { padding-right: 74px; word-break: normal; font-size: 20px; }
</style>

<style>
  div.v-dialog.withTable table { width: 100%; font-size: 9pt; }
  div.v-dialog.withTable table tr * { padding: 5px; }
  div.v-dialog.withTable table tr th { background-color: #AAAAAA; }
  div.v-dialog.withTable table tr td { background-color: #DDDDDD; text-align: center; }
</style>