<template>
  <v-tooltip :top="screenHeight <= 565" :right="screenHeight > 565">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        medium
        :class="[actionstyle, { 'on': vmodel[action] }]"
        @click="iconClick()"
        v-bind="attrs"
        v-on="on"
      >{{ icon }}</v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ToolBarIcon",
  props: ['icon', 'text', 'vmodel', 'action', 'actionstyle', 'click'],
  data: () => ({ screenHeight: window.innerHeight }),
  methods: {
    iconClick() {
      if (this.click && this.click === this.$parent.clickMeasureButton) { this.click() }
      else {
        this.$parent.turnOffMeasureTool()
        this.click ? this.click() : this.vmodel[this.action] = !this.vmodel[this.action]
      }
    },
    updateScreenHeight() { this.screenHeight = window.innerHeight }
  },
  mounted() { window.addEventListener('resize', this.updateScreenHeight) },
  beforeDestroy() { window.removeEventListener('resize', this.updateScreenHeight) }
}
</script>

<style scoped>
  .v-icon {
    z-index: 8;
    padding: 5px 9px 5px 10px;
    border: 1px solid white;
  }
  .v-icon:hover {
    color: rgba(0, 0, 0, 0.80);
    cursor: pointer;
  }
  .v-icon.v-icon::after { background: none; }
  .v-icon.on.open { color: #0000CC; }
  .v-icon.on.active { color: #00CC00; }
  .v-icon.on.active:hover { color: #00BB00; }
  .v-icon.on.activeTool { color: #CC0000; }
  .v-icon.on.activeTool:hover { color: #BB0000; }
  .v-icon.inactive { color: lightgray; }
</style>