import axios from "axios"
import { baseFilesUrl } from '@/global'

export async function getCacheVersions(company) {
  const defaultCache = await axios.get(`${baseFilesUrl}/cacheVersions.json`).then(res => res.data ? res.data : []).catch(() => [])
  const companyCache = await axios.get(`${baseFilesUrl}/${company}/cacheVersions.json`).then(res => res.data ? res.data : []).catch(() => [])
  localStorage.setItem('cacheVersions', JSON.stringify(defaultCache.concat(companyCache)))
}

async function getCachedData(cacheName, url) {
  const cacheStorage = await caches.open(cacheName)
  const cachedResponse = await cacheStorage.match(url)
  if (!cachedResponse || !cachedResponse.ok) { return false }
  return await cachedResponse.json()
}
async function deleteCachedData(cacheName, url) {
  const cacheStorage = await caches.open(cacheName)
  const cachedResponse = await cacheStorage.delete(url, { ignoreSearch: true })
  if (!cachedResponse || !cachedResponse.ok) { return false }
  return await cachedResponse.json()
}
export async function requestCache(method, params, url) {
  const saveCache = params.cache ? true : false
  const getVersion = (path) => {
    if (path == 'cacheVersions.json') { return 0 }
    const cacheVersions = JSON.parse(localStorage.getItem('cacheVersions'))
    if (!cacheVersions) { return 0 }
    const file = cacheVersions.find(file => path.startsWith(file.request))
    return file ? file.version : 0
  }
  const path = saveCache ? `${params.cache.name}/${params.cache.url}` : ''
  const version = getVersion(path)
  const versionParam = saveCache ? `?version=${version}` : ''
  let objectCached = saveCache ? await getCachedData(params.cache.name, `${params.cache.url}${versionParam}`) : false
  if (objectCached && version != 0) { return objectCached }
  else {
    const cache = saveCache ? await caches.open(params.cache.name) : false
    !saveCache || await deleteCachedData(params.cache.name, params.cache.url)
    const body = params.body ? params.body : ''
    const config = params.config ? params.config : ''
    return params.early ? axios[method](url, { ...body, ...config }) : await axios[method](url, params.body, config).then(res => {
      !saveCache || cache.put(`${params.cache.url}${versionParam}`, new Response(JSON.stringify(res.data)))
      return res.data
    }).catch(e => e)
  }
}