import Vue from 'vue'
import VueRouter from 'vue-router'
import Data from '../components/Data.vue'
import Reports from '../components/Reports.vue'
import Maps from '../components/Maps.vue'
import Settings from '../components/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/data',
    name: 'data',
    component: Data
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports
  },
  {
    path: '/maps',
    name: 'maps',
    component: Maps
  },
  {
    path: '/controls',
    name: 'controls',
    component: Data
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
