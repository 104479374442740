<template>
  <div class="fullGrid">
    <div id="divMap" ref="divMap"></div>
    <ToolBar :Map="mySelf()" ref="ToolBar"/>
    <FooterInfo :objectsName="objectsName" :selected="selected" ref="FooterInfo"/>
  </div>
</template>

<script>
import * as backend from '@/lib/backend'
import * as files from '@/lib/files'
import * as get_info from '@/lib/get_info'
import { map_functions } from '@/lib/map_functions'
import { mapState } from 'vuex'
import ToolBar from './Maps/ToolBar'
import FooterInfo from './Maps/FooterInfo'
export default {
  name: "Maps",
  components: { ToolBar, FooterInfo },
  computed: { ...mapState(['user', 'register', 'menus']) },
  data: () => ({
    map: null,
    geocoder: null,
    logicMaps: null,
    functions: null,
    backend: null,
    get_info: null,
    objectsName: [],
    objects: [],
    selected: []
  }),
  methods: {
    mySelf() { return this },
    loadMap() {
      this.functions.configMap(this, this.$refs.divMap)
      if (typeof this.logicMaps.loadMap === 'function') this.logicMaps.loadMap(this)
      if (typeof this.logicMaps.loadHMenuMap === 'function') this.logicMaps.loadHMenuMap({ Map: this } )
    },
    reload() {
      this.objectsName = []
      this.objects = []
      this.selected = []
      this.loadMap()
    }
  },
  async mounted() {
    this.backend = backend
    this.get_info = get_info
    this.logicMaps = await files.getJSNewObject({ path: `${this.user.company}/logicMaps.js`, newParams: { files, map_functions, backend } })
    this.functions = new map_functions
    this.loadMap()
  },
  // destroyed() { files.unloadScript(`${this.user.company}/logicMaps.js`) }
}
</script>

<style>
  div.fullGrid {
    height: 100%;
    position: relative;
  }
  div#divMap { height: 100%; }
  .gm-style .gm-style-iw {
    max-width: 323px;
    max-height: 200px;
    font-weight: 400;
  }
  .gm-style .gm-style-iw-d > div > h3.infowindowTitle {
    background-color: gray;
    color: white;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 8px 20px;
    border-radius: 4px 4px 0px 0px;
  }
  .gm-style .gm-style-iw-d > div > h3 {
    background-color: #DDDDDD;
    color: #666666;
    text-align: center;
    margin: 0px 0px 3px 0px;
    padding: 8px 20px;
    border-radius: 0px 0px 4px 4px;
  }
  .gm-style .gm-style-iw-d > div > div.infoBlock, .gm-style .gm-style-iw-d > div > div.infoButtons { margin: 10px 0px; }
  .gm-style .gm-style-iw-d > div > div.infoBlock, .gm-style .gm-style-iw-d > div > div.infoButtons > * { margin-bottom: 5px; }
  .gm-style .gm-style-iw-d > div > div.infoBlock > h4 {
    text-align: center;
    margin:  0px 0px 5px 0px;
    border-bottom: 1px solid gray;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons {
    display: flex;
    flex-direction: column;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons > button {
    border: solid 1px lightgray;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px;
    color: #666666;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons > button:hover { background-color: #D5D5D5; }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px lightgray;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px;
    color: #666666;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption > button {
    width: 100%;
    border: solid 1px lightgray;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px;
    color: #666666;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption > button:hover { background-color: #D5D5D5; }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption > div { padding: 7px; }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption > div > input { vertical-align: middle; }
  .gm-style .gm-style-iw-d > div > div.infoButtons > div.buttonOption > div > label { vertical-align: middle; }
  .gm-style .gm-style-iw-d > div > div.infoBlock > ul { padding: 2px 10px; }
  .gm-style .gm-style-iw-d > div > div.infoBlock > ul > li {
    list-style-type: none;
    text-align: left;
    margin: 3px 0px;
  }
  .gm-style .gm-style-iw-d > div > div.infoBlock > ul > li > label {
    padding: 0px 1px 0px 0px;
    font-weight: bold;
  }
  .gm-style .gm-style-iw-d > div > div.infoButtons > button:hover { background-color: #d6d6d6; }
  .gm-style .gm-style-iw-d > div > div.infoBlock > table { width: 100%; }
  .gm-style .gm-style-iw-d > div > div.infoBlock > table > tbody > tr > th, .gm-style .gm-style-iw-d > div > div.infoBlock > table > tbody > tr > td {
    padding: 5px;
    text-align: center;
    background-color: #f5f5f5;
  }
  .gm-style .gm-style-iw-d > div > div.infoBlock > table > tbody > tr > th { background-color: #d1d1d1; }
</style>