<template>
  <div>
    <Breadcrumbs />
    <Tabs v-if="showTabs" />
    <div v-if="!showTabs" class="noMenu">Acesse um item do menu</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Breadcrumbs from './Breadcrumbs'
import Tabs from './Data/Tabs.vue'
import * as get_info from '@/lib/get_info'
export default {
  name: "Data",
  components: { Breadcrumbs, Tabs },
  computed: { ...mapState(['permission', 'menus', 'started']) },
  data: () => ({
    showTabs: false,
    get_info: null
  }),
  methods: {
    async showStart() {
      if (!this.permission.startReportDataId) return
      const menu = await this.get_info.getMenuObject({ parent: this, hmenu: 'reports', dataId: this.permission.startReportDataId })
      this.$router.push({ name: 'reports', query: { hmenuOpened: 'Relatórios', menuId: menu.report, menuOpened: menu.title } })
        .then(() => this.permission.startReportDataId = false)
        .catch(()=>{})
    }
  },
  mounted() {
    this.get_info = get_info
    this.showStart()
  },
  created() {
    this.$watch(
      () => this.$route.query.menuId,
      (menuId) => { this.showTabs = (menuId && menuId != "") ? true : false },
      { immediate: true }
    )
  }
}
</script>

<style>
  .noMenu {
    text-align: center;
    padding: 10px;
    color: gray;
  }
</style>