<template>
  <div id="toolBar">
    <ToolBarIcon text="Seleção Múltipla" :vmodel="multiSelect" action="active" actionstyle="active" icon="mdi-plus-box-multiple"/>
    <!-- <ToolBarIcon text="Selecionar Dentro" :vmodel="select" action="open" actionstyle="open" icon="mdi-checkbox-multiple-blank"/> -->
    <ToolBarIcon text="Selecionar Dentro" :vmodel="select" action="" actionstyle="inactive" icon="mdi-checkbox-multiple-blank"/>
    <!-- <ToolBarIcon text="Edição Múltipla" :vmodel="multiUpdate" action="open" actionstyle="open" icon="mdi-layers-triple"/> -->
    <ToolBarIcon text="Edição Múltipla" :vmodel="multiUpdate" action="" actionstyle="inactive" icon="mdi-layers-triple"/>
    <ToolBarIcon text="Filtros" :vmodel="filter" action="open" actionstyle="open" icon="mdi-filter"/>
    <ToolBarIcon text="Mudar Cores" :vmodel="palette" action="open" actionstyle="open" icon="mdi-palette"/>
    <ToolBarIcon text="Procurar no mapa" :vmodel="search" action="open" actionstyle="open" icon="mdi-magnify"/>
    <ToolBarIcon text="Recarregar" vmodel="" action="" actionstyle="" :click="reload" icon="mdi-refresh"/>
    <ToolBarIcon text="Estabelecimentos" :vmodel="poi" action="active" actionstyle="activeTool" :click="clickPOI" icon="mdi-store-marker"/>
    <ToolBarIcon text="Inverter mouse" :vmodel="mouseReverse" action="active" actionstyle="activeTool" icon="mdi-mouse-move-vertical"/>
    <ToolBarIcon text="Medir Distância" :vmodel="measure" action="active" actionstyle="activeTool" :click="clickMeasureButton" icon="mdi-ruler"/>
    <ToolBarIcon text="Localização atual"  vmodel="" action="" actionstyle="" :click="clickUserCurrentPosition" icon="mdi-crosshairs-gps"/>
    <ToolBarIcon text="Remover" :vmodel="remove" action="open" actionstyle="open" icon="mdi-delete"/>
    <!-- <ToolBarIcon text="Desenhar Shape" :vmodel="drawShape" action="" actionstyle="inactive" icon="mdi-shape-plus"/> -->
    <ToolBarIcon v-if="!drawShapeActive.active" text="Desenhar Shape" :vmodel="drawShape" action="open" actionstyle="open" icon="mdi-shape-plus"/>
    <ToolBarIcon v-if="drawShapeActive.active" text="Desativar Shape" :vmodel="drawShapeActive" action="active" actionstyle="activeTool" :click="clickDrawShapeActive" icon="mdi-shape"/>
    <ToolBarWindow headline="Selecionar Dentro" :vmodel="select" action="open" :click="selectObjects" command="Selecionar" icon="mdi-checkbox-multiple-blank">
      <FormLines mode="map" :fields="fieldsSelect" :logic="logic" :vmodel="select"/>
    </ToolBarWindow>
    <ToolBarWindow headline="Edição Múltipla" :vmodel="multiUpdate" action="open" :click="multiUpdateObjects" command="Adicionar" icon="mdi-layers-triple">
      <FormLines mode="map" :fields="fieldsFilter" :logic="logic" :vmodel="multiUpdate"/>
      <FormLines mode="map" :fields="fieldsFilterConnections()" :logic="logic"/>
    </ToolBarWindow>
    <ToolBarWindow headline="Filtros" :vmodel="filter" action="open" :click="filterObjects" command="Filtrar" icon="mdi-filter">
      <FormLines mode="map" :fields="fieldsFilter" :logic="logic" :vmodel="filter"/>
      <div class="firsttabs" v-if="filter.objectsType">
        <v-tabs v-model="filter.tab" height="30px">
          <v-tab key='0'>Dados Internos</v-tab>
          <v-tab key='1'>Dados Públicos</v-tab>
          <v-tab key='2'>Método</v-tab>
        </v-tabs>
        <v-tabs-items v-model="filter.tab">
          <v-tab-item key='0'>
            <div class="tabs">
              <v-tabs v-model="filter.company.tab" height="30px">
                <v-tab key='0'>Campo Específico</v-tab>
                <v-tab key='1'>Conexão</v-tab>
                <v-tab key='2'>Parametros</v-tab>
              </v-tabs>
              <v-tabs-items v-model="filter.company.tab">
                <v-tab-item key='0'>
                  <FormLines mode="map" :fields="fieldsFilterLinks('filter')" :logic="logic" :vmodel="filter.company"/>
                </v-tab-item>
                <v-tab-item key='1'>
                  <FormLines mode="map" :fields="fieldsFilterConnections()" :logic="logic" :vmodel="filter.company"/>
                </v-tab-item>
                <v-tab-item key='2'>
                  <FormLines mode="map" :fields="fieldsFilterParametros()" :logic="logic" :vmodel="filter.company"/>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-tab-item>
          <v-tab-item key='1'>
            <div class="tabs">
              <v-tabs v-model="filter.public.tab" height="30px">
                <v-tab key='0'>Campo Específico</v-tab>
                <v-tab key='1'>Conexão</v-tab>
              </v-tabs>
              <v-tabs-items v-model="filter.public.tab">
                <v-tab-item key='0'>
                  <FormLines mode="map" :fields="fieldsFilterLinks('filter')" :logic="logic" :vmodel="filter.public"/>
                </v-tab-item>
                <v-tab-item key='1'>
                  <FormLines mode="map" :fields="fieldsFilterConnections('public')" :logic="logic" :vmodel="filter.public"/>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-tab-item>
          <v-tab-item key='2'>
            <FormLines mode="map" :fields="fieldsFilterMethods" :logic="logic" :vmodel="filter"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </ToolBarWindow>
    <ToolBarWindow headline="Mudar Cores" :vmodel="palette" action="open" :click="changeColor" command="Mudar" icon="mdi-palette">
      <FormLines mode="map" :fields="fieldsPalette" :logic="logic" :vmodel="palette"/>
    </ToolBarWindow>
    <ToolBarWindow headline="Procurar no mapa" v-if="search.open" :vmodel="search" action="open" :click="find" command="Procurar" icon="mdi-magnify">
      <v-tabs v-model="search.tab" height="30px">
        <v-tab key='0'>Endereço | CEP</v-tab>
        <v-tab key='1'>Setor e Quadra</v-tab>
      </v-tabs>
      <v-tabs-items v-model="search.tab">
        <v-tab-item key='0'>
          <FormLines mode="map" :fields="fieldsSearchEnderecoCEP" :logic="logic" :vmodel="search"/>
        </v-tab-item>
        <v-tab-item key='1'>
          <FormLines mode="map" :fields="fieldsSearchSetorQuadra" :logic="logic" :vmodel="search"/>
        </v-tab-item>
      </v-tabs-items>
    </ToolBarWindow>
    <ToolBarWindow headline="Remover" :vmodel="remove" action="open" :click="removeObjects" command="Remover" icon="mdi-delete">
      <FormLines mode="map" :fields="fieldsRemove" :logic="logic" :vmodel="remove"/>
    </ToolBarWindow>
    <ToolBarWindow headline="Desenhar shape no mapa" :vmodel="drawShape" action="open" :click="drawShapes" command="Selecionar" icon="mdi-draw">
      <FormLines mode="map" :fields="fieldsdrawShape" :logic="logic" :vmodel="drawShape"/>
    </ToolBarWindow>
  </div>
</template>

<script>
import ToolBarIcon from './ToolBarIcon'
import ToolBarWindow from './ToolBarWindow'
import FormLines from '../Data/Form/FormLines'
import * as backend from '@/lib/backend'
import * as js_functions from '@/lib/js_functions'
export default {
  name: "ToolBar",
  props: ['Map'],
  components: { ToolBarIcon, ToolBarWindow, FormLines },
  data: () => ({
    multiSelect: { active: false },
    multiUpdate: { open: false, group: null, objectsType: null },
    filter: {
      open: false,
      group: null,
      objectsType: null,
      tab: 0,
      company: { tab: 0, links: [], connections: [], params: [] },
      public: { tab: 0, links: [], connections: [] },
      method: null,
      params: null
    },
    palette: { open: false, group: null, objectsType: null, method: null, params: null },
    search: {
      open: false,
      address: null,
      tab: 0,
      setor: null,
      quadra: null,
      tipo: 'F'
    },
    select: { open: false, base: null, group: null, objectsType: null, clickedObject: null },
    remove: { open: false, group: null, objectsType: null, clickedObject: null },
    poi: { active: true },
    mouseReverse: { active: false },
    measure: { active: false, measureTool: null },
    drawShape: { open: false },
    drawShapeActive: { active: false, drawWithMeasure: null, listenerClickCircle: null, listenerClickRectangle: null },
    shapesConfig: {
      zIndex: 10,
      fillColor: "#95A2B8",
      fillOpacity: 0.2,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      editable: true,
      draggable: true,
      clickable: true,
    },
    logic: {}
  }),
  watch: {
    'select.open': function(value) {
      if (!value && this.select.group == 'clicked') {
        this.select.base = null
        this.select.group = null
        this.select.objectsType = null
        this.select.clickedObject = null
      }
    },
    'remove.open': function(value) {
      if (!value && this.remove.group == 'clicked') {
        this.remove.group = null
        this.remove.objectsType = null
        this.remove.clickedObject = null
      }
    }
  },
  computed: {
    fieldsFilter: function() { return [
      {
        "number": "1",
        "fields": [
          {
            "name": "group",
            "label": "Aplicar em",
            "type": "select",
            "options": [
              { "option": { "text": "Todos", "value": "objects" } },
              { "option": { "text": "Selecionados", "value": "selected" } },
              { "option": { "text": "Não Selecionados", "value": "notSelected" } }
            ]
          }
        ]
      },
      {
        "number": "2",
        "fields": [
          {
            "name": "objectsType",
            "label": "Tipo dos Objetos",
            "type": "select",
            "options": this.$parent.objectsName.map(item => { return { "option": { "text": item.label, "value": item.name } }})
          }
        ]
      }
    ]},
    fieldsFilterMethods: function() { return [
      {
        "number": "5",
        "fields": [
          {
            "type": "link",
            "fields": [
              {
                "name": "method",
                "label": "Método",
                "module": "controls",
                "dataId": "_maps",
                "linkField": "title",
                "linkValue": "method",
                "field": "type",
                "value": "filter",
                "grow": "2"
              }
            ]
          },
          {
            "name": "param",
            "label": "Parâmetro",
            "type": "text"
          }
        ]
      },
    ]},
    fieldsPalette: function() { return [
      {
        "number": "1",
        "fields": [
          {
            "name": "group",
            "label": "Aplicar em",
            "type": "select",
            "options": [
              { "option": { "text": "Todos", "value": "objects" } },
              { "option": { "text": "Selecionados", "value": "selected" } },
              { "option": { "text": "Não Selecionados", "value": "notSelected" } }
            ]
          }
        ]
      },
      {
        "number": "2",
        "fields": [
          {
            "name": "objectsType",
            "label": "Tipo dos Objetos",
            "type": "select",
            "options": this.$parent.objectsName.map(item => { return { "option": { "text": item.label, "value": item.name } }})
          }
        ]
      },
      {
        "number": "3",
        "fields": [
          {
            "type": "link",
            "fields": [
              {
                "name": "method",
                "label": "Método",
                "module": "controls",
                "dataId": "_maps",
                "linkField": "title",
                "linkValue": "method",
                "field": "type",
                "value": "color",
                "grow": "2"
              }
            ]
          },
          {
            "name": "params",
            "label": "Parâmetro",
            "type": "text"
          }
        ]
      },
    ]},
    fieldsSearchEnderecoCEP: function() { return [
      {
        "fields": [
          {
            "name": "address",
            "label": "Endereço | CEP",
            "type": "text"
          }
        ]
      },
      {
        "fields": [
          {
            "name": "city",
            "label": "Cidade",
            "type": "text",
            "defaultvalue": `${this.Map.user.permission.enderecoDoMapa || 'São Paulo'}`
          }
        ]
      }
    ]},
    fieldsSearchSetorQuadra: function() { return [
      {
        "fields": [
          {
            "name": "setor",
            "label": "Setor",
            "type": "text",
            "mask": "number",
            "maxlength": 3
          },
          {
            "name": "quadra",
            "label": "Quadra",
            "type": "text",
            "mask": "number",
            "maxlength": 3
          },
          {
            "name": "tipo",
            "label": "Tipo",
            "type": "select",
            "options": [
              { "option": { "text": "Fiscal", "value": "F" } },
              { "option": { "text": "Municipal", "value": "M" } },
              { "option": { "text": "Rural", "value": "R" } },
              { "option": { "text": "Especial", "value": "E" } }
            ],
            "defaultvalue": "F"
          }
        ]
      },
    ]},
    fieldsSelect: function() { return [
      {
        "number": "1",
        "fields": [
          {
            "name": "base",
            "label": "Selecionar dentro de",
            "type": "select",
            "options": this.$parent.objectsName.map(item => { return { "option": { "text": item.label, "value": item.name } }})
          }
        ]
      },
      {
        "fields": [
          {
            "name": "group",
            "label": "Aplicar em",
            "type": "select",
            "options": [
              { "option": { "text": "Todos", "value": "objects" } },
              { "option": { "text": "Selecionados", "value": "selected" } },
              { "option": { "text": "Não Selecionados", "value": "notSelected" } },
              { "option": { "text": "Item clicado", "value": "clicked" } }
            ]
          }
        ]
      },
      {
        "fields": [
          {
            "name": "objectsType",
            "label": "Tipo dos Objetos",
            "type": "select",
            "options": this.$parent.objectsName.map(item => { return { "option": { "text": item.label, "value": item.name } }})
          }
        ]
      },
    ]},
    fieldsRemove: function() { return [
      {
        "fields": [
          {
            "name": "group",
            "label": "Aplicar em",
            "type": "select",
            "options": [
              { "option": { "text": "Todos", "value": "objects" } },
              { "option": { "text": "Selecionados", "value": "selected" } },
              { "option": { "text": "Não Selecionados", "value": "notSelected" } },
              { "option": { "text": "Item clicado", "value": "clicked" } }
            ]
          }
        ]
      },
      {
        "fields": [
          {
            "name": "objectsType",
            "label": "Tipo dos Objetos",
            "type": "select",
            "options": this.$parent.objectsName.map(item => { return { "option": { "text": item.label, "value": item.name } }})
          }
        ]
      },
    ]},
    fieldsdrawShape: function() { return [
      {
        "fields": [
          {
            "name": "forma",
            "label": "Forma",
            "type": "select",
            "options": [
              { "option": { "text": "Livre", "value": "Livre" } },
              { "option": { "text": "Círculo", "value": "Círculo" } },
              { "option": { "text": "Retângulo", "value": "Retângulo" } }
            ]
          }
        ]
      }
    ]}
  },
  methods: {
    fieldsFilterLinks(tab) { return [
      {
        "number": "3",
        "fields": tab != 'filter' || [
          {
            "name": "links",
            "type": 'multilines',
            "fields": [
              {
                "type": "link",
                "columnwidth": "100%",
                "fields": [
                  {
                    "name": "dataId",
                    "label": "Campo",
                    "source": "JSON",
                    "module": "data",
                    "dataId": this[tab].objectsType,
                    "linkField": "title",
                    "linkValue": "dataId"
                  },
                  {
                    "name": "_id",
                    "label": "Valor",
                    "source": "DB",
                    "module": "data",
                    // "linkField": "label",
                    "linkValue": "_id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]},
    fieldsFilterConnections(company) { return [
      {
        "number": "4",
        "fields": [
          {
            "name": "connections",
            "type": "multilines",
            "fields": [
              {
                "type": "link",
                "fields": [
                  {
                    "name": "dataId",
                    "label": "Banco de Dados",
                    "company": company,
                    "source": "DB",
                    "module": "controls",
                    "dataId": "_data",
                    "linkField": "title",
                    "linkValue": "dataId"
                  },
                  {
                    "name": "_id",
                    "label": "Registro",
                    "company": company,
                    "source": "DB",
                    "module": "data",
                    "linkField": "label",
                    "linkValue": "_id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]},
    fieldsFilterParametros() { return [
      {
        "number": "5",
        "fields": [
          {
            "name": "params",
            "type": 'multilines',
            "fields": [
              {
                "type": "link",
                "columnwidth": "50%",
                "fields": [
                  {
                    "name": "field",
                    "label": 'Campo',
                    "module": "data",
                    "dataId": "parametros",
                    "linkField": "nome"
                  }
                ]
              },
              {
                "name": "operation",
                "label": 'Operação',
                "type": "select",
                "columnwidth": "20%",
                "options": [
                  { "option": { "text": "Igual", "value": "Igual" } },
                  { "option": { "text": "Diferente", "value": "Diferente" } },
                  { "option": { "text": "Maior", "value": "Maior" } },
                  { "option": { "text": "Menor", "value": "Menor" } },
                ]
              },
              {
                "name": "value",
                "label": 'Valor',
                "type": "text",
                "columnwidth": "30%",
                "minwidth": "80px"
              }
            ]
          }
        ]
      }
    ]},
    getSubArray(group, objectsType, clickedObject) {
      if (group == 'clicked') { return [clickedObject] }
      const _array = group == 'notSelected' ? 'objects' : group
      const _subArray = this.Map[_array][objectsType]
      return group == 'notSelected' ? _subArray.filter(item => !this.Map.selected[objectsType].includes(item, 0)) : _subArray
    },
    selectObjects() {
      const points = { minInside: 1 }
      const arrayBase = this.getSubArray(this.select.group, this.select.base, this.select.clickedObject)
      arrayBase.map(objSelected => {
        this.Map.functions.selectPolygonsInside(this.Map, this.select.objectsType, objSelected, points)
        objSelected.setMap(null)
        this.Map.functions.removeFromAllMapArrays(this.Map, this.select.base, objSelected)
        // console.log(' > ', index + 1, ' de ', arrayBase.length)
      })
      this.select.open = false
    },
    multiUpdateObjects() {
      if (this.multiUpdate.group == null) { return this.Map.$store.commit("showError", { data: 'Selecione um grupo para editar.' }) }
      if (this.multiUpdate.objectsType == null) { return this.Map.$store.commit("showError", { data: 'Selecione um tipo de objeto.' }) }
      const objectsToChange = this.multiUpdate.group != 'notSelected' ? this.Map[this.multiUpdate.group][this.multiUpdate.objectsType] : this.Map.objects[this.multiUpdate.objectsType].filter(item => !this.Map.selected[this.multiUpdate.objectsType].includes(item, 0))
      this.Map.functions.multiUpdate(this.Map, objectsToChange, this.multiUpdate.objectsType)
      this.multiUpdate.open = false
    },
    filterObjects() {
      if (this.filter.group == null) { return this.Map.$store.commit("showError", { data: 'Selecione um grupo para filtrar.' }) }
      if (this.filter.objectsType == null) { return this.Map.$store.commit("showError", { data: 'Selecione um tipo de objeto.' }) }
      const objectsToChange = this.filter.group != 'notSelected' ? this.Map[this.filter.group][this.filter.objectsType] : this.Map.objects[this.filter.objectsType].filter(item => !this.Map.selected[this.filter.objectsType].includes(item, 0))
      switch (this.filter.tab) {
        case 0:
          switch (this.filter.company.tab) {
            case 0:
              if (this.filter.company.links.length == 0) { return this.Map.$store.commit("showError", { data: 'Adicione um ou mais dados para filtrar.' }) }
              this.Map.functions.filterObjects(this.Map, objectsToChange, this.filter.objectsType, this.Map.user.company, this.filter.company.links, 'links')
              break
            case 1:
              if (this.filter.company.connections.length == 0) { return this.Map.$store.commit("showError", { data: 'Adicione um ou mais dados para filtrar.' }) }
              this.Map.functions.filterObjects(this.Map, objectsToChange, this.filter.objectsType, this.Map.user.company, this.filter.company.connections, 'connections')
              break
            case 2:
              if (this.filter.company.params.length == 0) { return this.Map.$store.commit("showError", { data: 'Adicione um ou mais dados para filtrar.' }) }
              this.Map.functions.filterObjectsParams(this.Map, objectsToChange, this.filter.objectsType, this.Map.user.company, this.filter.company.params, 'params')
              break
          }
          break
        case 1:
          switch (this.filter.public.tab) {
            case 0:
              if (this.filter.public.links.length == 0) { return this.Map.$store.commit("showError", { data: 'Adicione um ou mais dados para filtrar.' }) }
              this.Map.functions.filterObjects(this.Map, objectsToChange, this.filter.objectsType, this.Map.user.company, this.filter.public.links, 'links')
              break
            case 1:
              if (this.filter.public.connections.length == 0) { return this.Map.$store.commit("showError", { data: 'Adicione um ou mais dados para filtrar.' }) }
              this.Map.functions.filterObjects(this.Map, objectsToChange, this.filter.objectsType, 'public', this.filter.public.connections, 'connections')
              break
          }
          break
        case 2:
          if (this.filter.method == null) { return this.Map.$store.commit("showError", { data: 'Selecione um método.' }) }
          if (typeof this.Map.logicMaps[this.filter.method] !== 'function') { return this.Map.$store.commit("showError", { data: 'Método não encontrado.' }) }
          this.Map.logicMaps[this.filter.method](this.Map, objectsToChange, this.filter.objectsType, this.filter.params)
          break
      }
      this.filter.open = false
    },
    changeColor() {
      if (this.palette.group == null) { return this.Map.$store.commit("showError", { data: 'Selecione um grupo para aplicar.' }) }
      if (this.palette.objectsType == null) { return this.Map.$store.commit("showError", { data: 'Selecione um tipo de objeto.' }) }
      if (this.palette.method == null) { return this.Map.$store.commit("showError", { data: 'Selecione um método.' }) }
      if (typeof this.Map.logicMaps[this.palette.method] !== 'function') { return this.Map.$store.commit("showError", { data: 'Método não encontrado.' }) }
      this.Map.logicMaps[this.palette.method](this.Map, this.palette.group, this.palette.objectsType, this.palette.params)
      this.palette.open = false
    },
    find() {
      const finds = ['findAddress', 'findSetorQuadra']
      this[finds[this.search.tab]]()
    },
    findAddress() {
      if (!this.search.address) { return this.Map.$store.commit("showError", { data: 'Digite um endereço ou CEP.' }) }
      const fullAddress = `${this.search.address}, ${this.search.city}`
      this.Map.functions.setMapCenter({ Map: this.Map, address: fullAddress, zoom: 20, insertMarker: true, buttonInsertTerreno: true })
      this.search.open = false
    },
    async findSetorQuadra() {
      if (this.search.setor == null) { return this.Map.$store.commit("showError", { data: 'Digite um Setor.' }) }
      if (this.search.quadra == null) { return this.Map.$store.commit("showError", { data: 'Digite uma Quadra.' }) }
      this.$store.commit("showOverlay")
      await backend.post({
        company: 'public',
        module: 'data',
        dataId: 'quadras',
        action: 'read/many',
        early: true,
        body: { "query": { sqt: `${this.search.setor}${this.search.quadra}${this.search.tipo}` }, "project": { connections: 1, sqt: 1 } }
      }).then(async res => {
        if (!res.data || res.data.length == 0) { return this.Map.$store.commit("showError", { data: 'Nenhuma quadra foi encontrada.' }) }
        const distrito = js_functions.getConnectionIdByDataId(res.data[0].connections, 'distritos', 'first')
        const distritoObject = this.Map.objects.distritos ? this.Map.objects.distritos.find(_distrito => _distrito.builder.register._id == distrito) : false
        if (distritoObject) {
          distritoObject.setMap(null)
          this.Map.functions.removeFromAllMapArrays(this.Map, 'distritos', distritoObject)
        }
        const query = { connections: { $elemMatch: { dataId: 'distritos', _id: distrito } } }
        const queryFile = `/distrito=${distrito}`
        const _params = { Map: this.Map, array: 'objects', type: 'quadras', field: 'sqt', value: res.data[0].sqt }
        let quadrasObjects = this.Map.functions.getObjectsFromMapArray(_params)
        if (!quadrasObjects || quadrasObjects.length == 0) {
          await this.Map.functions.public.insertQuadras(this.Map, query, queryFile)
          quadrasObjects = this.Map.functions.getObjectsFromMapArray(_params)
        }
        quadrasObjects == false || quadrasObjects.map((quadraObject, index) => {
          quadraObject.setOptions({ strokeColor: '#FF0000', strokeOpacity: 0.8, strokeWeight: 2 })
          index != 0 || this.Map.functions.fitBounds(this.Map.map, quadraObject)
        })
      }).catch(e => { this.$store.commit("showError", e) })
      this.search.open = false
      this.$store.commit("hideOverlay")
    },
    reload() {
      this.Map.reload()
      this.Map.$store.commit("clearMenuSelected", true)
      this.poi.active = true
    },
    turnOnMeasureTool() {
      this.measure.measureTool = this.Map.functions.startToolMeasure({ Map: this.Map.map, mapObjects: this.Map.objects })
      this.measure.measureTool?.start()
      this.measure.active = true
    },
    turnOffMeasureTool() {
      this.measure.measureTool?.end()
      this.measure.active = false
    },
    clickPOI() {
      this.poi.active = !this.poi.active
      this.Map.map.setOptions({ styles: [{ featureType: "poi", elementType: "labels", stylers: [{ visibility: `${this.poi.active ? 'on' : 'off'}` }] }] })
    },
    clickUserCurrentPosition() { this.Map.functions.getUserCurrentPosition({ Map: this.Map, zoom: 20, insertMarker: true, buttonInsertTerreno: false }) },
    clickMeasureButton() { this.measure.active ? this.turnOffMeasureTool() : this.turnOnMeasureTool() },
    removeObjects() {
      const arrayBase = this.getSubArray(this.remove.group, this.remove.objectsType, this.remove.clickedObject)
      arrayBase.map(objSelected => {
        objSelected.setMap(null)
        this.Map.functions.removeFromAllMapArrays(this.Map, this.remove.objectsType, objSelected)
        // console.log(' > ', index + 1, ' de ', arrayBase.length)
      })
      this.remove.open = false
    },
    clickDrawShapeActive() {
      this.drawShapeActive.active = false
      const desactiveShape = {
        Livre: () => {
          this.drawWithMeasure.end()
          this.insertShape({ type: 'Polygon', options: this.shapesConfig, coords: this.Map.functions.polygonInfos.coords })
        },
        Círculo: () => { window.google.maps.event.removeListener(this.listenerClickCircle) },
        Retângulo: () => { window.google.maps.event.removeListener(this.listenerClickRectangle) }
      }
      this.Map.functions.changeClickableAllObjectsInMap(this.Map.objects, true)
      desactiveShape[this.drawShape.forma]()
    },
    insertShape({ type, options, coords }) {
      const object = this.Map.functions.insertMapObject({
        Map: this.Map,
        type,
        name: 'shape',
        label: 'Shape',
        coords,
        options: builder => builder ? options : true,
        info: (builder, object) => {
          if (!builder) { return true }
          const objectInfos = builder.Map.functions.getClickedShapeInfo(builder, object)
          const ifCircle = objectInfos.radius ? `<li><label>Raio:</label> ${objectInfos.radius} m</li>` : ''
          let content = '<h3 class="infowindowTitle">Shape</h3>'
          content += `<h3>${objectInfos.title}</h3>`
          content += `<div class="infoBlock">
              <ul>
                ${ifCircle}
                <li><label>Perímetro:</label> ${objectInfos.perimeter} m</li>
                <li><label>Área:</label> ${objectInfos.area} m²</li>
              </ul>
          </div>`
          return content
        }
      })
      if (type != 'Polygon') { this.clickDrawShapeActive() }
      if (type == 'Circle') {
        const toolBar = this
        window.google.maps.event.addListener(object, 'radius_changed', function() {
          const radiusCircle = js_functions.numberOut(object.getRadius(), 2)
          toolBar.$store.commit("showAlert", { type: 'info', text: `Raio do círculo: ${radiusCircle} metros`, dismissible: true })
        })
      }
    },
    drawShapes() {
      const configShape = {
        Livre: () => {
          this.drawWithMeasure = this.Map.functions.startDrawWithMeasure({ Map: this.Map.map, mapObjects: this.Map.objects })
          this.drawWithMeasure.start()
          this.$store.commit("showAlert", { type: 'info', text: 'Click no mapa para inserir vértices do polígono', dismissible: true, time: 5000 })
        },
        Círculo: () => {
          this.$store.commit("showAlert", { type: 'info', text: 'Click no mapa para inserir o círculo', dismissible: true, time: 5000 })
          this.Map.functions.changeClickableAllObjectsInMap(this.Map.objects, false)
          const toolBar = this
          const callbackCircle = function(event) {
            const lat = event.latLng.lat()
            const lng = event.latLng.lng()
            const escalaZoom = toolBar.Map.map.getZoom()
            toolBar.insertShape({ type: 'Circle', options: { center: { lat, lng }, radius: 10000000/(2**escalaZoom), ...toolBar.shapesConfig } })
          }
          this.listenerClickCircle = window.google.maps.event.addListener(this.Map.map, 'click', callbackCircle)
        },
        Retângulo: () => {
          this.$store.commit("showAlert", { type: 'info', text: 'Click no mapa para inserir o Retângulo', dismissible: true, time: 5000 })
          this.Map.functions.changeClickableAllObjectsInMap(this.Map.objects, false)
          const toolBar = this
          const callbackRectangle = function(event) {
            const lat = event.latLng.lat()
            const lng = event.latLng.lng()
            const escalaZoom = toolBar.Map.map.getZoom()
            toolBar.insertShape({ type: 'Rectangle', options: { ...toolBar.shapesConfig, map: toolBar.Map.map,
              bounds: {
                north: lat-(100/(2**escalaZoom)),
                south: lat+(100/(2**escalaZoom)),
                east: lng+(100/(2**escalaZoom)),
                west: lng-(100/(2**escalaZoom)),
              }
            }})
          }
         this.listenerClickRectangle = window.google.maps.event.addListener(this.Map.map, 'click', callbackRectangle)
        }
      }
      this.drawShapeActive.active = true
      this.drawShape.open = false
      configShape[this.drawShape?.forma]()
    }
  }
}
</script>

<style>
  div#toolBar {
    z-index: 8;
    position: absolute;
    background-color: white;
    width: 45px;
    min-height: 300px;
    top: 50%;
    left: 10px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    padding-top: 6px;
    transform: translateY(-50%);
  }
  @media (max-height: 565px) {
    div#toolBar {
      width: 585px;
      min-height: 45px;
      top: 10px;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: row;
    }
  }
</style>