<template>
  <v-app :style="cssVars">
    <Alert float="true"/>
    <Login v-if="!showApp" @showApp="showApp = true"/>
    <div id="btb" :class="{'hide-menu': !isMenuVisible}" v-else>
      <Dialog />
      <BottomSheet />
      <Overlay />
      <Header v-if="noNotifications" :showLogin="() => { showApp = false }"/>
      <Menu v-if="noNotifications"/>
      <Router v-if="noNotifications"/>
    </div>
  </v-app>
</template>

<script>
import axios from "axios"
import { Page } from '@/lib/core/components/page'
import * as js_functions from '@/lib/js_functions'
import * as backend from '@/lib/backend'
import Alert from '@/components/Alert'
import Dialog from '@/components/Dialog'
import BottomSheet from '@/components/BottomSheet'
import Overlay from '@/components/Overlay'
import Header from "@/components/Header"
import Menu from "@/components/Menu"
import Router from "@/components/Router"
import { userKey, baseFilesUrl } from '@/global'
import { mapState } from 'vuex'
import Login from '@/components/auth/Login'
import * as files from '@/lib/files'
import * as cache from '@/lib/cache'
export default {
  name: "App",
  components: { Header, Menu, Router, Login, Alert, Dialog, BottomSheet, Overlay },
  computed: { 
    ...mapState(['isMenuVisible', 'user']),
    cssVars() { return { ...this.styles } }
  },
  data: () => ({
    userData: {},
    showApp: false,
    noNotifications: false,
    styles: {}
  }),
  watch: {
    showApp() { this.checkNotifications() },
  },
  methods: {
    checkScreen() { 
      if (this.$mq === 'xs' || this.$mq === 'sm') { this.$store.commit('toggleMenu', false) }
      else { this.$store.commit('toggleMenu', true) }
    },
    async validatingToken(userData) {
      this.$store.commit('login', {})
      if (!userData) {
        this.showApp = false
        return false
      }
      const res = await backend.post({
        company: 'validateToken/' + userData.company,
        module: 'data',
        dataId: '_users',
        body: userData
      })
      if (res.valid) {
        this.$store.commit("setPermission", res.permission)
        this.$store.commit('login', userData)
        this.showApp = true
        return true
      }
      else { localStorage.removeItem(userKey) }
      return false
    },
    onError() {
      axios.interceptors.response.use(res => res, error => {
        const status = error.response ? error.response.status : 'network'
        if (status === 401) {
          localStorage.removeItem(userKey)
          this.$store.commit('login', {})
          this.showApp = false
        }
        return Promise.reject({ status: status, data: { ...error } })
      })
    },
    async loadStylesheetIfNotLoaded({ url, retries }) {
      if (document.querySelector(`link[href="${url}"]`)) return
      try {
        const response = await fetch(url)
        if (response.ok) {
          const link = document.createElement('link')
          link.rel = 'stylesheet'
          link.href = url
          document.head.appendChild(link)
        }
        else { if (retries > 0) { setTimeout(() => this.loadStylesheetIfNotLoaded({ url, retries: retries - 1 }), 5000) } }
      }
      catch (e) { if (retries > 0) { setTimeout(() => this.loadStylesheetIfNotLoaded({ url, retries: retries - 1 }), 5000) } }
    },
    changeIcons(company) {
      document.getElementById("favicon").href = `${baseFilesUrl}/${company}/favicon.ico`
      document.getElementById("touchicon").href = `${baseFilesUrl}/${company}/m_logo.png`
    },
    async loadPublicVersions() {
      window.public_versions = await files.get(`public/dataVersions.json`)
      window.getLastVersion = ({ company, name }) => window[`${company}_versions`].find(versions => versions.name == name)?.versions?.getLast()?.dataId
    },
    async loadUniversalLib() {
      const loadScript = async () => {
        try { return await files.loadScript(`_universal/universal.js`) }
        catch(e) { return false }
      }
      return !window.universal ? await loadScript() : false
    },
    async loadCompanyLib(company) {
      const loadScript = async () => {
        try { return await files.loadScript(`${company}/global.js`) }
        catch(e) { return false }
      }
      return !window[company] ? await loadScript() : false
    },
    async checkNotifications() {
      this.$store.commit("showOverlay")
      const notifications = await backend.post({
        company: this.user.company,
        module: 'controls',
        dataId: '_notifications',
        action: 'read/many',
        body: { "query": { status: true } },
      })
      const userNotifications = await backend.post({
        company: this.user.company,
        module: 'controls',
        dataId: '_users',
        action: 'read/one',
        body: { "query": { _id: this.user._id }, "project": { notifications: 1 } },
      })
      this.$store.commit("hideOverlay")
      if (!Array.isArray(notifications) || !userNotifications._id) {
        if (Array.isArray(notifications) && userNotifications == '') { this.$store.commit("showError", { data: "Não foi possível ler as informações do usuário." }) }
        this.showApp = false
        return
      }
      const notificationNotShowed = (notification) => {
        if (!userNotifications.notifications) { return true }
        return !userNotifications.notifications.some(_notification => _notification.notification == notification._id)
      }
      const notificationsNotShowed = notifications.filter(notification => notificationNotShowed(notification))
      this.showNotifications(notificationsNotShowed)
    },
    showNotifications(notificationsNotShowed) {
      if (!notificationsNotShowed || !notificationsNotShowed[0])  { this.noNotifications = true }
      else {
        const notification = notificationsNotShowed.shift()
        this.$store.commit("showDialog", {
          fullscreen: true,
          title: notification.name,
          text: notification.text,
          buttonNo: false,
          buttonYes: notification.answerOptions[0].text,
          action: () => {
            backend.patch({
              module: 'controls',
              dataId: '_users',
              body: { set: { _id: this.user._id }, push: { notifications: {
                notification: notification._id,
                link_notification: notification.name,
                notificationBackup: notification.name,
                answer: notification.answerOptions[0].text,
                dateTime: js_functions.dateToString(new Date(), { addTime: true }),
                log: js_functions.getUserInfo()
              } } },
              early: true
            })
            .then(res => {
              if (res.status == 200) {
                this.$store.commit("hideDialog")
                this.showNotifications(notificationsNotShowed)
              }
              else { throw { data: "Ocorreu um problema ao gravar a resposta da notificação." } }
            }).catch(e => this.$store.commit("showError", e))
          }
        })
      }
    }
  },
  async created() {
    new Page({ global: window, store: this.$store })
    window.importModule = (modulePath) => import('@/lib/core/' + modulePath).then(async (module) => module)
    window.jsf = js_functions
    window.$showAlert = ({ type, text, dismissible = false, time }) => this.$store.commit("showAlert", { type, text, dismissible, time })
    window.addEventListener("orientationchange", function() { document.body.style.zoom = "100%" })
    const json = localStorage.getItem(userKey)
    const userData = JSON.parse(json)
    this.userData = userData
    const _company = window.location.hostname.indexOf('.') > 0 ? window.location.hostname.split('.')[0] : ''
    const company = (userData && userData.company) ? userData.company : _company
    await this.loadStylesheetIfNotLoaded({ url: "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900", retries: 5 })
    await this.loadStylesheetIfNotLoaded({ url: "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css", retries: 5 })
    await this.loadPublicVersions()
    await this.loadUniversalLib()
    await this.loadCompanyLib(company)
    this.changeIcons(company)
    const stylesJSON = await files.get(`${company}/style.json`)
    this.styles = { ...stylesJSON[0] }
    this.$store.commit('setStyles', this.styles)
    const validToken = await this.validatingToken(userData)
    !validToken || await cache.getCacheVersions(company)
    // !validToken || await this.checkNotifications()
    this.checkScreen()
    this.onError()
  }
}
</script>

<style>
  html, body { margin: 0; overflow: auto; }
  @media screen {
    #btb {
      height: 100vh;
      display: grid;
      grid-template-rows: 60px 1fr;
      grid-template-columns: 200px 1fr;
      grid-template-areas:
        "header header"
        "menu router";
    }
    #btb.hide-menu {
      grid-template-areas:
        "header header"
        "router router";
    }
  }
  div#formLines div.line.notMultilines.notTable, div#formLines div.line.notMultilines div.divDataTable table * tr { page-break-inside : avoid !important; }
  @media print {
    @page { size: A4; margin: 0; margin-top: 0.5cm; }
    @page :first { margin-top: 0 }
    body { zoom: 80%; }
    body div.router * { font-size: 9pt !important; }
    #btb {
      display: grid;
      grid-template-rows: 40px 1fr;
      grid-template-areas:
        "header"
        "router";
    }
    
    * {
      -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
      color-adjust: exact !important;                 /*Firefox*/
    }
  }
</style>