"use strict"

export class dialog {
  #openDialog
  #closeDialog
  #dialog = {}
  static empty = { show: false, title: '', text: '', buttonYes: '', buttonNo: '', action: function() { return false } }
  constructor({ data, openDialog, closeDialog }) {
    Object.assign(this.#dialog, (data || dialog.empty))
    this.#openDialog = openDialog
    this.#closeDialog = closeDialog
  }
  open = () => this.#openDialog({ dialog: this.#dialog })
  close = () => this.#closeDialog()
}