<template>
  <div v-if="!$route.query.menuId || canAction('visible')">
    <Breadcrumbs ref="Breadcrumbs"/>
    <Form v-show="showForm" mode="report"/>
    <div v-show="!showForm" class="noMenu">Acesse um item do menu</div>
  </div>
  <div v-else class="noMenu">Você não tem permissão para acessar este relatório.</div>
</template>

<script>
import Breadcrumbs from './Breadcrumbs'
import Form from './Data/Form/Form'
export default {
  name: "Reports",
  components: { Breadcrumbs, Form },
  data: () => ({
    showForm: false,
  }),
  methods: {
    canAction(action) { return this.$store.getters.canAction(action) }
  },
  created() {
    this.$watch(
      () => this.$route.query.menuId,
      (menuId) => { this.showForm = (menuId && menuId != "") ? true : false },
      { immediate: true }
    )
  }
}
</script>

<style>
</style>