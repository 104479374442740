
import * as jsf from '@/lib/js_functions'

const trim = string => !string || string.trim()
const getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
const tagToObject = (tag) => {
  if (tag.children.length > 0) {
    const fieds = Array.prototype.slice.call(tag.children)
    let obj = {}
    fieds.map(field => {
      const key = field.nodeName.split("_").pop() == tag.getAttribute('number') ? field.nodeName.substring(0, field.nodeName.lastIndexOf('_')) : field.nodeName
      Object.assign(obj, { [key]: trim(field.firstChild.nodeValue) })
    })
    return obj
  }
  else { return { [tag.nodeName]: trim(tag.firstChild.nodeValue) } }
}
const getKmlFromKmz = kmzFile => {
  var zip = new JSZip()
  return zip.loadAsync(kmzFile).then(zip => {
    let kmlDom = null
    zip.forEach((relPath, file) => {
      if (getExtension(relPath) === "kml" && kmlDom === null) { kmlDom = file.async("string").then(getDom) }
    })
    return kmlDom || Promise.reject("No kml file found")
  })
}
const getJsonFromKml = kmlDom => {
  const placemarks = [...kmlDom.getElementsByTagName('Placemark')]
  let array = []
  placemarks.map(placemark => {
    let data = { name: placemark.getElementsByTagName('name')[0]?.firstChild.nodeValue }
    const simpleDatas = [...placemark.getElementsByTagName('SimpleData')]
    simpleDatas.map(simpleData => {
      if (simpleData.getAttribute('name') && simpleData.firstChild && simpleData.firstChild.nodeValue) {
        Object.assign(data, { [simpleData.getAttribute('name')]: trim(simpleData.firstChild.nodeValue) })
      }
    })
    const coordinatesArray = [...placemark.getElementsByTagName('coordinates')]
    coordinatesArray.map(coordinates => {
      let register = { ...data }
      if (coordinates.firstChild && coordinates.firstChild.nodeValue) {
        Object.assign(register, { coordinates: trim(coordinates.firstChild.nodeValue) })
      }
      array.push(register)
    })
  })
  return array
}
const getJsonFromXml = xmlData => {
  const tags = [].slice.call(xmlData.children)
  return tags[0].namespaceURI == 'urn:schemas-microsoft-com:office:spreadsheet' ? getJsonFromExcelXml(xmlData) : getJsonFromManualXml(xmlData)
}
const getJsonFromExcelXml = xmlData => {
  const table = xmlData.getElementsByTagName('Table')[0]
  const cleamTable = table.getElementsByTagName('Row')
  const firstLine =  Array.prototype.slice.call(cleamTable).shift()
  const headers = Array.prototype.slice.call(firstLine.children).map(cell => cell.children[0].childNodes[0].data.replace(' ', '_'))
  let registers = []
  Array.prototype.slice.call(cleamTable).map(line => {
    let register = {}
    Array.prototype.slice.call(line.children).map((cell, index) => {
      const value = cell.children[0]?.childNodes[0]?.data ? cell.children[0].childNodes[0].data : ''
      const valueNotBlank = value == '###' ? '' : value
      Object.assign(register, { [`${headers[index]}`]: valueNotBlank })
    })
    registers.push(register)
  })
  return registers
}
const getJsonFromManualXml = xmlData => {
  const tags = [].slice.call(xmlData.children[0].children)
  return tags.map(register => {
    let obj = {}
    Array.prototype.slice.call(register.attributes).map(attribute => Object.assign(obj, { [attribute.name]: attribute.nodeValue }))
    Array.prototype.slice.call(register.children).map(tag => {
      const tagsColection = Array.prototype.slice.call(register.getElementsByTagName(tag.nodeName))
      if (tagsColection.length > 1 || tag.getAttribute('number')) {
        Object.assign(obj, { [tag.nodeName]: tagsColection.map(_tag => { return tagToObject(_tag) }) })
      }
      else { Object.assign(obj, tagToObject(tag)) }
    })
    return obj
  })
}
const readFileAndExecute = (file, action, type) => {
  const typeAction = type == 'json' ? JSON.parse : getDom
  const myPromise = new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsText(file)
    reader.onloadend = function() { resolve(action(typeAction(reader.result))) || reject("Erro ao carregar o arquivo!") }
  })
  return myPromise
}
const stringDateToDate = (stringDate) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
  const stringDateWithoutDe = stringDate.replaceAll('de ', '')
  const date = meses.reduce((_date, mes, index) => _date.replace(mes, months[index]), stringDateWithoutDe)
  return new Date(Date.parse(date))
}
const excelDateToDate = (serial) => {
  const utc_days  = Math.floor(serial - 25569)
  const utc_value = utc_days * 86400
  return new Date(utc_value * 1000)
}
export function getExtension(fileName) { return fileName.split(".").pop() }
export function kmz_to_json(kmzFile) { return getKmlFromKmz(kmzFile).then(kmlDom => getJsonFromKml(kmlDom)) }
export function kml_to_json(kmlFile) { return readFileAndExecute(kmlFile, getJsonFromKml) }
export function xml_to_json(xmlFile) { return readFileAndExecute(xmlFile, getJsonFromXml) }
export function json_to_json(jsonFile) { return readFileAndExecute(jsonFile, file => file, 'json') }
export function coords_trasformation(coords) { return [coords.split(' ').map(latlng => { return { lat: Number(trim(latlng.split(',')[1])) ,lng: Number(trim(latlng.split(',')[0])) } })] }
export function date_trasformation(date) { return jsf.dateToString(isNaN(date) ? stringDateToDate(date) : excelDateToDate(date)) }
