<template>
  <Form mode="new"/>
</template>

<script>
import Form from './Form/Form'
export default {
  name: "New",
  components: { Form }
}
</script>

<style>

</style>