<template>
  <div v-if="field.show == undefined || field.show" :class="['divDataTable', field.class]">
    <v-card>
      <v-card-title v-if="field.search == undefined ? true : field.search">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busca"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="field.headers"
        :custom-sort="customSort"
        multi-sort
        :items="Array.isArray(field.items) ? field.items : vmodel[field.name]"
        :search="search"
        :no-data-text="'Nenhum registro encontrado'"
        :no-results-text="'A busca não encontrou nenhum resultado'"
        :hide-default-footer="field.footer == undefined ? false : !field.footer"
        :items-per-page="field.itemsPerPage"
        :footer-props="{
          'items-per-page-text': 'Linhas por página',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [ 10, 20, 50, 100, -1 ]
        }"
        :single-expand="field.singleExpand"
        :expanded.sync="expanded"
        item-key="key"
        :show-expand="field.expand"
      >
        <template slot="header" v-if="'preHeaders' in field">
          <thead><tr><th v-for="(preHeader, index) in field.preHeaders" :key="index" class="preHeader" :class="{ [preHeader.class]: preHeader.class, ['show']: preHeader.text != '' }" :colspan="preHeader.span">{{preHeader.text}}</th></tr></thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr @click="rowClicked(item, field.headers)" :class="getItemClass(item)" :style="getItemBackgroundColor(item)">
            <template v-for="(header, index) in field.headers.filter(header => header.value != 'data-table-expand')">
              <td v-if="item[header.value] || item[header.value] == ''" :key="index" :colspan="item[`colspan_${header.value}`]" @click="cellClicked(item, header, index)" :class="{ [`text-${header.align}`]: header.align, [header.class]: header.class }" v-html="item[header.value]"></td>
            </template>
            <td v-for="(header, index) in field.headers.filter(header => header.value == 'data-table-expand')" :key="`expand${index}`" @click="field.expandAll || expand(!isExpanded); return false" :class="{ [`text-${header.align}`]: header.align, [header.class]: header.class }"><v-icon>{{ isExpanded || field.expandAll ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></td>
          </tr>
          <tr v-if="field.expandAll" :class="getItemClass(item)" :style="getItemBackgroundColor(item)">
            <td :colspan="field.headers.length" @click="cellClicked(item)" v-html="item.expand"></td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <tr v-if="field.expand && !field.expandAll" :class="getItemClass(item)" :style="getItemBackgroundColor(item)">
            <td :colspan="headers.length" v-html="item.expand"></td>
          </tr>
        </template>
        <template slot="foot" v-if="'footers' in field">
          <tfoot><tr><th v-for="(footer, index) in field.footers" :key="index" :class="footer.class" v-html="footer.text"></th></tr></tfoot>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: ['FormLines', 'logic', 'field', 'vmodel'],
  data: () => ({
    search: '',
    expanded: []
  }),
  methods: {
    getItemClass: function(item) { return item.class ? item.replaceClassSpace ? item.class.replace(' ', '_') : item.class : '' },
    getItemBackgroundColor: function(item) { return item.backgroundColor ? { backgroundColor: item.backgroundColor } : '' },
    rowClicked(data, headers) {
      if (typeof this.logic[this.field.rowClicked] == 'function') { this.logic[this.field.rowClicked](this.$parent.$parent, data, headers) }
    },
    cellClicked(data, header, index) {
      if (typeof this.logic[this.field.cellClicked] == 'function') { this.logic[this.field.cellClicked](this.$parent.$parent, data, header, index) }
    },
    getItems() {
      if (typeof this.logic[this.field.items] == 'function') { this.logic[this.field.items](this.FormLines) }
    },
    customSort(items, sortBy, sortDesc) {
      sortBy = Array.isArray(sortBy) && sortBy.length > 0 ? sortBy.reverse() : []
      sortDesc = Array.isArray(sortDesc) && sortDesc.length > 0 ? sortDesc.reverse() : []
      sortBy.map((activeSortColumn, index) =>
        items.sort((a, b) => {
          if (!a[activeSortColumn] || !b[activeSortColumn]) { return false }
          const isNumberCol = this.field.headers.find(header => header.dataType == 'number' && header.value == activeSortColumn)
          const diff = (_a, _b) => isNaN(Number(_a)) || isNaN(Number(_b)) ? _a.localeCompare(_b) : Number(_a) - Number(_b)
          if (isNumberCol) { return sortDesc[index] ? diff(b[activeSortColumn], a[activeSortColumn]) : diff(a[activeSortColumn], b[activeSortColumn]) }
          const isDate = this.field.headers.find(header => header.dataType == 'date' && header.value == activeSortColumn)
          const _a = isDate ? a[activeSortColumn].split('/').reverse().join('') : a[activeSortColumn]
          const _b = isDate ? b[activeSortColumn].split('/').reverse().join('') : b[activeSortColumn]
          return sortDesc[index] ? ('' + _b).localeCompare(_a) : ('' + _a).localeCompare(_b)
        })
      )
      sortBy = Array.isArray(sortBy) && sortBy.length > 0 ? sortBy.reverse() : []
      sortDesc = Array.isArray(sortDesc) && sortDesc.length > 0 ? sortDesc.reverse() : []
      return items
    }
  },
  created() { this.vmodel[this.field.name] = [] },
  mounted() { this.getItems() }
}
</script>

<style>
  div.divDataTable { width: 100%; }
  div.divDataTable > div.v-sheet.v-card:not(.v-sheet--outlined) {
     box-shadow: none;
     border-radius: 0px;
   }
  div.divDataTable .v-card__title {
    flex-direction: row-reverse;
    padding: 5px;
  }
  div.divDataTable .v-card__title .v-text-field {
    flex: none;
    width: 50%;
    padding: 0px;
    margin: 0px;
  }
  div.divDataTable div.v-data-table thead tr th.preHeader { background: none; }
  div.divDataTable div.v-data-table thead tr th.preHeader.show { background-color: #AAAAAA; color: #FFFFFF; }
  div.divDataTable div.v-data-table thead span { color: black; }
  div.divDataTable div.v-data-table thead tr th { background-color: lightgray; }
  div.divDataTable div.v-data-table thead tr th.active { background-color: gray; }
  div.divDataTable div.v-data-table tfoot tr th { background-color: lightgray; }
  div.divDataTable div.v-data-table tr { cursor: pointer; }
  div.divDataTable div.v-data-table tr > * { border: 2px solid white; }
  div.divDataTable div.v-card .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd) { background-color: #FAFAFA; }
  div.divDataTable div.v-card .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(even) { background-color: #EFEFEF; }
  div.divDataTable div.v-card .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th { border: 2px solid white; }
  div.divDataTable div.v-card .theme--light.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th { border: 2px solid white; }
  div.divDataTable div.v-card .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td { border: 2px solid white; }
  div.divDataTable div.v-card .v-data-table .v-data-footer { border-top: none; }
  @media print {
    div.divDataTable .v-card__title { display: none; }
    div.line, div.divDataTable tbody { page-break-inside: auto !important; }
    div.divDataTable table tfoot { display: table-row-group !important; }
  }
</style>