import * as backend from '@/lib/backend'
import * as files from '@/lib/files'

export async function getDBOptions(params) {
  return await backend.post({ ...params, early: true, action: 'read/many' }).then(res => {
    if (res.status && res.status != 200) { throw { data: "Ocorreu um problema ao listar." } }
    let _options = res.data.map(e => ({ option: { text: getLink(e, params.linkField), value: e[params.linkValue], dataId: e.dataId, company: params.company } })).sort((a, b) => a.option.text.localeCompare(b.option.text))
    !Array.isArray(_options) || _options.unshift({ "option": { "text": "", "value": "" } })
    return Array.isArray(_options) ? _options : []
  }).catch(e => { console.log({ getDBOptionsError: e }); return [] })
}

export async function getJSONOptions(params) {
  try {
    const { company, dataId, folder, parent } = params
    if (!dataId || dataId == '') return []
    let fields = await files.get(`${company}/${dataId}/fields.json`)
    if (!Array.isArray(fields) && folder != undefined) { fields = await files.get(`${company}/${folder}/fields.json`) }
    if (!Array.isArray(fields)) {
      const _folder = await getFolderOfDataId(dataId)
      if (_folder != undefined) { fields = await files.get(`${company}/${_folder}/fields.json`) }
    }
    if (fields.status && fields.status != 200) { throw { data: "Ocorreu um problema ao listar." } }
    let options = []
    const fillOptions = (_field) => {
      if (_field.type == 'link') {
        _field.fields.map(subColunm => {
          options.push({ option: { text: `${subColunm.label ? subColunm.label + ' ' : ''}[${subColunm.name}]`, value: subColunm.name, dataId, company } })
          options.push({ option: { text: `${subColunm.label ? subColunm.label + ' ' : ''}[${subColunm.name}] (link)`, value: `link_${subColunm.name}`, dataId, company } })
        })
      }
      else if (_field.type == 'linkSimple') {
        options.push({ option: { text: `${_field.label ? _field.label + ' ' : ''}[${_field.name}]`, value: _field.name, dataId, company } })
        options.push({ option: { text: `${_field.label ? _field.label + ' ' : ''}[${_field.name}] (link)`, value: `link_${_field.name}`, dataId, company } })
      }
      else { options.push({ option: { text: `${_field.label ? _field.label + ' ' : ''}[${_field.name}]`, value: _field.name, dataId, company } }) }
    }
    fields.map(line => !line.fields || line.fields.map(_field => {
      if (parent) {
        if (_field.name == parent && _field.type == 'multilines') {
          _field.fields.map(colunm => { fillOptions(colunm) })
        }
      }
      else { fillOptions(_field) }
    }))
    return Array.isArray(options) ? options.sort((a, b) => (a.option.text && b.option.text) ? a.option.text.localeCompare(b.option.text) : -1) : []
  }
  // catch(e) { obj.$store.commit("showError", e) }
  catch(e) { return e }
}

export function sourceFunction(source) { return (source == 'JSON') ? getJSONOptions : getDBOptions }

export async function configAndGetOptions(obj, params) {
  const { name, dataId, source, cache, cacheName, updateCache } = params
  if (!dataId) { return }
  params.company = params.company ?? obj.user.company
  params.module = params.module ?? obj.FormLines.$route.name
  params.linkValue = params.linkValue ?? '_id'
  const body = createBody(params)
  if (cache) {
    const multilines = obj.items ? obj.parent : obj
    const cacheArray = multilines.links[name]
    if (!updateCache) {
      await getOptionsCached(obj, { ...params, body, cacheArray })
    }
    else {
      cacheArray[cacheName] = obj.items = await sourceFunction(source)(params)
    }
  }
  else {
    const options = await sourceFunction(source)({ ...params, body })
    obj.items = Array.isArray(options) ? options : []
  }
  return
}

async function getOptionsCached(obj, params) {
  const { cacheArray, source, cacheName } = params
  if (cacheArray[cacheName]) { obj.items = cacheArray[cacheName] }
  else { cacheArray[cacheName] = obj.items = await sourceFunction(source)(params) }
  return
}

export async function getDefaultFieldName(company, dataId, folder) {
  if (!dataId || dataId == '') return ''
  let list = await files.get(`${company}/${dataId}/list.json`)
  if (!Array.isArray(list) && folder != undefined) { list = await files.get(`${company}/${folder}/list.json`) }
  if (!Array.isArray(list)) {
    const _folder = await getFolderOfDataId(dataId)
    if (_folder != undefined) { list = await files.get(`${company}/${_folder}/list.json`) }
  }
  if (!list[0]) { return '' }
  return list[0].default ? list[0].default.value : list[0].value
}

async function getFolderOfDataId(dataId) {
  const menu = await getMenuOfDataId(dataId)
  return (menu && menu.folder) ? menu.folder : undefined
}

async function getMenuOfDataId(dataId) {
  return await backend.post({
    module: 'controls',
    dataId: '_data',
    action: 'read/one',
    body: { "query": { dataId }, "project": { folder: 1 } }
  })
}

function getLink(register, linkFieldsNames) {
  const _linkFieldsNames = (linkFieldsNames.constructor === Array) ? linkFieldsNames : [linkFieldsNames]
  return _linkFieldsNames.reduce((text, fieldName) => text += register[fieldName] ? `${register[fieldName]} - ` : '', '').slice(0, -3)
}

function createBody(params) {
  const { linkField, linkValue, field, value, query } = params
  const projectFields = { }
  const _linkField = Array.isArray(linkField) ? linkField : [linkField]
  _linkField.map(project => project == '' || Object.assign(projectFields, { [project]: 1 }))
  let body = { "project": { ...projectFields, [linkValue]: 1 } }
  if (query || (field && value)) { Object.assign(body, { "query": { ...query, [field]: value } }) }
  return body
}