<template>
  <div>
    <v-bottom-sheet v-model="bottomSheet.show" persistent hide-overlay content-class="bottomSheet">
      <v-sheet class="bg text-center py-5">
        <h3>{{ bottomSheet.title }}</h3>
        <div class="py-2 mb-4">{{ bottomSheet.text }}</div>
        <v-btn color="grey lighten-1" @click="actionNo">{{ bottomSheet.buttonNo }}</v-btn>
        <v-btn color="success" @click="action" class="ml-2">{{ bottomSheet.buttonYes }}</v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "BottomSheet",
  computed: { ...mapState(['bottomSheet']) },
  methods: {
    action() {
      this.bottomSheet.action()
      this.$store.commit("hideBottomSheet")
    },
    actionNo() {
      this.bottomSheet.actionNo?.()
      this.$store.commit("hideBottomSheet")
    },
  }
}
</script>

<style>
  .bottomSheet { white-space: pre-wrap; }
  .bottomSheet .bg { background-color: #FEFEFE; }
</style>