<template>
<div v-if="field.type === 'link' && copied() && (field.show == undefined || field.show)" class="link" :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
  <template v-for="(column, index) in fields">
    <FieldLinkSelect v-if="multiline == undefined" ref="links" :key="`${column.name}`" :field="column" :vmodel="vmodel" :logic="logic" :FormLines="FormLines" :parent="parent()" :index="index"/>
    <FieldLinkSelect v-else :key="`${column.name}_${line}`" ref="links" :field="column" :vmodel="vmodel" :multiline="multiline" :line="line" :logic="logic" :FormLines="FormLines" :parent="parent()" :index="index"/>
  </template>
</div>
</template>

<script>
import FieldLinkSelect from './FieldLinkSelect'
import * as get_options from '@/lib/get_options'
import { mapState } from 'vuex'
export default {
  name: "FieldLink",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { FieldLinkSelect },
  computed: { ...mapState(['user', 'register']) },
  data: () => ({
    fields: [],
    loaded: []
  }),
  watch: {
    loaded(value) { if (value.length > 0) { this.changeLoad(value.pop()) } },
    'field.fields': {
      deep: true,
      handler(fields) {
        fields.map((model, index) => {
          for (const property in model) {
            const copy = this.fields[index]
            if (!copy[property] || copy[property] != model[property]) { copy[property] = model[property] }
          }
          this.loaded.includes(index)
        })
      }
    }
  },
  methods: {
    parent() { return this.$parent },
    async changeField(index, optionSelected, clear) {
      let next = this.fields[index + 1]
      if (!next) return
      const { company, dataId, value } = optionSelected
      const source = this.fields[index].source
      if (!next.company) { next.company = (company & company != undefined) ? company : this.user.company } // company only pre-exists on index = 0 child fields and Map > Filter > Public Data > Connections
      next.dataId = dataId // dataId only pre-exists on index = 0 child fields
      next.linkField = (source != 'JSON') ? await get_options.getDefaultFieldName(next.company, value, next.folder) : value
      next.cacheName = (source != 'JSON') ? dataId : value
      next.parent = (source == 'JSON') ? value : null
      this.$refs['links'].map((field, _index) => {
        if (_index > index) {
          if (clear) { this.vmodel[this.fields[_index].name] = [] }
          field.key++
        }
      })
      return
    },
    async changeLoad(index) {
      const field = this.$refs['links'][index]
      const _value = Array.isArray(field.vmodel[field.field.name]) ? field.vmodel[field.field.name][0] : field.vmodel[field.field.name]
      const item = field.items.find(item => item.option.value == _value)
      if (item) { await this.changeField(index, item.option, false) }
    },
    copied() { return this.fields.length == this.field.fields.length }
  },
  mounted() {
    this.field.fields.map(_field => this.fields.push({ ..._field }))
  }
}
</script>

<style scoped>
  div.link { display: flex; flex-grow: 1; }
</style>