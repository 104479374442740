<template>
  <div :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
    <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top v-if="field.type === 'storage' && (field.show == undefined || field.show)">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs" v-on="on"
          v-if="field.type === 'storage' && (field.show == undefined || field.show)"
          :key="field.name"
          :name="field.name"
          :ref="field.name"
          :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
          v-model="vmodel[field.name]"
          :type="field.type"
          :solo="field.solo"
          :outlined="!field.solo"
          autocomplete="new-password"
          autocorrect="off"
          autocapitalize="off"
          dense
          hide-details
          :prepend-inner-icon="field.prependInnerIcon"
          :suffix="field.suffix"
          :readonly="true"
          :counter="field.maxlength"
          :rules="FormLines.rules(field)"
          :class="{ 'disabled': field.disabled || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
          :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
          :background-color="field.backgroundcolor"
          v-on:keyup="event(field.keyup)"
          @click="event(field.click)"
          :color="FormLines.styles['--over-color']"
          :autofocus="field.focus"
        >
          <template slot="append">
            <v-icon v-for="(icon, index) in FormLines.showIcon(field, line)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
            <v-icon v-if="!vmodel[field.name] && canStorage('upload')" @click="clickUpload" class="hover">mdi-file-upload-outline</v-icon>
            <v-icon v-if="vmodel[field.name] && canStorage('download')" @click="clickDownload" class="hover">mdi-file-download-outline</v-icon>
            <v-icon v-if="vmodel[field.name] && canStorage('remove')" @click="clickRemove" class="hover">mdi-file-remove-outline</v-icon>
            <AppendIconTooltip :field="field" />
          </template>
        </v-text-field>
      </template>
      <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog.show" persistent :max-width="dialog.width || '570'" scrollable content-class="withTable" :fullscreen="dialog.fullscreen">
      <v-card>
        <v-card-title class="headline"><v-icon x-large>{{ dialog.icon.name }}</v-icon>{{ dialog.title }}</v-card-title>
        <v-card-text v-if="dialog.type == 'upload'" >
          <v-file-input :rules="maxSizeRule(file)" outlined dense label="Escolha um arquivo" truncate-length="50" variant="outlined" v-model="file" @change="changeNameFile" @click:clear="file = null" show-size></v-file-input>
          <v-switch v-show="heicFile.showButton" v-model="heicFile.convertFileInJPG" :true-value="true" :false-value="false" label="Converter arquivo de HEIC para JPG"></v-switch>
          <v-text-field label="Renomear" hide-details v-model="fileName.name"></v-text-field>
        </v-card-text>
        <v-card-text v-if="dialog.type == 'remove'" >
          <v-text-field label="Arquivo" outlined dense hide-details readonly v-model="vmodel[field.name]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="dialog.buttonNo != false" color="grey lighten-1" @click="dialog.show = false">Cancelar</v-btn>
          <v-btn v-if="dialog.buttonYes != false" :color="dialog.buttonYesColor" @click="dialog.action">{{ dialog.buttonYes }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as storage from '@/lib/storage'
import * as downloads from '@/lib/downloads'
import exifr from 'exifr'
import heic2any from 'heic2any'
import { mapState } from 'vuex'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldStorage",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { AppendIconTooltip },
  computed: { ...mapState(['register']) },
  data: () => ({
    files: [],
    file: null,
    fileName: {
      name: '',
      extention: '',
    },
    dialog: { show: false, icon: {} },
    heicFile: { showButton: false, convertFileInJPG: true },
    metadados: null,
    settings: {}
  }),
  methods: {
    maxSizeRule(file) {
      if (file?.size > this.settings.fileMaxSize) {
        this.dialog.buttonYes = false
        return [`O tamanho máximo permitido é de ${this.FormLines.Form.js_functions.formatFileSize(this.settings.fileMaxSize, 0)}!`]
      }
      else {
        this.dialog.buttonYes = 'Fazer upload'
        return [true]
      }
    },
    disabled() { return this.field.disabled || this.blockField(this.field.name, this.multiline) || this.blockLine() },
    setFileNameAndExtention() {
      const fullName = this.file?.name ? this.file.name : ''
      const pontoIndex = fullName.lastIndexOf(".")
      const name = fullName.substring(0, pontoIndex)
      const extention = fullName.substring(pontoIndex + 1)
      this.fileName = { name, extention }
    },
    getFileFullName() { return `${this.fileName.name}.${this.fileName.extention}` },
    changeNameFile() {
      this.setFileNameAndExtention()
      if (!this.file) return
      this.heicFile.showButton = this.file.type === 'image/heic' ? true : false
    },
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    canStorage(action) { return this.$store.getters.canStorage(action) },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], vmodel: this.vmodel, lineNumber: this.line, metadados: this.metadados }) } },
    dialogConfig(action) {
      return {
        upload: {
          type: "upload",
          show: true,
          action: this.uploadFile,
          buttonYes: 'Fazer upload',
          buttonYesColor: 'success',
          title: 'Upload',
          icon: { color: '#00FF00', name: 'mdi-file-upload-outline' }
        },
        remove: {
          type: "remove",
          show: true,
          action: this.removeFile,
          buttonYes: 'Remover arquivo',
          buttonYesColor: 'error',
          title: 'Remover',
          icon: { color: '#00FF00', name: 'mdi-file-remove-outline' }
        }
      }[action]
    },
    async clickUpload() {
      if (this.disabled()) { return }
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'settings' }, func: () => storage.getSettings(this.FormLines.user.company) })
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkIfRegisterIsSaved(FormLines) })) { return this.$store.commit("hideOverlay") }
      this.dialog = this.dialogConfig('upload')
      this.$store.commit("hideOverlay")
    },
    clickDownload() {
      if (this.disabled()) { return }
      this.downloadFile()
    },
    async clickRemove() {
      if (this.disabled()) { return }
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkIfRegisterIsSaved(FormLines) })) { return this.$store.commit("hideOverlay") }
      this.dialog = this.dialogConfig('remove')
      this.$store.commit("hideOverlay")
    },
    checkIfRegisterIsSaved(FormLines) {
      if (!FormLines.register._id) { return { status: false, alertType: 'warning', alertText: 'É necessário gravar o registro antes de fazer upload de arquivos.' } }
      return { status: true }
    },
    getStorageParams(fileName) {
      return {
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        _id: this.FormLines.register._id,
        ...(this.field.folder && { folder: this.field.folder }),
        fileName: fileName ? fileName : this.vmodel[this.field.name],
        early: true
      }
    },
    checkIfFileIsSelected() {
      if (!this.file) { return { status: false, alertType: 'warning', alertText: 'Selecione um arquivo.' } }
      return { status: true }
    },
    checkIfFileAtualNameIsNotEmpty() {
      if (!this.fileName.name) { return { status: false, alertType: 'warning', alertText: 'Preencha o nome do arquivo.' } }
      return { status: true }
    },
    checkIfExistsFileWithAtualName() {
      if (this.files.find(file => file.name == this.getFileFullName())) { return { status: false, alertType: 'warning', alertText: 'Já existe um arquivo com este nome vinculado a este registro. Por favor utilize outro nome.' } }
      return { status: true }
    },
    async uploadFile() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkIfFileIsSelected() })) { return this.$store.commit("hideOverlay") }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkIfFileAtualNameIsNotEmpty() })) { return this.$store.commit("hideOverlay") }
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => this.checkIfExistsFileWithAtualName() })) { return this.$store.commit("hideOverlay") }
      if (this.file.type.startsWith("image")) { this.metadados = await exifr.parse(this.file) }
      if (this.file.type === 'image/heic' && this.heicFile.convertFileInJPG) { this.file = await this.convertHEICtoJPEG(this.file) }
      var formData = new FormData()
      formData.append("file", this.file)
      const params = { ...this.getStorageParams(this.getFileFullName()), formData: formData }
      if (await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this.vmodel, name: this.field.name }, func: () => storage.uploadFile(params) })) {
        this.event(this.field.change)
      }
      if (this.file.type.startsWith("image")) { this.event(this.field.metadados) }
      this.dialog.show = false
      this.$store.commit("hideOverlay")
    },
    async convertHEICtoJPEG(file) {
      try {
        const fileToBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { file: file.type })
        const blob = await fileToBlob(file)
        const outputBuffer = await heic2any({ blob, toType: 'image/jpeg', quality: 0.8 })
        this.fileName.extention = 'jpg'
        return new File([outputBuffer], `${this.fileName.name}.jpg`, { type: 'image/jpeg' })
      } catch (error) { console.error('Erro ao converter o arquivo:', error) }
    },
    async downloadFile() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      const _this = {}
      const params = this.getStorageParams()
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: _this, name: 'link' }, func: () => storage.downloadFile(params) })) { return this.$store.commit("hideOverlay") }
      downloads.downloadStorageFile({ url: _this.link, name: this.vmodel[this.field.name] })
      this.$store.commit("hideOverlay")
    },
    async removeFile() {
      this.$store.commit("showOverlay")
      const FormLines = this.FormLines
      const params = this.getStorageParams()
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, func: () => storage.removeFile(params) })) { return this.$store.commit("hideOverlay") }
      this.vmodel[this.field.name] = ''
      this.dialog.show = false
      this.$store.commit("hideOverlay")
    },
    async listFiles() {
      const FormLines = this.FormLines
      const params = this.getStorageParams()
      if (!await FormLines.Form.js_functions.getFunc({ FormLines, receiver: { object: this, name: 'files' }, func: () => storage.listFiles(params) })) { return this.$store.commit("hideOverlay") }
    },
    clearIfRemoved() {
      if (!this.files.find(file => file.name == this.vmodel[this.field.name] || file.relativePath == this.vmodel[this.field.name])) { this.vmodel[this.field.name] = '' }
    }
  },
  async mounted() {
    await this.listFiles()
    this.clearIfRemoved()
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  .inLineButton.outer {
    margin-top: -6px;
  }
  .v-dialog > .v-card > .v-card__title { padding: 10px 10px; }
  .v-dialog > .v-card > .v-card__text { padding: 10px 15px; }
</style>