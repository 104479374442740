<template>
  <div id="formLines" v-if="$route.query.menuId != '' || mode == 'map'" :class="$route.query.menuId">
    <div v-if="canAction('import')" class="line actions functionsLine">
      <v-btn @click="event('showImport')" outlined class="actionButton" :class="{ 'active': actionIsActive('showImport') }" :color="styles['--over-color']"><v-icon medium>mdi-upload</v-icon></v-btn>
      <Import2 v-if="actionsActive.includes('showImport')" :Form="Form" :FormLines="mySelf()"/>
    </div>
    <template v-for="(lines, i) in fields">
      <v-expansion-panels v-if="lines.lineType == 'painel' && (lines.show == undefined || lines.show)" :key="i" class="painel" :value="lines.opened ? 0 : -1">
        <v-expansion-panel>
          <v-expansion-panel-header>{{ lines.lineHeader }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="(_lines, j) in lines.lines">
              <LineFields :key="j" :lines="_lines" :FormLines="mySelf()" :vmodel="vmodel" :register="register" :logic="logic"/>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <LineFields v-if="lines.lineType != 'painel' && (lines.show == undefined || lines.show)" :key="i" :lines="lines" :FormLines="mySelf()" :vmodel="vmodel || register" :logic="logic"/>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Import2 from './Import2'
import LineFields from './LineFields'
import * as masks from '@/lib/masks'
export default {
  name: "FormLines",
  components: { Import2, LineFields },
  computed: { ...mapState(['user', 'dataItens', 'register', 'registerOriginal', 'styles', 'print']) },
  props: ['mode', 'fields', 'logic', 'Form', 'vmodel'],
  data: () => ({
    components: { FieldMap: [], FieldChart: [] },
    actionsActive: []
  }),
  methods: {
    mySelf() { return this },
    canAction(action) { return this.$store.getters.canAction(action) },
    actionIsActive(method) { return this.actionsActive.includes(method) },
    event(functionName) {
      this.actionIsActive(functionName) ? this.actionsActive = this.actionsActive.filter(action => action !== functionName) : this.actionsActive.push(functionName)
      if (functionName && this.logic && this.logic[functionName]) { this.logic[functionName]({ parent: this }) }
    },
    showIcon(field, line) {
      const onlyoneError = () => (Array.isArray(field.onlyoneError) && field.onlyoneError.length == 0) ? false : field.onlyoneError
      let icons = []
      !field.required || icons.push({ name: 'mdi-alert-circle', error: field.requiredError })
      !field.onlyone || icons.push({ name: 'mdi-numeric-1-circle', error: (line != undefined && field.onlyoneError) ? field.onlyoneError.includes(line) : onlyoneError() })
      return icons
    },
    rules(field) { return [this.required(field)] },
    required(field) { return value => (!field.required || !!value) || 'Campo obrigatório!' },
    clearOnlyoneError(multilines, lineIndex) {
      const totalLines = (multilines == undefined) ? this.fields.length : 1
      for (let lineNumber = 0; lineNumber < totalLines; lineNumber++) {
        const line = (multilines == undefined) ? this.fields[lineNumber] : multilines
        if (line.fields && Array.isArray(line.fields)) {
          for (let fieldNumber = 0; fieldNumber < line.fields.length; fieldNumber++) {
            const field = line.fields[fieldNumber]
            if (field.type == 'multilines') { this.clearOnlyoneError(field) }
            else {
              if (field.onlyone == true) {
                if (lineIndex == undefined) { field.onlyoneError = (multilines == undefined) ? false : [] }
                else {
                  field.onlyoneError = field.onlyoneError.filter(line => line != lineIndex)
                  field.onlyoneError = field.onlyoneError.map(line => line > lineIndex ? line - 1 : line)
                }
              }
            }
          }
        }
      }
    },
    mask(field) {
      switch (field.mask) {
        // case 'number': return masks.numberMask(field.maskOptions)
        case 'number': return masks.numberMask
        case 'time': return masks.timeMask
        case 'date': return masks.dateMask
        case 'fone': return masks.foneMask
        case 'cpf': return masks.cpfMask
        case 'cnpj': return masks.cnpjMask
        default: return field.mask
      }
    }
  },
  created() {
    this.$watch(
      () => this.$route.query.tabNumber,
      () => this.clearOnlyoneError(),
      { immediate: true }
    )
  }
}
</script>

<style lang="scss">
  div#formLines { width: 100%; padding-top: 10px; }
  div#formLines div.painel { width: 98%; margin: 10px 1% 20px 1%; flex-wrap: nowrap; }
  div#formLines div.line { display: flex; flex-wrap: wrap; }
  div#formLines div.line.notMultilines { margin-bottom: 10px; flex-wrap: nowrap; }
  div#formLines div.line.functionsLine { margin: 20px 5px; flex-wrap: wrap; }
  div#formLines div.line.actions { flex-direction: row-reverse; }
  div#formLines div.line .actionButton { margin-right: 5px; }
  div#formLines div.line div.v-text-field { margin: 0px 5px; }
  div#formLines div.line .error--text .v-input__append-inner .v-icon { color: #ff5252; }
  div#formLines div.line .v-input__append-inner .v-icon.mdi-menu-down { color: rgba(0, 0, 0, 0.54); }
  div#formLines div.line .v-input__append-inner .v-icon.redIcon { color: #ff5252; }
  div#formLines div.line .theme--light.v-label { color: rgba(0, 0, 0, 0.20); }
  div#formLines div.line .theme--light.v-label.v-label--active { color: rgba(0, 0, 0, 0.87); }
  div#formLines div.line .disabled, div#formLines div.line .readonly { background: rgba(0, 0, 0, 0.05); }
  div#formLines div.line .disabled *, div#formLines div.line .readonly * { color: rgba(0, 0, 0, 0.60) !important; border-color: rgba(0, 0, 0, 0.35) !important; }
  div#formLines div.line .v-input__append-inner .v-icon.tooltip:hover { color: black !important; }
  /* Company Style */
  #app.v-application .primary--text { color: var(--over-color) !important; caret-color: var(--over-color) !important; }
  div#formLines .v-btn { border-color: rgba(0, 0, 0, 0.87); }
  div#formLines .v-btn span.v-btn__content { color: rgba(0, 0, 0, 0.87); }
  div#formLines .v-icon.hover:hover { color: var(--over-color); }
  div#formLines div.line .actionButton.active { background: var(--active-background-color); }
  div#formLines div.line>div.subtitle {
    flex-basis: 100%;
    background: var(--subtitle-background-color);
    color: var(--subtitle-color);
    font-weight: bold;
    margin: 10px 0px 10px 5px;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
  }
  div#formLines div.line>div.subtitleNotFancy {
    flex-basis: 100%;
    padding: 6px 10px 2px 7px;
  }
  div#formLines div.line>div.subtitle * { float: right; }
  div#formLines div.line>div.subtitleNotFancy * { float: right; }
  div#formLines div.line>div.subtitle .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  div#formLines div.line>div.subtitleNotFancy .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  div#formLines div.line>div.subtitle .inLineButton.outer { margin-top: -6px; }
  @media only screen and (max-width: 768px) {
    div#formLines div.line { display: inline; }
    div#formLines div.line * { margin-bottom: 3px; }
  }
  @media print {
    div#formLines div.line.functionsLine { display: none; }
    div#formLines div.line.printHide { display: none; }
    div#formLines div.actions { display: none; }
  }
</style>