import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './../router'
import { dialog } from '@/lib/core/components/dialog'

Vue.use(Vuex)

const userEmpty = { company: "", username: "", name: "" }
const alertEmpty = { show: false, type: null, text: '', dismissible: false, time: null }
const bottomSheetEmpty = { show: false, title: '', text: '', buttonYes: '', buttonNo: '', action: function() { return false }, actionNo: function() { return false } }
const getPermissionAction = (state, action, hmenu, menu) => {
  hmenu = hmenu ? hmenu : router.app.$route.name
  const modulesArray = `${hmenu}Modules`
  if (!state.permission[modulesArray]) { return false }
  menu = menu ? menu : router.app.$route.query.menuId
  if (!menu) { return false }
  const permission = state.permission[modulesArray].find(module => module.module == menu)
  return permission ? permission[action] : false
}

export default new Vuex.Store({
  state: {
    user: { ...userEmpty },
    permission: {},
    dataItens: [],
    reportsItens: [],
    menus: {
      data: [],
      reports: [],
      maps: [],
      controls: []
    },
    clearMenuSelected: false,
    alert: { ...alertEmpty },
    dialog: { ...dialog.empty },
    bottomSheet: { ...bottomSheetEmpty },
    overlay: false,
    overlays: [],
    isMenuVisible: true,
    registerEmpty: {},
    register: {},
    registerOriginal: {},
    keepRegister: false,
    map: null,
    mapEditable: false,
    report: null,
    styles: {},
    print: {
      pageTitle: ''
    }
  },
  getters: {
    user: (state) => () => state.user,
    canAction: (state) => (action, hmenu, menu) => {
      hmenu = hmenu ? hmenu : router.app.$route.name
      const modulesArray = `${hmenu}Modules`
      if (!state.permission[modulesArray]) { return false }
      menu = menu ? menu : router.app.$route.query.menuId
      if (!menu) { return false }
      const permission = state.permission[modulesArray].find(module => module.module == menu)
      const permissionAction = permission ? permission[action] : false
      return permissionAction == true ? true : false
    },
    canStorage: (state) => (action, hmenu, menu) => {
      const permissionAction = getPermissionAction(state, 'storage', hmenu, menu)
      return Array.isArray(permissionAction) ? permissionAction.includes(action) : false
    },
    blockField: (state) => ({ fieldName, multilinesName }) => {
      if (state.permission.dataModulesFields) {
        const _menuId = router.app.$route.query.menuId
        const lines = state.permission.dataModulesFields.filter(line => line.module == _menuId)
        if (multilinesName) {
          const multilines = lines.filter(line => line.fields.includes(multilinesName))
          return multilines.reduce((block, line) => block = (block || (line.lineFields && line.lineFields.find(field => field == fieldName) && line.block)) ? true : false, false)
        }
        else { return lines.reduce((block, line) => block = (block || (line.fields.find(field => field == fieldName) && line.block)) ? true : false, false) }
      }
      else { return false }
    },
    canActionMultilines: (state) => ({ multilinesName, blockAction }) => {
      if (state.permission.dataModulesFields) {
        const _menuId = router.app.$route.query.menuId
        const lines = state.permission.dataModulesFields.filter(line => line.module == _menuId)
        if (multilinesName) {
          const multilines = lines.filter(line => line.fields.includes(multilinesName))
          return multilines.reduce((block, line) => block = (block || line[blockAction]) ? true : false, false)
        }
      }
      else { return false }
    }
  },
  mutations: {
    login(state, user) {
      if (user) {
        state.user = user
        axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
        axios.defaults.headers.common['Company'] = user.company
      }
    },
    logout(state) {
      state.user = { ...userEmpty }
      delete axios.defaults.headers.common['Authorization']
    },
    setPermission(state, permission) { state.permission = permission },
    toggleMenu(state, isVisible) { state.isMenuVisible = (isVisible === undefined) ? !state.isMenuVisible : isVisible },
    setDataItens(state, dataItens) { state.dataItens = dataItens },
    setReportsItens(state, reportsItens) { state.reportsItens = reportsItens },
    changeMenus(state, { hmenu, menus }) { if (menus) { state.menus[hmenu] = menus } },
    clearMenuSelected(state, clear) { state.clearMenuSelected = clear },
    showAlert(state, { type, text, dismissible = false, time}) {
      state.alert.show = true
      state.alert.type = type
      state.alert.text = text
      state.alert.dismissible = dismissible
      state.alert.time = time
    },
    hideAlert(state) { state.alert = { ...alertEmpty } },
    showError(state, error) {
      state.overlay = false
      const handleNetworkError = (error) => {
        if (navigator.onLine) {
          axios.get('https://api.btb.app.br/healthcheck')
            .then(response => {
              console.log('erro na network, resposta do backend:', response)
              const text = (error.data && error.data.response) ? error.data.response.data : error.data
              this.commit("showAlert", { type: 'error', text: text, dismissible: true })
            }).catch(() => this.commit("showAlert", { type: 'error', text: 'Erro de conexão com o servidor', dismissible: true }))
        }
        else { this.commit("showAlert", { type: 'warning', text: 'Verifique sua internet', dismissible: true }) }
      }
      switch (error.status) {
        case 'network':
          handleNetworkError(error)
          break
        case 401:
          this.commit("showAlert", { type: 'warning', text: 'Token expiriado', time: 2000 })
          break
        case 403:
          this.commit("showAlert", { type: 'error', text: error.data.response.data, time: 2000 })
          break
        default: {
          const text = (error.data && error.data.response) ? error.data.response.data : error.data 
          this.commit("showAlert", { type: 'error', text: text, dismissible: true })
        }
      }
    },
    showDialog(state, _dialog) { state.dialog = { ..._dialog, show: true } },
    hideDialog(state) { state.dialog = { ...dialog.empty } },
    showBottomSheet(state, { title, text, buttonYes, buttonNo, action, actionNo }) {
      state.bottomSheet.show = true
      state.bottomSheet.title = title
      state.bottomSheet.text = text
      state.bottomSheet.buttonYes = buttonYes
      state.bottomSheet.buttonNo = buttonNo
      state.bottomSheet.action = action
      state.bottomSheet.actionNo = actionNo
    },
    hideBottomSheet(state) { state.bottomSheet = { ...bottomSheetEmpty } },
    showOverlay(state) { state.overlay = true },
    hideOverlay(state) { state.overlay = false },
    showOverlays(state, number) { state.overlays.includes(number) || state.overlays.push(number) },
    hideOverlays(state, number) { state.overlays.removeByValue(number) },
    changeRegister(state, register) {
      state.register = register
      state.registerOriginal = window.structuredClone(register)
    },
    changeRegisterKeepOriginal(state, register) { state.register = register },
    changeRegisterEmpty(state, registerEmpty) { state.registerEmpty = window.structuredClone(registerEmpty) },
    clearRegister(state) { state.register = window.structuredClone(state.registerEmpty) },
    changeKeepRegister(state, keepRegister) { state.keepRegister = keepRegister },
    setMap(state, map) { state.map = map },
    changemapEditable(state, mapEditable) { state.mapEditable = mapEditable },
    setReport(state, report) { state.report = report },
    setStyles(state, styles) { state.styles = { ...styles } }
  }
})