<template>
  <v-expansion-panels v-if="FormLines.actionsActive.includes(field.openMethod)" class="FieldTimelineControler">
    <v-expansion-panel>
      <v-expansion-panel-header>{{ field.label }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="divLine">
          <v-select
            label="Tarefas não iniciadas"
            v-model="tasks.selectedStartEnd"
            :items="tasks.all"
            item-text="task.text"
            item-value="task.value"
            outlined
            dense
            hide-details
            multiple
            style="flex: 3 1 0;"
            :color="FormLines.styles['--over-color']"
          ></v-select>
          <v-select
            label="Tarefas iniciadas"
            v-model="tasks.selectedEnd"
            :items="tasks.all"
            item-text="task.text"
            item-value="task.value"
            outlined
            dense
            hide-details
            multiple
            style="flex: 3 1 0;"
            :color="FormLines.styles['--over-color']"
          ></v-select>
        </div>
        <div>
          <v-text-field label="Dias" v-model="days" style="flex: 1 1 0;" :color="FormLines.styles['--over-color']"></v-text-field>
          <v-text-field label="Justificativa" v-model="description" style="flex: 6 1 0;" :color="FormLines.styles['--over-color']"></v-text-field>
          <v-btn
            @click="postpone"
            outlined
            :color="FormLines.styles['--over-color']"
          >Aplicar</v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import * as files from '@/lib/files'
import * as js_functions from '@/lib/js_functions'
import { mapState } from 'vuex'
export default {
  name: "FieldTimelineControler",
  props: ['FormLines', 'logic', 'field', 'vmodel'],
  computed: { ...mapState(['register', 'user']) },
  data: () => ({
    tasks: {
      all: [],
      selectedEnd: [],
      selectedStartEnd: []
    },
    days: '',
    description: ''
  }),
  methods: {
    postpone() {
      this.addLines(this.tasks.selectedEnd, false)
      this.addLines(this.tasks.selectedStartEnd, true)
    },
    addLines(_tasks, changeStart) {
      _tasks.map(task => {
        if (!this.register[task] || !this.register[task][0]) { return }
        const lastLine = this.register[task].length - 1
        if (this.register[task][0].data) {
          const _data = js_functions.sumDays(this.register[task][lastLine].data, this.days, true)
          this.register[task].push({ data: _data, justificativa: this.description, logDate: js_functions.getToday()})
        }
        if (this.register[task][0].inicio) {
          const _start = changeStart ? js_functions.sumDays(this.register[task][lastLine].inicio, this.days, true) : this.register[task][lastLine].inicio
          const _end = this.register[task][lastLine].fim ? js_functions.sumDays(this.register[task][lastLine].fim, this.days, true) : ''
          const _days = changeStart ? this.register[task][lastLine].dias : Number(this.register[task][lastLine].dias) + Number(this.days)
          this.register[task].push({ inicio: _start, fim: _end, dias: _days, justificativa: this.description, logDate: js_functions.getToday()})
        }
      })
    },
    async getTasks() {
      let company = this.field.company ? this.field.company : this.user.company
      let registers = []
      const fields = await files.get(`${company}/${this.$route.query.menuId}/fields.json`)
      fields.map(line => !line.fields || line.fields.map(field => {
        if (field.timeline && field.timeline.show == true && this.register[`${field.name}Status`] != 'N/A') {
          registers.push({ task: { text: field.label, value: field.name } })
        }
      }))
      this.tasks.all = registers.sort((a, b) => a.task.text.localeCompare(b.task.text))
    }
  },
  mounted() { this.getTasks() }
}
</script>

<style>
  .FieldTimelineControler { margin-top: 10px; }
  div.divLine { display: flex; }
</style>