<template>
  <div id="divUserMenu">
    <span>{{ getUserName() }}</span>
    <v-menu offset-y attach>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
      </template>
      <v-list dense nav>
        <v-list-item key="userInfo" link @click="userInfo()">
          <v-list-item-icon><v-icon>mdi-cog-outline</v-icon></v-list-item-icon>
          <v-list-item-content>Ajustes</v-list-item-content>
        </v-list-item>
        <v-list-item link @click="clearCache()">
          <v-list-item-icon><v-icon>mdi-cached</v-icon></v-list-item-icon>
          <v-list-item-content>Limpar Cache</v-list-item-content>
        </v-list-item>
        <v-divider color="white"></v-divider>
        <v-list-item key="Logout" link @click="logout()">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content>Logout</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { userKey } from '@/global'
import { mapState } from 'vuex'
export default {
  name: "UserMenu",
  props: { showLogin: Function },
  computed: { ...mapState(['user']) },
  methods: {
    getUserName() { return (this.$mq === 'xs' || this.$mq === 'sm') ? this.user.name.split(' ')[0] : this.user.name },
    userInfo() {
      this.$router.push({ name: 'settings', query: { hmenuOpened: 'Dados' } })
        .then(()=>{})
        .catch(()=>{})
      },
    logout() {
      this.$store.commit('hideAlert')
      this.$store.commit('hideDialog')
      this.$store.commit('hideBottomSheet')
      localStorage.removeItem(userKey)
      this.$store.commit('logout')
      this.showLogin()
    },
    clearCache() {
      caches.delete('files').then(() => {
        caches.delete('maps/db/polygons').then(() => {
          caches.delete('maps/db/info').then(() => {
            caches.delete('data/db').then(() => {
              this.$store.commit("showAlert", { type: 'success', text: 'Cache limpo com sucesso!', dismissible: true })
            })
          })
        })
      })
    }
  }
}
</script>

<style>
  div#divUserMenu { display: flex;  z-index: 150; }
  div#divUserMenu span { margin-right: 5px; }
  div#divUserMenu .v-menu__content { margin-left: -118px; width: 145px; }
  div#divUserMenu div.v-menu div.v-menu__content div.v-list div.v-list-item div.v-list-item__icon:first-child { margin-right: 8px; }
  div#divUserMenu .v-list--dense .v-list-item .v-list-item__content {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
  }
  /* Company Style */
  div#divUserMenu .theme--light.v-icon:before { color: var(--header-color); }
  div#divUserMenu .theme--light.v-icon:after { color: var(--header-color); }
  div#divUserMenu .v-menu__content .theme--light.v-list { background-color: var(--header-background-color); }
  div#divUserMenu .v-menu__content .theme--light.v-list .v-list-item .v-list-item__content { color: var(--header-color); }
  div#divUserMenu .v-menu__content .theme--light.v-list .v-list-item .v-list-item__content { background-color: var(--header-background-color); }
  @media print {
    #divUserMenu { width: 200px; }
    #divUserMenu span { display: none; }
    #divUserMenu .v-icon { display: none; }
  }
</style>