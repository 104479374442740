<template>
  <component :is="tag"
    v-if="field.show == undefined || field.show"
    :name="field.name"
    :key="`${field.name}_${key}`"
    :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
    v-model="vmodel[field.name]"
    :items="items"
    item-text="option.text"
    item-value="option.value"
    outlined
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    dense
    hide-details
    :multiple="field.multiple"
    :no-data-text="`Nenhum(a) ${field.label || 'dado'} encontrado(a)`"
    :disabled="field.disabled"
    :readonly="field.readonly || blockField(field.name, multiline) || blockLine()"
    :rules="FormLines.rules(field)"
    :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
    :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
    :background-color="field.backgroundcolor"
    @click="event(field.click)"
    @change="change"
    :loading="loading"
    :color="FormLines.styles['--over-color']"
  >
    <template v-if="field.multiple" v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.option.text }}</span>
      <v-chip v-if="item && index === 1">
        <span>+{{ vmodel[field.name].length - 1 }}</span>
      </v-chip>
    </template>
    <template slot="append">
      <v-icon v-if="field.dataId && (field.linkIcon == undefined || field.linkIcon)" @click="showRegisterLink()" >mdi-link</v-icon>
      <v-icon v-for="(icon, index) in FormLines.showIcon(field)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
      <v-icon>mdi-menu-down</v-icon>
    </template>
    <template slot="append-outer">
      <template v-for="action in field.actions">
        <v-btn
          v-if="action.type == 'button'"
          :key="action.text"
          @click="event(action.method)"
          outlined
          class="inLineButton"
          :loading="action.loading"
          :color="FormLines.styles['--over-color']"
        >{{ action.text }} </v-btn>
      </template>
    </template>
  </component>
</template>

<script>
import { VSelect, VAutocomplete } from 'vuetify/lib'
import * as get_options from '@/lib/get_options'
import * as js_functions from '@/lib/js_functions'
import { mapState } from 'vuex'
export default {
  name: "FieldLinkSelect",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line', 'parent', 'index'],
  components: { VSelect, VAutocomplete },
  computed: {
    ...mapState(['user', 'register']),
    tag() { return this.field.autocomplete ? 'VAutocomplete' : 'VSelect' }
  },
  data: () => ({
    key: 0,
    items: [],
    loading: 'false'
  }),
  watch: {
    key() { this.load() }
  },
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent.$parent : this.$parent.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    getLinkDescription() { return js_functions.getOptionTextByValue(this.items, this.vmodel[this.field.name]) },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], text: this.getLinkDescription(), vmodel: this.vmodel, lineNumber: this.line }) } },
    change() {
      this.event(this.field.change)
      const _item = this.items.find(item => item.option.value == this.vmodel[this.field.name])
      if (_item) { this.$parent.changeField(this.index, _item.option, true) }
    },
    load() {
      this.loading = this.FormLines.styles["--over-color"]
      get_options.configAndGetOptions(this, {
        ...this.field,
        cache: (this.multiline != undefined),
        cacheName: this.field.cacheName ?? this.field.dataId,
        multilines: (this.multiline != undefined) ? this.parent : null
      }).then(() => {
        this.loading = 'false'
        if (this.items.length > 0 && !this.$parent.loaded.includes(this.index)) { this.$parent.loaded.push(this.index) }
        js_functions.addLinkDescription(this.vmodel, this.field.name, this.items)
        if (Array.isArray(this.items) && this.items[0] && this.field.blanckText) { this.items[0].option.text = this.field.blanckText }
        if (this.FormLines.Form) {
          this.FormLines.Form.links.data[`${this.multiline ? this.multiline + '/' : ''}${this.field.name}`] = this.items
          if (this.field.await) { this.FormLines.Form.links.loaded += 1 }
        }
      })
    },
    async showRegisterLink() {
      const menu = await this.FormLines.Form.get_info.getMenuObject({ parent: this.FormLines.Form, hmenu: 'data', dataId: this.field.dataId })
      this.FormLines.$router.push({ name: 'data', query: { hmenuOpened: 'Dados', menuId: menu.dataId, menuOpened: menu.title, tabNumber: 2, id: this.vmodel[this.field.name] } })
        .then(()=>{})
        .catch(()=>{})
    }
  },
  mounted() {
    this.$watch(`vmodel.${this.field.name}`, () => { js_functions.addLinkDescription(this.vmodel, this.field.name, this.items) })
    this.load()
  }
}
</script>

<style scoped>
  .v-btn.inLineButton { margin-top: -6px; }
</style>