import { baseFilesUrl } from '@/global'
import Vue from 'vue'
import * as cache from '@/lib/cache'

export async function get(path, early) {
  const params = {
    early: early,
    cache: { 'name': 'files', 'url': path },
  }
  return await cache.requestCache('get', params, `${baseFilesUrl}/${path}`)
}

export async function getJS(path, early) { return eval('(' + await get(path, early) + ')') }

export async function getJSNewObject({ path, newParams }) {
  try {
    const object = eval('(' + await get(path) + ')')
    return new object(newParams)
  }
  catch { return false }
}

export function loadScript(path) { return Vue.loadScript(`${baseFilesUrl}/${path}`) }

export function unloadScript(path) { return Vue.unloadScript(`${baseFilesUrl}/${path}`) }

export function loadCss(params) {
  const { company, type, name } = params
  const cssId = `${type}_${name}`
  if (!document.getElementById(cssId)) {
    var head  = document.getElementsByTagName('head')[0]
    var link  = document.createElement('link')
    link.id   = cssId
    link.rel  = 'stylesheet'
    link.type = 'text/css'
    link.href = `${baseFilesUrl}/${company}/${type}/${name}.css`
    link.media = 'all'
    head.appendChild(link)
  }
}

export function fixRegister(register, fields) {
  let registerEmpty = {}
  let _register = { ...register }
  let linksTotal = 0
  const fix = (field) => {
    if (field.type == 'button') { return }
    // Register Empty
    if (field.type == 'map' || field.type == 'multilines' || field.type == 'table') { Object.assign(registerEmpty, { [field.name]: [] }) }
    else {
      if (field.type == 'link') { field.fields.map(_field => {
        Object.assign(registerEmpty, { [_field.name]: '' })
        if (_field.await) { linksTotal += 1 }
      })}
      else {
        Object.assign(registerEmpty, { [field.name]: '' })
        if (field.type == 'linkSimple' && field.await) { linksTotal += 1 }
      }
    }
    if (field.type == 'map') { Object.assign(registerEmpty, { [`${field.name}Area`]: '', [`${field.name}Perimetro`]: '' }) }
    if (field.type == 'multilines' && field.footers && Array.isArray(field.footers)) { field.footers.map(footer => footer.type == undefined || Object.assign(registerEmpty, { [footer.name]: '' })) }
    if (field.type == 'multilines' && field.afterFooters && Array.isArray(field.afterFooters)) { field.afterFooters.map(afterFooter => afterFooter.type == undefined || Object.assign(registerEmpty, { [afterFooter.name]: '' })) }
    if (field.type == 'multilines' && field.timeline?.show) { Object.assign(registerEmpty, { [`${field.name}Status`]: '' }) }
    // Register with data
    if (field.type == 'link') { field.fields.map(_field => { if (!_register[_field.name]) { Object.assign(_register, { [_field.name]: registerEmpty[_field.name] }) } }) }
    else {
      if (field.type == 'multilines') {
        if (_register[field.name] == undefined) { Object.assign(_register, { [field.name]: [] }) }
        if (_register[field.name] == undefined && field.timeline?.show) { Object.assign(_register, { [`${field.name}Status`]: '' }) }
        if (field.footers && Array.isArray(field.footers)) { field.footers.map(footer =>  { if (footer.type != undefined && !_register[footer.name]) { Object.assign(_register, { [footer.name]: registerEmpty[footer.name] }) } }) }
        if (field.afterFooters && Array.isArray(field.afterFooters)) { field.afterFooters.map(afterFooter =>  { if (afterFooter.type != undefined && !_register[afterFooter.name]) { Object.assign(_register, { [afterFooter.name]: registerEmpty[afterFooter.name] }) } }) }
        !field.fields || field.fields.map(_field => {
          !Array.isArray(_register[field.name]) || _register[field.name].map((line, index) => {
            if (line[_field.name] == undefined) { Object.assign(_register[field.name][index], { [_field.name]: '' }) }
          })
        })
      }
      else { if (!_register[field.name]) { Object.assign(_register, { [field.name]: registerEmpty[field.name] }) } }
    }
  }
  fields.map(line => {
    !line.fields || line.fields.map(field => {
      fix(field)
    })
    !line.lines || line.lines.map(_line => {
      !_line.fields || _line.fields.map(field => {
        fix(field)
      })
    })
  })
  return { registerEmpty, fixedRegister: _register, linksTotal }
}