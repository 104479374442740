<template>
  <div class="multiline" :class="field.name" v-if="(register[`${field.name}Status`] != 'N/A' || FormLines.actionsActive.includes('clickMostrarNA')) && (field.show == undefined || field.show)">
    <div v-if="field.label && !field.hideSubtitle" class="subtitle" :class="{ 'wraped': field.wraped }">{{ field.label }} {{ field.details }}</div>
    <table>
      <thead>
      <tr class="multilineHeader " v-if="field.prePreHeaders">
        <th v-for="(prePreHeader, index) in field.prePreHeaders" :key="index" :colspan="prePreHeader.span">
          <v-text-field
            v-if="'text' in prePreHeader"
            :value="prePreHeader.text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </th>
      </tr>
      <tr class="multilineHeader " v-if="field.preHeaders">
        <th v-for="(preHeader, index) in field.preHeaders" :key="index" :colspan="preHeader.span">
          <v-text-field
            v-if="'text' in preHeader"
            :value="preHeader.text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </th>
      </tr>
      <tr class="multilineHeader ">
        <th v-for="(column, index) in field.fields" :key="index" :style="`width: ${column.columnwidth};`">
          <v-tooltip :disabled="!column.tooltip || column.tooltipIcon" top v-if="column.label">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs" v-on="on"
                :value="column.label"
                outlined
                dense
                readonly
                hide-details
                :style="`flex: ${column.grow ? column.grow : 1} 1 ${column.basis ? column.basis : 0};`"
              >
                <template slot="append">
                  <AppendIconTooltip :field="column" />
                </template>
              </v-text-field>
            </template>
            <span v-if="!column.tooltipIcon">{{ column.tooltip }}</span>
          </v-tooltip>
          <div v-else class="flex">
            <v-text-field
              v-for="(field, index) in column.fields"
              :key="index"
              :value="field.label"
              outlined
              dense
              disabled
              hide-details
              :style="`flex: ${column.grow ? column.grow : 1} 1 ${column.basis ? column.basis : 0};`"
            ></v-text-field>
          </div>
        </th>
        <th v-if="field.timeline && field.timeline.show && !field.timeline.alllines">
          <FieldSelect v-if="field.timeline && field.timeline.show && !field.timeline.alllines" :field="fieldStatus()" :vmodel="vmodel ? vmodel : register" :logic="logic" :FormLines="FormLines" :disabled="field.disabled || blockField(field.name)"/>
        </th>
        <th v-if="field.timeline && field.timeline.show && field.timeline.alllines">
          <v-text-field v-if="field.timeline && field.timeline.show && field.timeline.alllines" value="Adicionado em" outlined dense disabled hide-details style="min-width: 135px"/>
        </th>
        <th v-if="field.timeline && field.timeline.show && field.timeline.alllines">
          <v-text-field v-if="field.timeline && field.timeline.show && field.timeline.alllines" value="Status" outlined dense disabled hide-details style="min-width: 135px"/>
        </th>
        <th colspan="2"><v-btn v-if="field.addButtom != false && !blockAdd" outlined height="40px" @click="addLine" :disabled="blockAdd" :color="FormLines.styles['--over-color']" :class="{ 'disabled': blockAdd }">+</v-btn></th>
      </tr>
      <tr v-if="field.fields2 && field.fields2.length > 0" class="multilineHeader ">
        <th v-for="(column, index) in field.fields2" :key="index" :style="`width: ${column.columnwidth};`" :colspan="column.colspan">
          <v-text-field
            v-if="column.label"
            :value="column.label"
            outlined
            dense
            disabled
            hide-details
            :style="`flex: ${column.grow ? column.grow : 1} 1 ${column.basis ? column.basis : 0};`"
          ></v-text-field>
          <div v-else class="flex">
            <v-text-field
              v-for="(field, index) in column.fields2"
              :key="index"
              :value="field.label"
              outlined
              dense
              disabled
              hide-details
              :style="`flex: ${column.grow ? column.grow : 1} 1 ${column.basis ? column.basis : 0};`"
            ></v-text-field>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="totalOptions == totalLinks">
        <template v-for="(line, lineIndex, lineI) in vmodel ? vmodel[field.name] : register[field.name]">
          <tr :key="lineI">
            <td v-for="column in field.fields" :key="`${column.name}_${lineIndex}`">
              <FieldText v-if="column.type === 'text'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldSelect v-if="column.type === 'select'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldTextarea v-if="column.type === 'textarea'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldLink v-if="column.type === 'link'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldLinkSimple v-if="column.type === 'linkSimple'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldHTML v-if="column.type === 'html'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldDatePicker v-if="column.type === 'datePicker'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldStorage v-if="column.type === 'storage'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
            </td>
            <td v-if="field.timeline && field.timeline.show">
              <FieldText v-if="field.timeline && field.timeline.show" :key="`logDate_${lineIndex}`" :field="logDate()" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
            </td>
            <td v-if="field.timeline && field.timeline.show && field.timeline.alllines">
              <FieldSelect v-if="field.timeline && field.timeline.show && field.timeline.alllines" :field="fieldStatus()" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
            </td>
            <td v-if="field.removeButtom != false && lineIndex > blockedLines">
              <v-btn outlined height="40px" @click="removeLine(lineIndex)" :color="FormLines.styles['--over-color']" :x-small="field.moveLine">-</v-btn>
            </td>
            <td v-if="field.moveLine && field.removeButtom != false && blockedLines == -1" class="halfBtn">
              <v-btn outlined height="20px" @click="moveLine(lineIndex, -1, field.moveTogether)" :color="FormLines.styles['--over-color']" class="halfBtn" x-small>&uarr;</v-btn>
              <v-btn outlined height="20px" @click="moveLine(lineIndex, +1, field.moveTogether)" :color="FormLines.styles['--over-color']" class="halfBtn Down" x-small>&darr;</v-btn>
            </td>
          </tr>
          <tr v-if="field.fields2 && field.fields2.length > 0" :key="lineI">
            <td v-for="column in field.fields2" :key="`${column.name}_${lineIndex}`" :colspan="column.colspan">
              <FieldText v-if="column.type === 'text'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldSelect v-if="column.type === 'select'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldTextarea v-if="column.type === 'textarea'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldLink v-if="column.type === 'link'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldLinkSimple v-if="column.type === 'linkSimple'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldHTML v-if="column.type === 'html'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldDatePicker v-if="column.type === 'datePicker'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
              <FieldStorage v-if="column.type === 'storage'" :key="`${column.name}_${lineIndex}`" :field="column" :vmodel="vmodel ? vmodel[field.name][lineIndex] : register[field.name][lineIndex]" :multiline="field.name" :line="lineIndex" :logic="logic" :FormLines="FormLines"/>
            </td>
          </tr>
        </template>
      </template>
      <tr v-else><td :colspan="countColunms()"><v-progress-linear indeterminate :color="FormLines.styles['--over-color']"></v-progress-linear></td></tr>
      <tr class="multilineHeader " v-if="field.footers">
        <th v-for="(footer, index) in field.footers" :key="index" :colspan="footer.span">
          <v-text-field
            v-if="'text' in footer && footer.type == undefined"
            :value="footer.text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
          <FieldText v-if="'text' in footer && footer.type == 'text'" :field="footer" :vmodel="vmodel ? vmodel : register" :logic="logic" :FormLines="FormLines"/>
        </th>
      </tr>
      <tr class="multilineHeader " v-if="field.afterFooters">
        <th v-for="(afterFooter, index) in field.afterFooters" :key="index" :colspan="afterFooter.span">
          <v-text-field
            v-if="'text' in afterFooter && afterFooter.type == undefined"
            :value="afterFooter.text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
          <FieldText v-if="'text' in afterFooter && afterFooter.type == 'text'" :field="afterFooter" :vmodel="vmodel ? vmodel : register" :logic="logic" :FormLines="FormLines"/>
        </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FieldText from './FieldText'
import FieldSelect from './FieldSelect'
import FieldTextarea from './FieldTextarea'
import FieldLink from './FieldLink'
import FieldLinkSimple from './FieldLinkSimple'
import FieldHTML from './FieldHTML'
import FieldDatePicker from './FieldDatePicker'
import FieldStorage from './FieldStorage'
import AppendIconTooltip from './pieces/AppendIconTooltip'
import { mapState } from 'vuex'
import * as get_options from '@/lib/get_options'
import * as js_functions from '@/lib/js_functions'
export default {
  name: "Multilines",
  props: ['FormLines', 'field', 'vmodel', 'logic'],
  components: { FieldText, FieldSelect, FieldTextarea, FieldLink, FieldLinkSimple, FieldHTML, FieldDatePicker, FieldStorage, AppendIconTooltip },
  computed: { ...mapState(['register', 'user']) },
  data: () => ({
    blockedLines: -1,
    blockAdd: false,
    links: {},
    totalLinks: 1,
    totalOptions: 0
  }),
  watch: {
    'register.lastUpdate': function() { this.checkRemove() }
  },
  methods: {
    event(functionName, name, value, vmodel, lineIndex) {
      if (functionName) { this.logic[functionName]({ FormLines: this.FormLines, parent: this, name, value, vmodel, lineNumber: lineIndex }) }
    },
    getValues(lineIndex) {
      if (!this.vmodel) return lineIndex != undefined ? this.register[this.field.name][lineIndex] : this.register[this.field.name]
      return lineIndex != undefined ? this.vmodel[this.field.name][lineIndex] : this.vmodel[this.field.name]
    },
    blockField(fieldName) { return this.$store.getters.blockField({ fieldName }) },
    checkAdd() { this.blockAdd = this.canActionMultilines(this.field.name, 'blockAdd') },
    checkRemove() {
      if (this.canActionMultilines(this.field.name, 'blockRemove')) {
        const _vmodel = this.vmodel ? this.vmodel[this.field.name] : this.register[this.field.name]
        this.blockedLines = _vmodel ? _vmodel.length - 1 : -1
      }
    },
    canActionMultilines(multilinesName, blockAction) { return this.$store.getters.canActionMultilines({ multilinesName, blockAction }) },
    addLine() {
      const value = this.getValues()
      const newLine = {}
      this.field.fields.map(item => {
        if ('name' in item) { Object.assign(newLine, {[item.name]: ''}) }
        if (item.type == 'link') { item.fields.map(subitem => { if ('name' in subitem) { Object.assign(newLine, {[subitem.name]: ''}) } }) }
        if (item.type == 'linkSimple') { if ('name' in item) { Object.assign(newLine, {[item.name]: ''}) } }
      })
      let _vmodel = this.vmodel ? this.vmodel : this.register
      if (_vmodel[this.field.name] == undefined) { this.$set(_vmodel, this.field.name, [newLine]) }
      else { _vmodel[this.field.name].push(newLine) }
      if (this.field.addUnblockedLine == true) { js_functions.getArrayValue(value, 'last').enabledLine = '' }
      this.event(this.field.clickAdd, this.field.name, _vmodel, value)
      this.field.enableFocus = true
    },
    removeLine(lineIndex) {
      const remove = () => {
        const value = this.getValues(lineIndex)
        let _vmodel = this.vmodel ? this.vmodel : this.register
        _vmodel[this.field.name].splice(lineIndex, 1)
        this.FormLines.clearOnlyoneError(this.field, lineIndex)
        this.event(this.field.clickRemove, this.field.name, value, _vmodel, lineIndex)
      }
      if (this.field.confirmRemove == undefined || this.field.confirmRemove == false) { return remove() }
      this.$store.commit("showDialog", {
        title: 'Tem certeza que deseja remover a linha?',
        text: 'Esta ação não é definitiva. As alterações só são salvas após gravar o registro.',
        buttonYes: 'Sim, pode remover!',
        buttonNo: 'Não',
        action: () => {
          this.$store.commit("hideDialog")
          remove()
        }
      })
    },
    moveLine(lineIndex, move, multilinesMoveTogether) {
      const _moveLine = (fieldName, lineIndex, move) =>{
        const _vmodel = this.vmodel ? this.vmodel[fieldName] : this.register[fieldName]
        const element = _vmodel[lineIndex]
        _vmodel.splice(lineIndex, 1)
        _vmodel.splice(lineIndex + move, 0, element)
      }
      _moveLine(this.field.name, lineIndex, move)
      !multilinesMoveTogether || multilinesMoveTogether.map(multilines => _moveLine(multilines, lineIndex, move))
    },
    fieldStatus() { 
      const name = this.field.timeline.alllines ? 'status' : `${this.field.name}Status`
      return {
        "name": name,
        "type": "select",
        "defaultvalue": "A Superar",
        "autocomplete": false,
        "options": [
          { "option": { "text": "A Superar", "value": "A Superar", "color": "#FFA500" } },
          { "option": { "text": "Superado", "value": "Superado", "color": "#88CC88" } },
          { "option": { "text": "N/A", "value": "N/A", "color": "#CCCCCC" } }
        ],
        "minwidth": "135px"
      }
    },
    logDate() { return {
      "name": "logDate",
      "type": "text",
      "defaultvalue": js_functions.getToday(),
      "disabled": true,
      "minwidth": "135px"
    }},
    countColunms() {
      let colunms = this.field.fields.length
      colunms += this.field.timeline && this.field.timeline.show ? 1 : 0
      colunms += this.field.timeline && this.field.timeline.alllines ? 1 : 0
      return colunms + 1
    },
    storeOptions(subtotal) {
      const _fields = this.field.fields.filter(column => column.type == 'link' || column.type == 'linkSimple')
      this[subtotal] = _fields.length
      _fields.map(_field => {
        if (_field.type == 'link') { _field.fields.map(field => Object.assign(this.links, { [field.name]: {} })) }
        else { Object.assign(this.links, { [_field.name]: {} }) }
        const field = _field.type == 'link' ? _field.fields[0] : _field
        get_options.configAndGetOptions(this, {
          ...field,
          cache: true,
          cacheName: field.cacheName ?? field.dataId,
          multilines: this
        }).then(() => this.totalOptions++ )
      })
    },
    focusFieldName() {
      if (this.field.focusField) { return this.field.focusField }
      const focusField = this.field.fields.find(field => !field.disabled && !field.readonly)
      return focusField ? focusField.name : ''
    },
    lineFocus(component) {
      if (this.field.enableFocus && component.line == this.vmodel[this.field.name].length - 1 && this.focusFieldName() == component.field.name) {
        component.$nextTick(() => component.$refs[component.field.name].focus())
        this.field.enableFocus = false
      }
    }
  },
  // created() {
  //   this.$watch(`vmodel.${this.field.name}.length`, (_new, _old) => {
  //     if (_new == _old + 1) { this.field.enableFocus = true }
  //   })
  // },
  mounted() {
    this.storeOptions('totalLinks')
    this.checkAdd()
    this.checkRemove()
    this.FormLines.clearOnlyoneError(this.field)
  }
}
</script>

<style lang="scss">
  div.multiline { flex-grow: 1; margin-bottom: 10px; }
  div.multiline div.subtitle {
    flex-basis: 100%;
    margin: 0px 0px 5px 7px;
  }
  div.multiline table, div.multiline table tbody { width: 100%; line-height: 1; }
  div.multiline table tr th { font-weight: normal; }
  div.multiline table tr th div.flex { display: flex; }
  div#formLines div.line div.multiline div.v-text-field { margin: 0px 1px; }
  div.multiline div.subtitle.wraped {
    margin: 0px 0px 10px 5px;
    background: var(--multilinesheader-background-color);
    color: var(--multilinesheader-color);
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
    padding: 8px 10px;
  }
  tr.multilineHeader th .v-text-field--outlined > .v-input__control > .v-input__slot { background: lightgray; }
  tr.multilineHeader th .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded):not(.v-select) > .v-input__control > .v-input__slot { padding: 0px; }
  tr.multilineHeader th .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot * { text-align: center; }
  tr.multilineHeader th .v-text-field--outlined > .v-input__control > .v-input__slot > .v-text-field__slot > input { color: black; }
  div.multiline table tbody tr td.halfBtn { min-width: 27px !important; max-width: 27px  }
  div.multiline table tbody .halfBtn { min-width: 27px !important; max-width: 27px !important; min-height: 18px !important; max-height: 18px !important; padding: 0px !important; }
  div.multiline table tbody .halfBtn.Down { margin-top: 2px !important; }
  div.multiline table tbody .halfBtn span { max-width: 15px !important; }
</style>