<template>
  <div id="divHorizontalMenu">
    <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
    <v-select
      attach
      :items="items"
      item-text="name"
      item-value="value"
      v-model="selected"
      dense
      @change="changeHMenu()"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "HorizontalMenu",
  computed: { ...mapState(['permission']) },
  data: () => ({
    selected: "data",
    items: []
  }),
  methods: {
    toggleMenu() { this.$store.commit('toggleMenu') },
    changeHMenu() {
      const name = this.items.find(item => item.value == this.selected).name
      const tabNumber = this.selected == 'data' || this.selected == 'controls' ? 0 : undefined
      this.$router.push({ name: this.selected, query: { hmenuOpened: name, tabNumber } })
        .then(() => { this.$store.commit('toggleMenu', true) })
        .catch(()=>{})
    },
    getZones() {
      this.items = this.permission.hmenuZones.map(zone => {
        // if (zone.level == '1') { return { item: { name: zone.link_zone, value: zone.zone, disabled: true } } }
        return { name: zone.link_zone, value: zone.zone }
      })
    }
  },
  mounted() {
    this.getZones()
    if (!this.$route.name) {
      this.$router.push({ name: 'data', query: { hmenuOpened: 'Dados' } })
        .then(()=>{})
        .catch(()=>{})
    }
  },
  created() {
    this.$watch(
      () => this.$route.name,
      (name, oldName) => {
        if (name == 'settings') { name = 'data' }
        if (name && name != oldName) { this.selected = name }
      },
      { immediate: true }
    )
  }
}
</script>

<style>
  #divHorizontalMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 150;
  }
  #divHorizontalMenu div.v-select {
    width: 140px;
    height: 30px;
  }
  @media (max-width: 768px) { #divHorizontalMenu div.v-select { width: 110px; } }
  /* Company Style */
  div#divHorizontalMenu .theme--light.v-select div.v-select__selections { color: var(--header-color); }
  div#divHorizontalMenu * .theme--light.v-icon:before { color: var(--header-color); }
  div#divHorizontalMenu * .theme--light.v-icon:after { color: var(--header-color); }
  div#divHorizontalMenu .theme--light.v-text-field > .v-input__control > .v-input__slot:before { border-color: var(--header-color); }
  div#divHorizontalMenu .theme--light.v-text-field > .v-input__control > .v-input__slot:after { border-color: var(--header-color); }
  div#divHorizontalMenu .v-menu__content .theme--light.v-list { background-color: var(--header-background-color); }
  div#divHorizontalMenu .v-menu__content .theme--light.v-list .v-list-item .v-list-item__content .v-list-item__title { color: var(--header-color); }
  div#divHorizontalMenu .v-menu__content .theme--light.v-list .v-list-item .v-list-item__content .v-list-item__title { background-color: var(--header-background-color); }
  @media print { #divHorizontalMenu { display: none; } }
</style>