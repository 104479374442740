import axios from "axios"
import { baseApiUrl } from '@/global'
import * as cache from '@/lib/cache'

const company = params => params.company ? params.company : axios.defaults.headers.common['Company']

export async function get(params) {
  let url = `${baseApiUrl}/${company(params)}/${params.module}/${params.dataId}`
  if (params.multilines) { url += `/${params.multilines}/${params.field}/${params.value}` }
  if (params.query) { url += Object.keys(params.query).reduce((query, key) => query += `${key}=${params.query[key]}&`, '?') }
  return await cache.requestCache('get', params, url)
}

export async function post(params) {
  let url = `${baseApiUrl}/${company(params)}/${params.module}/${params.dataId}`
  if (params.action) { url += `/${params.action}` }
  return await cache.requestCache('post', params, url)
}

export async function put(params) {
  const url = `${baseApiUrl}/${company(params)}/${params.module}/${params.dataId}/${params.register._id}`
  return params.early ? axios.put(url, params.register) : await axios.put(url, params.register).then(res => res.data).catch(e => e)
}

export async function patch(params) {
  let url = `${baseApiUrl}/${company(params)}/${params.module}/${params.dataId}`
  if (params.body.set && params.body.set._id) { url += `/${params.body.set._id}` }
  if (params.body.push && params.body.push._id) { url += `/${params.body.push._id}` }
  return params.early ? axios.patch(url, params.body) : await axios.patch(url, params.body).then(res => res.data).catch(e => e)
}

export async function del(params) {
  const url = `${baseApiUrl}/${company(params)}/${params.module}/${params.dataId}/${params.register._id}`
  return params.early ? axios.delete(url) : await axios.delete(url).then(res => res.data).catch(e => e)
}

// Files Storage

const getStorageFolder = (params) => params.folder ? `/${params.folder}` : ''

export async function upload(params) {
  const url = `${baseApiUrl}/storage/${company(params)}/${params.module}/${params.dataId}/${params._id}${getStorageFolder(params)}/${params.fileName}`
  const options = { headers: { 'Content-Type': 'multipart/form-data' } }
  return params.early ? axios.post(url, params.formData, options) : await axios.post(url, params.formData, options).then(res => res.data).catch(e => e)
}

export async function download(params) {
  const url = `${baseApiUrl}/storage/${company(params)}/${params.module}/${params.dataId}/${params._id}${getStorageFolder(params)}/${params.fileName}`
  return params.early ? axios.get(url) : await axios.get(url).then(res => res.data).catch(e => e)
}

export async function listFiles(params) {
  const url = `${baseApiUrl}/storage/list/${company(params)}/${params.module}/${params.dataId}/${params._id}${getStorageFolder(params)}`
  return params.early ? axios.get(url) : await axios.get(url).then(res => res.data).catch(e => e)
}

export async function removeFile(params) {
  const url = `${baseApiUrl}/storage/${company(params)}/${params.module}/${params.dataId}/${params._id}${getStorageFolder(params)}/${params.fileName}`
  return params.early ? axios.delete(url) : await axios.delete(url).then(res => res.data).catch(e => e)
}

export async function getSize(params) {
  const url = `${baseApiUrl}/storage/size/${company(params)}`
  return params.early ? axios.get(url) : await axios.get(url).then(res => res.data).catch(e => e)
}

export async function getSettings(params) {
  const url = `${baseApiUrl}/storage/settings/${company(params)}`
  return params.early ? axios.get(url) : await axios.get(url).then(res => res.data).catch(e => e)
}