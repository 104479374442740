import * as js_functions from '@/lib/js_functions'

export function timeline_functions() {
  this.options = { width:  "auto", height: "auto", layout: "box", eventMargin: 7, eventMarginAxis: 7, "zoomable": false, groupMinHeight: 15, 
    groupsOrder: function (a, b) {
      if (a.content && b.content) {
        const aPosition = a.content.substring(a.content.indexOf("<span style='display: none'>") + 28, a.content.indexOf("</span>"))
        const bPosition = b.content.substring(b.content.indexOf("<span style='display: none'>") + 28, b.content.indexOf("</span>"))
        return bPosition*1 - aPosition*1
      }
    }
  }
  this.configTimeline = function(Timeline, divTimeline) {
    try {
        if (window.google != undefined && divTimeline) {
          window.google.load('visualization', '1', { packages: ['corechart','timeline'], callback: () => {
            Timeline.data = new window.google.visualization.DataTable()
            this.configDataTable(Timeline)
            this.loadTimeline(Timeline, divTimeline, false)
          }})
        }
    }
    catch (error) { console.error(error) }
  }
  this.configDataTable = function(Timeline) {
    Timeline.data.addColumn('datetime', 'start')
    Timeline.data.addColumn('datetime', 'end')
    Timeline.data.addColumn('string', 'content')
    Timeline.data.addColumn('string', 'className')
    Timeline.data.addColumn('string', 'group')
    Timeline.data.addColumn('string', 'pharos')
    Timeline.data.addColumn('string', 'heading')
    Timeline.data.addColumn('string', 'type')
  }
  this.loadTimeline = function(Timeline, divTimeline, first) {
    if (Timeline.data && Timeline.data.getNumberOfRows() > 0) { Timeline.data.removeRows(0, Timeline.data.getNumberOfRows()) }
    this.fillData(Timeline, first)
    Timeline.timeline = new window.links.Timeline(divTimeline)
    Timeline.timeline.setOptions(this.options)
    Timeline.timeline.draw(Timeline.data)
    // Timeline.timeline.setScale(window.links.Timeline.StepDate.SCALE.DAY, 1)
  }
  this.addHeader = function(Timeline) {
    let headers = `<span style='display: none'>0</span>`
    headers += '<span position="999" class="Group_TimelineHeading">Step</span>'
    Timeline.field.headers.map(header => headers += `<div class="${header.class} TimelineHeading ">${header.text}</div>`)
    Timeline.data.addRows([[null, null, '', 'First', headers, 'Nome', '', '']])
  }
  this.fillData = function(Timeline, first) {
    if (Timeline.field.headers) { this.addHeader(Timeline) }
    const addDataByLine = (field, lineNumber) => {
      this.addData({
        Timeline: Timeline,
        name: field.name,
        label: field.label,
        group: field.label,
        position: field.timeline.position,
        first: first,
        alllines: field.timeline.alllines,
        groupfield: field.timeline.groupfield,
        labelfield: field.timeline.labelfield,
        lineNumber: lineNumber,
        class: field.class,
        replaceClassSpace: true
      })
    }
    Timeline.FormLines.fields.map(line => {
      !line.fields || line.fields.map(field => {
        if (field.timeline && field.timeline.show == true) {
          if (Timeline.register[field.name] && Timeline.register[field.name].length > 0) {
            if (field.timeline.alllines == true) {
              Timeline.register[field.name].map((line, index) => { addDataByLine(field, index) })
            }
            else { addDataByLine(field, Timeline.register[field.name].length - 1) }
          }
        }
      })
    })
  }
  this.addData = function(Params) {
    const line = Params.Timeline.register[Params.name][Params.lineNumber]
    let myClass = ""
    if (Params.alllines) {
      Params.label = line[Params.labelfield]
      Params.group = Params.groupfield ? line[Params.groupfield] : Params.label
      myClass = line.status ? line.status.replace(' ', '') : ''
      Params.position += (Params.lineNumber / 1000)
    }
    if (!Params.alllines) { myClass = Params.Timeline.register[`${Params.name}Status`] && typeof Params.Timeline.register[`${Params.name}Status`] == 'string' ? Params.Timeline.register[`${Params.name}Status`].replace(' ', '') : '' }
    const start = line.data ? js_functions.createObjectDate(line.data) : js_functions.createObjectDate(line.inicio)
    const end = line.fim ? js_functions.createObjectDate(line.fim) : null
    const today = new Date()
    let atrasadoClass
    if (start && !end) { atrasadoClass = (myClass == 'ASuperar' && (today-start) > (24*60*60*1000)) ? 'Atrasado' : '' }
    if (end) { atrasadoClass = (myClass == 'ASuperar' && (today-end) > (24*60*60*1000)) ? 'Atrasado' : '' }
    if (atrasadoClass != '') { myClass = atrasadoClass }
    if (Params.class) { myClass += ` ${Params.class}` }
    Params.group = `<span style='display: none'>${Params.position}</span>${Params.group}`
    // else { strGroup = "<span position='" + intOrder + "' class='Group_" + strPharosName + "'>" + strGroup + "</span>" }
    if (!Params.Timeline.details) {
      Params.group += Params.Timeline.logicTimeline && typeof Params.Timeline.logicTimeline[Params.Timeline.field.details1] == 'function'
        ? Params.Timeline.logicTimeline[Params.Timeline.field.details1](Params.Timeline, start, end, atrasadoClass, false)
        : this.addDate(Params.Timeline, start, end, atrasadoClass, false)
    }
    else {
      Params.group += Params.Timeline.logicTimeline && typeof Params.Timeline.logicTimeline[Params.Timeline.field.details2] == 'function'
        ? Params.Timeline.logicTimeline[Params.Timeline.field.details2](Params.Timeline, start, end, 'departamento')
        : this.addOriginDutation(Params.Timeline, start, end, 'departamento')
    }
    if (start && js_functions.isValidDate(start) && (end == null || js_functions.isValidDate(end))) {
      Params.Timeline.data.addRows([[start, end, Params.label, myClass, Params.group, 'Nome', '', '']])
    }
    if (Params.first) {
      const firstLine = Params.Timeline.register[Params.name][0]
      const firstStart = firstLine.fim ? js_functions.createObjectDate(firstLine.inicio) : js_functions.createObjectDate(firstLine.data)
      const firstEnd = firstLine.fim ? js_functions.createObjectDate(firstLine.fim) : null
      if (js_functions.isValidDate(firstStart)) { Params.Timeline.data.addRows([[firstStart, firstEnd, Params.label, 'First', Params.group, 'Nome', '', '']]) }
    }
  }
  this.addDate = function(Timeline, start, end, strAtrasadoClass, blnNotShowDay) {
    let group = "<div class='DateColumn " + strAtrasadoClass + "'>"
    if (start) {
      const strDiaInicio = (parseInt(start.getDate()) > 9) ? (parseInt(start.getDate())) : '0' + (parseInt(start.getDate()))
      const strMesInicio = (parseInt(start.getMonth())+1 > 9) ? (parseInt(start.getMonth())+1) : '0' + (parseInt(start.getMonth())+1)
      if (!blnNotShowDay) { group += strDiaInicio + "/" + strMesInicio + "/" + start.getFullYear()%100 }
      else { group += strMesInicio + "/" + start.getFullYear() }
    }
    if (end) {
      var strDiaFim = (parseInt(end.getDate()) > 9) ? (parseInt(end.getDate())) : '0' + (parseInt(end.getDate()))
      var strMesFim = (parseInt(end.getMonth())+1 > 9) ? (parseInt(end.getMonth())+1) : '0' + (parseInt(end.getMonth())+1)
      group += " - "
      if (!blnNotShowDay) { group += strDiaFim + "/" + strMesFim + "/" + end.getFullYear()%100 }
      else { group += strMesFim + "/" + end.getFullYear() }
    }
    group += "</div>"
    return group
  }
  this.addOriginDutation = function(Timeline, start, end, departamento) {
    const blnDuracao = true
    let group = ''
    if (departamento) { group += "<div class='DepartamentoColumn '>" + departamento + "</div>" }
    // if (start && strDataBaseField != '' && getDateObjectFromDateField(strDataBaseField) != null) { group += "<div class='MesReferenciaColumn '>" + parseInt(diferencaDias(start, getDateObjectFromDateField(strDataBaseField), false)/30) + "</div>"; }
    if (start && blnDuracao) {
      if (end) {
        group += "<div class='DuracaoColumn '>" + js_functions.diferencaMeses(end, start) + "</div>"
      }
      else {
        group += "<div class='DuracaoColumn '>" + '1' + "</div>"
      }
    }
    return group
  }
}