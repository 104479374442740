<template>
  <div class="footerInfo" :key="selectedsViewKey">
    <template v-for="obj, index in objectsName">
      <v-chip v-if="selected[obj.name] && selected[obj.name].length > 0" :key="index" class="ma-2" color="green" text-color="white">
        <v-avatar left class="green darken-4" >{{ selected[obj.name].length }}</v-avatar>{{ obj.label }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: "FooterInfo",
  props: ['objectsName', 'selected' ],
  data: () => ({
    selectedsViewKey: 0
  }),
  methods: {
    refreshSelectedsView() { this.selectedsViewKey += 1 },
  }
}
</script>

<style scoped>
  div.footerInfo {
    z-index: 5;
    position: absolute;
    bottom: 30px;
    left: 10px;
    display: flex;
  }
</style>