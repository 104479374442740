<template>
  <v-menu
    v-if="field.type === 'datePicker' && (field.show == undefined || field.show)"
    ref="componentRoot"
    min-width="auto"
    :nudge-top="0"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top>
        <template v-slot:activator="{ on2, attrs2 }">
          <div v-bind="attrs2" v-on="on2" :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              :key="field.name"
              :name="field.name"
              :ref="field.name"
              :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
              v-model="vmodel[field.name]"
              :type="field.type"
              :solo="field.solo"
              :outlined="!field.solo"
              autocomplete="new-password"
              autocorrect="off"
              autocapitalize="off"
              dense
              hide-details
              :suffix="field.suffix"
              :disabled="field.disabled"
              :readonly="readOnly()"
              v-mask="FormLines.mask(field)"
              :rules="FormLines.rules(field)"
              :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
              :background-color="field.backgroundcolor"
              @keydown="onKeyDown"
              @keyup="onKeyUp"
              @click="showCalendar = readOnly() ? false : true; event(field.click)"
              @change="event(field.change)"
              @blur="vmodelCalender = parseDate(vmodel[field.name])"
              :color="FormLines.styles['--over-color']"
              :autofocus="field.focus"
            >
              <template slot="append">
                <v-icon v-for="(icon, index) in FormLines.showIcon(field, line)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
                <AppendIconTooltip :field="field" />
                <template v-for="action in field.actions">
                  <v-icon v-if="action.type == 'icon' && !action.outer" :key="action.text" @click="event(action.method)" class="hover">{{action.text}}</v-icon>
                  <v-btn
                    v-if="action.type == 'button' && !action.outer"
                    :style="action.enabled ? 'pointer-events: auto;' : ''"
                    :key="action.text"
                    @click="event(action.method)"
                    outlined
                    small
                    class="inLineButton"
                    :loading="action.loading"
                    :color="FormLines.styles['--over-color']"
                  >{{action.text}}</v-btn>
                </template>
              </template>
              <template slot="append-outer">
                <template v-for="action in field.actions">
                  <v-icon v-if="action.type == 'icon' && action.outer" :key="action.text" @click="event(action.method)" class="hover">{{action.text}}</v-icon>
                  <v-btn
                    v-if="action.type == 'button' && action.outer"
                    :style="action.enabled ? 'pointer-events: auto;' : ''"
                    :key="action.text"
                    @click="event(action.method)"
                    outlined
                    class="inLineButton outer"
                    :loading="action.loading"
                    :color="FormLines.styles['--over-color']"
                  >{{action.text}}</v-btn>
                </template>
              </template>
            </v-text-field>
          </div>
        </template>
        <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
      </v-tooltip>
    </template>
    <v-date-picker
      v-if="showCalendar"
      v-model="vmodelCalender"
      :events="field.events"
      :event-color="FormLines.styles['--over-color']"
      width="auto"
      no-title
      scrollable
      :color="FormLines.styles['--over-color']"
      locale="pt-BR"
      :reactive="true"
      @input="vmodel[field.name] = formatDate(vmodelCalender)"
      @click="event(field.click)"
      @change="event(field.change); showCalendar = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldDatePicker",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line'],
  components: { AppendIconTooltip },
  computed: { ...mapState(['register']) },
  data: () => ({
    showCalendar: false,
    vmodelCalender: new Date().toISOString()
  }),
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const valid = this.FormLines.Form.js_functions.isValidStringDate(date)
      if (!valid) { return null }
      const [day, month, year] = date.split('/')
      return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`
    },
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    readOnly() { return this.field.readonly || this.blockField(this.field.name, this.multiline) || this.blockLine() },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], vmodel: this.vmodel, lineNumber: this.line }) } },
    onKeyUp(evt) {
      !this.field.keyup || this.event(this.field.keyup)
      if (evt.keyCode == 13) {
        this.vmodelCalender = this.parseDate(this.vmodel[this.field.name])
        this.showCalendar = false
      }
    },
    onKeyDown(evt) {
      !this.field.keydown || this.event(this.field.keydown)
      const keysAllowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
      const keysAlwaysAllowed = [8, 9, 13, 37, 39, 46]
      const _vmodel = this.vmodel ? this.vmodel[this.field.name] : this.register[this.field.name]
      if ((!keysAllowed.includes(evt.keyCode) || _vmodel && _vmodel.length >= 10) && !keysAlwaysAllowed.includes(evt.keyCode)) {
        evt.preventDefault()
        return
      }
    },
    defaultValue() { this.vmodel[this.field.name] = (!this.field.defaultvalue || this.vmodel[this.field.name]) ? this.vmodel[this.field.name] : this.field.defaultvalue },
  },
  created() {
    this.defaultValue()
    this.field.mask = "date"
  },
  mounted() {
    if (this.vmodel && this.vmodel[this.field.name]) { this.vmodelCalender = this.parseDate(this.vmodel[this.field.name]) }
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .inLineButton {
    margin-top: -2px;
    margin-left: 5px;
    background: #EEEEEE;
    border: 0px;
  }
  .inLineButton.outer { margin-top: -6px; }
</style>
<style>
  div.v-picker div.v-picker__body { max-width: 290px; }
</style>