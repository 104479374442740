<template>
  <div id="divButtons" v-if="$route.query.menuId != ''">
    <v-btn v-if="mode === 'new' && canAction('create')" @click="createRegister" color="success">Adicionar</v-btn>
    <v-btn v-if="mode === 'edit' && canAction('duplicate')" @click="cloneRegister" color="grey lighten-1">Duplicar</v-btn>
    <v-btn v-if="mode === 'edit' && canAction('delete')" @click="deleteRegister" color="error">Deletar</v-btn>
    <v-btn v-if="mode === 'edit' && canAction('update')" @click="updateRegister" color="success">Gravar</v-btn>
    <template v-for="(field, i) in actions">
      <FieldButton v-if="field.position == 'buttons' && (field.show == true || field.show == undefined)" :key="i" :field="field" :logic="logic" />
    </template>
  </div>
</template>

<script>
import * as backend from '@/lib/backend'
import * as updatePatch from '@/lib/updatePatch'
import { mapState } from 'vuex'
import * as js_functions from '@/lib/js_functions'
import FieldButton from './FieldButton'
export default {
  name: "List",
  props: ['mode', 'actions', 'logic', 'Form'],
  components: { FieldButton },
  computed: { ...mapState(['user', 'register', 'registerOriginal']) },
  methods: {
    canAction(action) { return this.$store.getters.canAction(action) },
    async createRegister() {
      this.$store.commit("showOverlay")
      if (!await this.Form.checkBeforeAction('beforeCheckCreate')) return this.$store.commit("hideOverlay")
      if (!this.Form.validate()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkOnlyone()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkBeforeAction('beforeCreate')) return this.$store.commit("hideOverlay")
      let _register = {}
      Object.keys(this.register).map(key => {
        if (this.register[key] != '' && this.register[key] != []) {
          Object.assign(_register, { [key]: Array.isArray(this.register[key]) ? js_functions.cleamObjectsArray(this.register[key]) : this.register[key] })
        }
      })
      _register.lastUpdate = new Date(Date.now()).toISOString()
      await backend.post({
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        body: _register,
        early: true
      }).then(res => {
        if (res.data && res.data.ops && res.data.ops[0]) {
          this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber: 2, id: res.data.ops[0]._id } })
            .then(() => {
              this.$store.commit("hideOverlay")
              this.$store.commit("showAlert", { type: 'success', text: 'Adicionado com sucesso!', dismissible: true, time: 2000 })
            })
            .catch(()=>{ this.$store.commit("hideOverlay") })
        }
        else { throw { data: "Ocorreu um problema ao adicionar." } }
      }).catch(e => { this.$store.commit("showError", e) })
    },
    async cloneRegister() {
      this.$store.commit("showOverlay")
      if (!await this.Form.checkBeforeAction('beforeClone')) return this.$store.commit("hideOverlay")
      let _register = window.structuredClone(this.register)
      delete _register._id
      _register.lastUpdate = new Date(Date.now()).toISOString()
      this.$store.commit('changeKeepRegister', true)
      this.$store.commit('changeRegister', _register)
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber: 0, id: '' } })
        .then(() => {
          this.$store.commit("hideOverlay")
          this.$store.commit("showAlert", { type: 'success', text: 'Registro duplicado com sucesso! Ainda falta gravar.', dismissible: true, time: 2000 })
        })
        .catch(()=>{ this.$store.commit("hideOverlay") })
    },
    updateRegister() { window[this.user.company] && window[this.user.company].settings && window[this.user.company].settings.updateMode == 'sync' ? this.updateRegisterSync() : this.updateRegisterPut() },
    async updateRegisterPut() {
      this.$store.commit("showOverlay")
      if (!await this.Form.checkBeforeAction('beforeCheckUpdate')) return this.$store.commit("hideOverlay")
      if (!this.Form.validate()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkOnlyone()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkBeforeAction('beforeUpdate')) return this.$store.commit("hideOverlay")
      let _register = {}
      Object.keys(this.register).map(key => {
        if (this.register[key] != '' && this.register[key] != []) {
          Object.assign(_register, { [key]: Array.isArray(this.register[key]) ? js_functions.cleamObjectsArray(this.register[key]) : this.register[key] })
        }
      })
      _register.lastUpdate = new Date(Date.now()).toISOString()
      await backend.put({
        module: this.$route.name,
        dataId: this.$route.query.menuId,
        register: _register,
        early: true
      }).then(res => {
        if (res.status == 200) {
          this.$store.commit("hideOverlay")
          this.$store.commit("showAlert", { type: 'success', text: 'Gravado com sucesso!', dismissible: true, time: 2000 })
        }
        else { throw { data: "Ocorreu um problema ao gravar." } }
      }).catch(e => this.$store.commit("showError", e))
    },
    async updateRegisterSync() {
      this.$store.commit("showOverlay")
      if (!await this.Form.checkBeforeAction('beforeCheckUpdate')) return this.$store.commit("hideOverlay")
      if (!this.Form.validate()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkOnlyone()) return this.$store.commit("hideOverlay")
      if (!await this.Form.checkBeforeAction('beforeUpdate')) return this.$store.commit("hideOverlay")
      updatePatch.updateRegisterSync(this)
    },
    async deleteRegister() {
      if (!await this.Form.checkBeforeAction('beforeDelete')) return this.$store.commit("hideOverlay")
      this.$store.commit("showDialog", {
        title: 'Tem certeza que deseja deletar o registro?',
        text: 'Ação irreversível: uma vez deletado esse registro não poderá ser recuperado!',
        buttonYes: 'Sim, pode deletar!',
        buttonNo: 'Não',
        action: async () => {
          this.$store.commit("hideDialog")
          this.$store.commit("showOverlay")
          await backend.del({
            module: this.$route.name,
            dataId: this.$route.query.menuId,
            register: this.register,
            early: true
          }).then(res => {
            if (res.status == 200) {
              this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber: 1, id: '' } })
                .then(() => {
                  this.$store.commit("hideOverlay")
                  this.$store.commit("showAlert", { type: 'success', text: 'Deletado com sucesso!', dismissible: true, time: 2000  })
                })
                .catch(()=>{ this.$store.commit("hideOverlay") })
            }
            else { throw { data: "Ocorreu um problema ao deletar." } }
          }).catch(e => this.$store.commit("showError", e))
        }
      })
    }
  }
}
</script>

<style scoped>
  div#divButtons {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0px;
  }
  div>* {
    margin-left: 20px;
  }
  @media print { div { display: none; } }
</style>