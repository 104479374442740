import * as backend from '@/lib/backend'
import * as jsf from '@/lib/js_functions'

export async function getGenericRegisters({ company, module, dataId, query, project, action }) {
  return await backend.post({
    company,
    module: module || 'data',
    dataId,
    action: action || 'read/many',
    body: { query, project },
  }).then(registers => {
    if ((action == 'read/one' && !registers) || registers.length <= 0) { return { status: false, alertType: 'warning', alertText: 'Nenhum registro encontrato.' } }
    return { status: true, data: registers }
  }).catch(e => { return { status: false, alertType: 'error', alertText: 'Ocorreu um problema ao carregar os registros.', error: e } })
}
export async function getLastNumber({ module, dataId, field }) {
  return await backend.get({
    module: module || 'data',
    dataId,
    query: { field, next: true }
  })
}
export async function getNextNumber({ module, dataId, field }) {
  const lastNumber = await this.getLastNumber({ module, dataId, field })
  return (jsf.numberIn(lastNumber.replace(".", "")) + 1) + ""
}
export function requireField({ fields, fieldName, required }) {
  jsf.getJSONObject({ fields, name: fieldName }).required = required
}
export async function replaceAllLinks({ originalData, dataId }) {
  let newData = originalData
  !Array.isArray(window[window.company].lib.linksToReplace(dataId)) || await Promise.all(window[window.company].lib.linksToReplace(dataId).map(async linkToReplace => {
    const { name, funcParams, replaceLinks } = linkToReplace
    const _this = {}
    await jsf.getFunc({ receiver: { object: _this, name}, func: () => getGenericRegisters({ ...funcParams }) })
    newData = newData?.map(register => replaceLinks(register, _this[name]))
  }))
  return newData
}