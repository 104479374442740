<template>
  <aside class="menu" v-show="isMenuVisible">
    <v-list dense nav>
      <v-list-item-group v-model="selectedItem" :key="key" color="primary" :multiple="multiple" :mandatory="$route.name != 'maps' && selectedItem != null">
        <template v-for="(item, i) in items">
          <v-list-item v-if="!item.subitems" :key="i" link @click="menuAction(item, i)" :disabled="item.disabled" :class="{['v-item--active v-list-item--active']: ($route.query.menuId && i == selectedItem) || (Array.isArray(selectedItem) && selectedItem.includes(i)), ['notActive']: ($route.name != 'maps' && (!$route.query.menuId || i != selectedItem)) && (!Array.isArray(selectedItem ) || !selectedItem.includes(i)) }">
            <v-list-item-icon class="mr-2"><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
          <v-list-group v-if="item.subitems" no-action :key="i">
            <template v-slot:activator>
              <v-list-item-icon class="mr-2"><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </template>
            <v-list-item v-for="(subitems, j) in item.subitems" :key="i + j/10" link @click="menuAction(subitems, i + j/10)">
              <v-list-item-icon class="mr-2"><v-icon :v-text="subitems.icon || 'mdi-menu-right'"></v-icon></v-list-item-icon>
              <v-list-item-content>{{ subitems.title }}</v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </aside>
</template>

<script>
import * as backend from '@/lib/backend'
import { mapState } from 'vuex'
export default {
  name: "Menu",
  computed: mapState(['isMenuVisible', 'user', 'map', 'report', 'clearMenuSelected']),
  data: () => ({
    items: [],
    selectedItem: null,
    multiple: false,
    key: 0
  }),
  watch: {
    clearMenuSelected(value) {
      if (value) {
        this.selectedItem = null
        this.$store.commit("clearMenuSelected", false)
      }
    }
  },
  methods: {
    async getFullMenusItems(dataId) { return await backend.post({ module: 'controls', dataId, action: 'read/many' }) },
    async getMenuItems() {
      this.$store.commit("showOverlay")
      this.selectedItem = null
      this.multiple = this.$route.name == 'maps' ? true : false
      let params = {
        company: this.$route.name == 'controls' ? 'default' : this.user.company,
        module: this.$route.name == 'settings' ? 'data' : this.$route.name,
        dataId: `menu/_${this.$route.name == 'settings' ? 'data' : this.$route.name}`,
        early: true
      }
      await backend.get(params).then(res => {
        this.items = res.data
      }).catch(e => {
        this.items = []
        this.$store.commit("showError", e)
      })
      this.selectItem()
      this.$store.commit("hideOverlay")
    },
    selectItem() {
      if (this.$route.query.menuId && this.$route.query.menuId != "" && Array.isArray(this.items)) {
        const _item = this.items.find(item => item.dataId == this.$route.query.menuId || item.report == this.$route.query.menuId) || this.items.find(item => this.$route.query.collection && (item.dataId == this.$route.query.collection || item.report == this.$route.query.collection))
        if (_item) {
          this.selectedItem = this.items.indexOf(_item)
          this.key += 1
        }
      }
    },
    getFolder(menuId) { return this.items.find(item => item.dataId == menuId || item.report == menuId)?.folder },
    async menuAction(item, itemNumber) {
      const menuId = (this.$route.name == 'controls' || this.$route.name == 'data' || this.$route.name == 'settings') ? item.dataId : item.report
      if (this.$route.name != 'maps') {
        const folder = this.getFolder(menuId)
        this.$router.push({ name: this.$route.name == 'settings' ? 'data' : this.$route.name, query: { ...this.$route.query, menuId, folder, menuOpened: item.title, tabNumber: 1 } })
          .then(() => { this.executeMenuAction(item, itemNumber) })
          .catch(()=>{})
      }
      else { this.executeMenuAction(item, itemNumber) }
    },
    async executeMenuAction(item, itemNumber) {
      if (this.$route.name == 'controls' || this.$route.name == 'data') {
        if (item.dataId == this.$route.query.menuId) { return }
        this.$store.commit('changeRegister', {})
      }
      if (this.$route.name == 'maps') {
        const origin = item.origin == 'public' ?  this.map.functions.public : this.map.logicMaps
        const added = (item.method && origin[item.method]) ? await origin[item.method](this.map, item.param) : false
        if (added && !this.selectedItem.includes(itemNumber)) { this.selectedItem.push(itemNumber) }
      }
      if (this.$mq === 'xs' || this.$mq === 'sm') { this.$store.commit('toggleMenu', false) }
    }
  },
  mounted() { this.getMenuItems() },
  async created() {
    this.$store.commit('setDataItens', await this.getFullMenusItems('_data'))
    this.$store.commit('setReportsItens', await this.getFullMenusItems('_reports'))
    this.$watch(
      () => this.$route,
      async (route, oldRoute) => {
        if (oldRoute && route.name != oldRoute.name) { await this.getMenuItems() }
        if (oldRoute && route.query.menuId != oldRoute.query.menuId) {
          this.selectItem()
        }
      },
      { immediate: true }
    )
  }
}
</script>

<style>
  .menu {
    grid-area: menu;
    border-right: 1px solid rgb(100 121 143 / 12%);
    overflow: auto;
    background-color: var(--menu-background-color); /* Company Style */
  }
  .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) { margin-left: 0px; }
  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon { min-width: 24px; }
  .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item { padding-left: 26px; }
  .v-list--dense .v-list-item .v-list-item__content {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
  }
  /* Company Style */
  .menu > .v-list { background-color: var(--menu-background-color); }
  .menu .theme--light.v-icon { color: var(--menu-color); }
  .menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) { color: var(--menu-color); }
  .v-list-item-group .v-list-item--active:not(.notActive) { color: var(--over-color); }
  .v-list-item-group .v-list-item--active:not(.notActive) .theme--light.v-icon { color: var(--over-color); }
  .v-list-item-group .notActive { color: var(--menu-color); }
  .v-list-item-group .notActive.theme--light.v-list-item--active::before { opacity: 0; }
  .v-list-item-group .notActive .theme--light.v-icon { color: var(--menu-color); }
  .v-list-item--link:before { color: var(--over-color); }
  @media print { .menu { display: none; } }
</style>