<template>
  <div id="tabs">
    <v-tabs v-model="tab" height="30px">
      <v-tab key='0' @change="changeTab(0)" :disabled="!canAction('create')">Novo</v-tab>
      <v-tab key='1' @change="changeTab(1)" :disabled="!canAction('list')">Lista</v-tab>
      <v-tab key='2' @change="changeTab(2)" :disabled="!canEdit() || !canAction('read')">{{ canAction('update') ? 'Editar' : 'Visualizar' }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-show="tab == 0 && canAction('create')" key='0'><New :key="newKey"/></v-tab-item>
      <v-tab-item v-show="tab == 1 && canAction('list')" key='1'><List /></v-tab-item>
      <v-tab-item v-if="tab == 2 && canEdit() && canAction('read')" key='2'><Edit :key="editKey"/></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import List from './List.vue'
import New from './New.vue'
import Edit from './Edit.vue'
import { mapState } from 'vuex'
export default {
  name: "Tabs",
  components: { List, New, Edit },
  computed: { ...mapState(['register']) },
  data: () => ({
    tab: 1,
    newKey: 0,
    editKey: 0
  }),
  methods: {
    changeTab(tabNumber) {
      this.tab = tabNumber
      if (tabNumber != 2) { this.$store.commit('clearRegister') }
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, tabNumber, id: '' } })
        .then(()=>{})
        .catch(()=>{})
    },
    canEdit() { return (this.register != null && '_id' in this.register) || this.$route.query.id ? true : false },
    canAction(action) { return this.$store.getters.canAction(action) }
  },
  mounted() { this.tab = Number(this.$route.query.tabNumber) },
  created() {
    this.$watch(
      () => this.$route.query.tabNumber,
      (tabNumber) => {
        this.tab = Number(tabNumber)
        const divRouter = document.querySelector("div.router")
        if (divRouter) { divRouter.scrollTop = 0 }
      },
      { immediate: true }
    )
  }
}
</script>

<style>
  div#tabs { padding: 10px; }
  /* Company Style */
  div#tabs .v-tab.v-tab--active { color: var(--over-color); }
  div#tabs .v-tab:before{ color: var(--over-color); }
  div#tabs .v-tabs-slider { background-color: var(--over-color); }
  @media print { div#tabs .v-tabs { display: none; } }
</style>